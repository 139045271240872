import React, { useState, useEffect } from 'react';
import { dashboardData } from '../data/RAdashboardData';
import MetricCard from '../new/MetricCard';
import TopVendors from '../new/TopVendors';
import SpendByCategory from '../new/SpendByCategory';
import InvoiceAgeBreakdown from '../new/InvoiceAgeBreakdown';
import InvoiceStatus from '../new/InvoiceStatus';
import PaymentMethod from '../new/PaymentMethod';
import InvoiceByCustomer from '../new/InvoiceByCustomer';
import SpendByYear from '../new/SpendByYear';
import GenericBarChart from '../new/HorizontalBarGraph';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
import { dashboard, adminRAStats } from '../../../api/dashboard';
import { Link } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { RiLoader4Fill } from 'react-icons/ri';

const DashboardRA = () => {
  const [filters, setFilters] = useState([]);
  const [spendFilter, setSpendFilter] = useState('monthly');
  const [dashboard_data, setDashboardData] = useState({
    metrics: {
      open: { value: 0, trend: '0.0%', data: [] },
      active: { value: 0, trend: '0.0%', data: [] },
      won: { value: 0, trend: '0.0%', data: [] },
      lost: { value: 0, trend: '0.0%', data: [] }
    },
    disputesByBuyer: { currentYear: [], previousYear: [] },
    disputesByYear: {
      value: 0,
      currentYear: [],
      previousYear: []
    },
    disputesByProduct: { currentYear: [], previousYear: [] },
    disputesByCategory: {},
    salesByBuyer: { currentYear: [], previousYear: [] },
    disputesByLocation: {},
    userName: ''
  });
  const [userName, setUserName] = useState('');
  const [greeting, setGreeting] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchDashboardData = async (filters) => {
    setLoading(true);
    try {
      const response = await adminRAStats(filters);
      if (response[0] !== 200) throw new Error('Failed to fetch RA dashboard data');

      const data = response[1];
      console.log("Fetched RA Data: ", data);

      setUserName(data.userName.split(' ')[0]);
      setDashboardData(data);

      localStorage.setItem('raDashboardData', JSON.stringify(data));
    } catch (error) {
      console.error('Error fetching RA dashboard data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDashboardData(filters);

    const determineGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) setGreeting('Good Morning');
      else if (currentHour < 18) setGreeting('Good Afternoon');
      else setGreeting('Good Evening');
    };

    determineGreeting();
  }, [filters]);

  const handleSpendFilterChange = (filter) => {
    setSpendFilter(filter);
  };

  const getFilteredSpendData = () => {
    const data = dashboard_data.disputesByYear;
    if (spendFilter === 'quarterly') {
      return {
        currentYear: data.currentYear.filter((_, index) => (index + 1) % 3 === 0),
        previousYear: data.previousYear.filter((_, index) => (index + 1) % 3 === 0)
      };
    } else if (spendFilter === 'monthly') {
      return data;
    } else {
      return {
        currentYear: [{ month: 'Total', amount: data.currentYear.reduce((sum, item) => sum + item.amount, 0) }],
        previousYear: [{ month: 'Total', amount: data.previousYear.reduce((sum, item) => sum + item.amount, 0) }]
      };
    }
  };

  const handleFilter = (filter) => {
    console.log("Received filter:", filter);
  
    // If filter is an array, extract the first object
    if (Array.isArray(filter) && filter.length > 0) {
      filter = filter[0];  
    }
  
    // Ensure the filter is a valid non-empty object
    if (!filter || typeof filter !== "object" || Object.keys(filter).length === 0) {
      console.log("Invalid or empty filter:", filter);
      return;
    }
  
    const filterKey = Object.keys(filter)[0]; // Extract key
    const filterValue = filter[filterKey]; // Extract value
  
    setFilters((prevFilters) => {
      if (filterValue === "" || filterValue === null) {
        // Remove the filter if the value is empty/null
        const updatedFilters = { ...prevFilters };
        delete updatedFilters[filterKey];
        return updatedFilters;
      }
      return { ...prevFilters, [filterKey]: filterValue };
    });
  };  
  console.log(filters);
  
  const handleRemoveFilter = (filterKey) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[filterKey];
      return updatedFilters;
    });
  };  


  const clearAllFilters = () => {
    setFilters({});
  };


  const filteredSpendData = getFilteredSpendData(); // Calculate this here, so it can be used in render
  
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen text-lg font-semibold">
        <RiLoader4Fill className={"text-2xl animate-spin text-gray-700"}/>
      </div>
    );
  }

  return (
    <div className="w-full p-6">
      {/* Header section */}
      <div className="mb-8 text-left flex flex-col md:flex-row items-start md:items-center justify-between space-y-4">
        <div>
          <h1 className="text-2xl font-semibold text-gray-800">{greeting}, {userName}</h1>
          <p className="text-gray-600 mt-1">Here's what's happening with your invoices.</p>
          <Link to="/staging-app/dashboard/admin/ra-analysis" className="mt-4">
            <button className="bg-black hover:bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2 mt-4">
              Review Disputes
              <IoIosArrowDroprightCircle className='h-6 w-6' />
            </button>
          </Link>
        </div>

        <div className="flex flex-wrap gap-4">
          <select
            className="w-20 md:w-fit px-1 py-2 bg-gray-100 border rounded-lg text-gray-500 font-semibold"
            onChange={(e) => handleSpendFilterChange(e.target.value)}
            value={spendFilter}
          >
            <option value="">Filter</option>
            <option value="yearly">Yearly</option>
            <option value="quarterly">Quarterly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
      </div>

      {/* Filter Display */}
      <div className='w-full mb-5'>
      {Object.keys(filters).length > 0 && (
        <ul className="flex items-center space-x-3 flex-wrap">
          {Object.entries(filters).map(([key, value]) => (
            <li 
              key={key} 
              className="text-sm text-blue-600 px-2 py-1 rounded-full border border-blue-600 bg-blue-100 flex items-center gap-2"
            >
              <span className="font-semibold">{key.replace('_', ' ')}:</span> {value}
              <button 
                onClick={() => handleRemoveFilter(key)} 
                className="text-red-500 hover:text-red-700"
              >
                ✖
              </button>
            </li>
          ))}
        </ul>
      )}
      </div>

      {/* Metrics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6">
        <MetricCard
          title="Open Disputes"
          value={dashboardData.metrics.open.value}
          trend={dashboardData.metrics.open.trend}
          data={dashboardData.metrics.open.data}
        />
        <MetricCard
          title="Active Disputes"
          prefix="$"
          value={dashboardData.metrics.active.value}
          trend={dashboardData.metrics.active.trend}
          data={dashboardData.metrics.active.data}
        />
        <MetricCard
          title="Disputes Won"
          suffix="%"
          value={dashboardData.metrics.won.value}
          trend={dashboardData.metrics.won.trend}
          data={dashboardData.metrics.won.data}
        />
        <MetricCard
          title="Disputes Lost"
          suffix="%"
          value={dashboardData.metrics.lost.value}
          trend={dashboardData.metrics.lost.trend}
          data={dashboardData.metrics.lost.data}
        />
      </div>

      {/* Row 2 */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <GenericBarChart data={dashboard_data.disputesByBuyer} title="Disputes by Buyers" xAxisLabel="" yAxisLabel="" onFilterChange={handleFilter} />
        <SpendByYear data={getFilteredSpendData()} timeFilter={dashboardData.disputesByYear} title="Total Sales" />
        <GenericBarChart data={dashboardData.disputesByProduct} title="Disputes by proucts" xAxisLabel="" yAxisLabel="" onFilterChange={handleFilter} />
      </div>

      {/* Row 3 */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <PaymentMethod data={dashboardData.disputesByCategory} title="Disputes By product category" />
        <GenericBarChart data={dashboard_data.disputesByBuyer} title="Sales by Buyers" xAxisLabel="" yAxisLabel="" onFilterChange={handleFilter} />
        <InvoiceAgeBreakdown data={dashboard_data.disputesByLocation} title="Disputes By Location" />
      </div>
    </div>
  );
};

export default DashboardRA;