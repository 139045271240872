import request, {sideQuest} from "./init";


function sideQuestAccess() {
    return request(`${sideQuest}/`, null, "GET");
}

function getProfile(uri) {
    return request(`${sideQuest}/get-profile?uri=${uri}`, null, "GET");
}

function getFinancialStatements(ticker) {
    return request(`${sideQuest}/financial-statements?ticker=${ticker}`, null, "GET");
}

function getNewsArticles(q) {
    return request(`${sideQuest}/news-articles?query=${q}`, null, "GET");
}

function autoCompleteProfile(q) {
    return request(`${sideQuest}/auto-complete?query=${q}`, null, "GET");
}

function sideQuestChat(init = false, payload = null) {
    return request(`${sideQuest}/chat`, payload, init ? "GET" : "POST");
}

export {sideQuestAccess, getProfile, getFinancialStatements, getNewsArticles, autoCompleteProfile, sideQuestChat};
