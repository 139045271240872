import React from 'react';
import Navbar from './navbar.jsx';
import Hero from './hero.jsx';
import Features from './features.jsx';
import BentoGrid from './how.jsx';
import Integrations from './integrations.jsx';
import Reasons from './why.jsx';
import SuperchargeSection from './supercharge.jsx';
import Footer from './footer.jsx';

export default function FreightAudit() {
  return (
    <div className='w-full min-h-screen overflow-y-auto'>
        <Navbar />
        <Hero />
        <Features />
        <BentoGrid />
        <Integrations />
        <Reasons />
        <SuperchargeSection />
        <Footer />
    </div>
  )
}