import React, { useRef, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
} from 'chart.js';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { MdRefresh } from 'react-icons/md';

// Register necessary components from Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend
);

const InvoiceByCustomer = ({ data }) => {
  const chartRef = useRef(null); // Reference to the canvas element
  const chartInstanceRef = useRef(null); // Store the Chart.js instance
  const [filteredData, setFilteredData] = useState(data); // State for filtered data

  useEffect(() => {
    // Cleanup previous chart instance
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Check if data is valid
    if (!filteredData || !filteredData.currentYear || !filteredData.previousYear) {
      console.error('Error: Invalid data format');
      return;
    }

    // Prepare chart data
    const chartData = {
      labels: filteredData.currentYear.map((item) => item.customer),
      datasets: [
        {
          label: 'Current Year',
          data: filteredData.currentYear.map((item) => item.amount),
          backgroundColor: '#98CDF3', // Blue
          borderRadius: 4,
          barThickness: 12,
          categoryPercentage: 0.5, // Adjust space between categories
          barPercentage: 0.5, // Adjust space within each category
        },
        {
          label: 'Previous Year',
          data: filteredData.previousYear.map((item) => item.amount),
          backgroundColor: '#97F2B2', // Green
          borderRadius: 4,
          barThickness: 12,
          categoryPercentage: 0.5, // Adjust space between categories
          barPercentage: 0.5, // Adjust space within each category
        },
      ],
    };

    // Define chart options
    const options = {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          align: 'end',
          labels: {
            boxWidth: 12,
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 20,
            color: '#6B7280',
          },
        },
      },
      scales: {
        x: {
          grid: {
            color: 'rgba(243, 244, 246, 1)',
            lineWidth: 1,
          },
          ticks: {
            color: '#6B7280',
            font: {
              size: 12,
            },
            callback: (value) => `$${value.toLocaleString()}`,
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            color: '#6B7280',
            font: {
              size: 12,
            },
          },
        },
      },
      onClick(event, elements) {
        if (elements.length) {
          const clickedIndex = elements[0].index;
          const clickedCustomer = filteredData.currentYear[clickedIndex].customer;

          // Filter data based on clicked customer
          const newFilteredData = {
            currentYear: filteredData.currentYear.filter(item => item.customer === clickedCustomer),
            previousYear: filteredData.previousYear.filter(item => item.customer === clickedCustomer),
          };

          setFilteredData(newFilteredData);
        }
      }
    };

    // Create new chart instance
    if (chartRef.current) {
      chartInstanceRef.current = new ChartJS(chartRef.current, {
        type: 'bar',
        data: chartData,
        options,
      });
    }

    // Cleanup on unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
    
  }, [filteredData]); // Only re-run if filteredData changes

  // Function to refresh the chart data
  const handleRefresh = () => {
    setFilteredData(data);
  };

  return (
    <div className="bg-white rounded-xl text-left border h-fit shadow-md">
      <div className="pb-1 p-6 flex items-center justify-between">
        <div>
          <h2 className="text-sm font-semibold text-gray-800">Outstanding Invoice by Customer</h2>
          <span className="text-gray-500 text-xs">This Year</span>
        </div>
        <div className='flex items-start space-x-3'>
          <p className='text-[6px] font-semibold rounded-md border bg-gray-200 px-2 py-1'>Product Count</p>
          <IoIosInformationCircleOutline className="text-gray-500" />
        </div>
      </div>

      {/* Refresh Button */}
      <button
        onClick={handleRefresh}
        className="text-gray-500 py-2 px-4 rounded hover:text-gray-800 transition"
      >
        <MdRefresh />
      </button>

      <div className='h-[320px] -mt-6 px-10 pb-10 cursor-pointer'> {/* Added cursor-pointer */}
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default InvoiceByCustomer;
