import React, { useState } from "react";

export default function Sorter({ sort, setSort, columns }) {
  const [sortOpen, setSortOpen] = useState(false);

  return (
    <div className="flex flex-col gap-4 relative">
      <button
        onClick={() => setSortOpen(!sortOpen)}
        className="px-3 py-1 flex items-center card p-0 whitespace-nowrap text-xs gap-1 hover:bg-gray-200 rounded-md border-transparent"
      >
        <span className="material-symbols-rounded text-lg">sort</span>
        {sort || "Sort by"} {/* Display the selected sort or a default */}
      </button>
      {sortOpen && (
        <ul className="bg-white shadow-xl rounded-lg overflow-hidden absolute top-10 right-0 border z-20">
          {columns.map((c) => (
            <li
              key={c.id}
              className={`select-none px-3 py-2 hover:bg-gray-100 text-xs whitespace-nowrap cursor-pointer ${
                sort === c.id ? "font-bold text-blue-600" : ""
              }`}
              onClick={() => {
                setSort(c.id); // Update the selected sort
                setSortOpen(false); // Close the dropdown
              }}
            >
              <span>{c.name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
