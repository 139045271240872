import React, {useEffect, useState} from "react";
import Notify from "./Notification";
import {contractDetails} from "../api/dashboard";

function ContractSideBar({contract, setDescribeContract}) {
    const [det, setDet] = useState({});

    useEffect(() => {
        contractDetails(contract.id)
            .then(([status, data]) => {
                if (status === 200) {
                    setDet(data);
                } else {
                    if (status === 404) {
                        Notify("No record found for this contract", "alert");
                    } else {
                        Notify("Something went wrong while fetching details", "alert");
                    }
                    setDet({});
                }
            })
    }, [contract]);

    return <div
        className={"flex flex-col fixed h-full lg:max-w-screen-sm w-full shadow-xl bg-white right-0 bottom-0 z-50" +
            " flex-1 overflow-x-auto lg:overflow-hidden lg:overflow-y-auto light-scroll"}>

        <div className={"flex items-stretch w-full h-min"}>
            {/* border */}
            <div className={"h-full w-[5px]"} style={{backgroundColor: "#005B8C"}}/>
            <div className={"flex flex-col w-full pt-6 px-6"}>
                {/* side header and close button */}
                <div className={"flex items-center justify-between mb-4 flex-1"}>
                    <div className={"overflow-hidden text-ellipsis w-3/4"}><span
                        className={"font-bold text-md text-nowrap"}>{contract.name}</span>
                    </div>
                    <button onClick={() => setDescribeContract(null)}><span
                        className={"material-symbols-rounded text-md"}>close</span></button>
                </div>

                {/* contract details */}
                <div className={"flex flex-col w-full"}>
                    <span className={"text-gray-400 font-bold my-4 text-sm border-b pb-2"}>Contract Details</span>
                    <table className={"mb-2"}>
                        <tbody>
                        <tr>
                            <td>
                                <span className={"text-sm font-bold inline-block pr-4"}>Contract Number:</span>
                            </td>
                            <td>
                                <span className={"text-sm"}>{det.contract_no || "-"}</span> {/* PC-XXXXXX */}

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className={"text-sm font-bold inline-block pr-4"}>Start Date:</span>
                            </td>
                            <td>
                                    <span
                                        className={"text-sm"}>{det.start_date || "-"}</span> {/* 11th February 2004 */}
                            </td>
                            <td>
                                <span className={"text-sm font-bold inline-block pr-4"}>End Date:</span>
                            </td>
                            <td>
                                <span className={"text-sm"}>{det.end_date || "-"}</span> {/* 11th February 2024 */}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div className={"flex items-stretch w-full h-min"}>
            {/* border */}
            <div className={"h-full w-[5px]"} style={{backgroundColor: "#00A7C9"}}/>
            <div className={"flex flex-col w-full px-6"}>
                {/* supplier information */}
                <div className={"flex flex-col w-full"}>
                        <span
                            className={"text-gray-400 font-bold my-4 text-sm border-b pb-2"}>Supplier Information</span>
                    <table className={"mb-2"}>
                        <tbody>
                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap font-bold inline-block pr-4"}>Supplier Name:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.supplier_name || "-"}</span> {/*Fischer Scientific Company, LLC*/}
                            </td>
                        </tr>

                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap font-bold inline-block pr-4"}>Supplier Email:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.supplier_email || "-"}</span> {/*Fischercompany@gmail.com*/}
                            </td>
                        </tr>

                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap font-bold inline-block pr-4"}>Supplier Website:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.supplier_website || "-"}</span>
                            </td>
                        </tr>

                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap align-top font-bold inline-block pr-4"}>Supplier Address:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.supplier_address || "-"}</span> {/*300 Industry Drive, Pittsburgh, PA 15275*/}
                            </td>
                        </tr>

                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap font-bold inline-block pr-4"}>Buyer Entity:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.buyer_entity || "-"}</span> {/*  People of the state of New York, acting by and through the Commissioner of the Office of General services(OGS)  */}
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div className={"flex items-stretch w-full h-min"}>
            {/* border */}
            <div className={"h-full w-[5px]"} style={{backgroundColor: "#A58EFD"}}/>
            <div className={"flex flex-col w-full px-6"}>
                {/* pricing information */}
                <div className={"flex flex-col w-full"}>
                    <span
                        className={"text-gray-400 font-bold my-4 text-sm border-b pb-2"}>Pricing Information</span>
                    <table className={"mb-2"}>
                        <tbody>
                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap font-bold inline-block pr-4"}>Minimum Order Amount:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.min_order_amount || "-"}</span> {/*  $100  */}
                            </td>
                        </tr>

                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap font-bold inline-block pr-4"}>Price Change:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.price_change || "-"}</span> {/*Price decreases or discount increases are permitted and encouraged at any time during the term of the contract*/}
                            </td>
                        </tr>

                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap align-top font-bold inline-block pr-4"}>Is best price offered?:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.best_price || "-"}</span> {/*  Yes  */}
                            </td>
                        </tr>

                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap font-bold inline-block pr-4"}>Purchase Card Accepted?:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.purchase_card || "-"}</span> {/*    Yes*/}
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div className={"flex items-stretch w-full flex-1"}>
            {/* border */}
            <div className={"h-full w-[5px]"} style={{backgroundColor: "#005162"}}/>
            <div className={"flex flex-col w-full px-6"}>
                {/* Product return and exchange policy */}
                <div className={"flex flex-col w-full"}>
                        <span
                            className={"text-gray-400 font-bold my-4 text-sm border-b pb-2"}>Product Return and Exchange Policy</span>
                    <table className={"mb-2"}>
                        <tbody>
                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap font-bold inline-block pr-4"}>Return time period:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.return_period || "-"}</span> {/*3 days*/}
                            </td>
                        </tr>

                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap font-bold inline-block pr-4"}>Credit or Full Refund:</span>
                            </td>
                            <td><span className={"text-sm block mb-1"}>{det.credit_refund || "-"}</span> {/*Yes*/}
                            </td>
                        </tr>

                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap align-top font-bold inline-block pr-4"}>Any fee involved?:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.fee_involved || "-"}</span> {/*No restocking fees*/}
                            </td>
                        </tr>

                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap font-bold inline-block pr-4"}>Refund timeline:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.refund_timeline || "-"}</span> {/*  Within 3 business days.  */}
                            </td>
                        </tr>

                        <tr>
                            <td className={"align-top"}><span
                                className={"text-sm text-nowrap font-bold inline-block pr-4"}>Cost of returning items:</span>
                            </td>
                            <td><span
                                className={"text-sm block mb-1"}>{det.cost_of_returning || "-"}</span> {/* Paid by contractor */}
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
}

export default ContractSideBar;