import React from "react";
import { motion } from "framer-motion";
import clipboardImage from "../assets_landingpage/icons/clipboardImage.svg"; // Replace with your images
import dollarImage from "../assets_landingpage/icons/dollarImage.svg";
import lightbulbImage from "../assets_landingpage/icons/lightbulbImage.svg";
import InfoCard from "./ui/InfoCard"; // Adjust the path as needed

const WhyChooseNimbey = ({
  title = "Why Choose Nimbey?",
  description = "Maximize Your Recovery with Expert Audits, AI Insights, and Success-Based Results",
  cards = [
    {
      title: "Deduction Expert Audits",
      description:
        "Human deduction experts in the loop to always ensure accuracy.",
      imgSrc: clipboardImage,
    },
    {
      title: "Success based pricing",
      description:
        "Your success is our success. It’s in our best interest to help you recover the maximum amount.",
      imgSrc: dollarImage,
    },
    {
      title: "Insights to recover more $",
      description:
        "AI based insights to solve deductions in the long term & increase recovery.",
      imgSrc: lightbulbImage,
    },
  ],
  titleSize = "text-[26px] md:text-[36px]",
  descriptionSize = "text-[16px] md:text-[20px]",
  titleColor = "text-black",
  descriptionColor = "text-gray-600",
}) => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const cardContainerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.section
      id="whychoose"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
      variants={containerVariants}
      className="py-10 pt-40 md:pb-20 px-8 bg-white w-[87.50%] mb-[40px] text-center"
    >
      {/* Title Section */}
      <motion.div
        variants={itemVariants}
        className={`${titleSize} font-poppins font-semibold mb-[16px] ${titleColor}`}
      >
        {title}
      </motion.div>

      {/* Description Section */}
      <motion.p
        variants={itemVariants}
        className={`${descriptionSize} font-publicSans font-medium ${descriptionColor} mb-[40px] mx-auto md:w-[530px] md:h-[53px]`}
      >
        {description}
      </motion.p>

      {/* Info Cards Section */}
      <motion.div
        variants={cardContainerVariants}
        className="flex flex-col md:flex-row justify-center items-center md:items-stretch space-y-4 md:space-y-0 md:space-x-4 pt-8"
      >
        {cards.map((card, index) => (
          <motion.div
            key={index}
            variants={cardVariants}
            className="flex-shrink-0 md:w-1/3 flex justify-center"
          >
            <InfoCard
              title={card.title}
              description={card.description}
              imgSrc={card.imgSrc}
              imageHeight={card.imageHeight}
              imageWidth={card.imageWidth}
            />
          </motion.div>
        ))}
      </motion.div>
    </motion.section>
  );
};

export default WhyChooseNimbey;