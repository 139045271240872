import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import clipboardImage from "../assets_landingpage/icons/clipboard.svg";
import analysisImage from "../assets_landingpage/icons/analysis.svg";
import reconciliationImage from "../assets_landingpage/icons/reconciliation.svg";
import InfoCard from "./ui/InfoCard";

const HowItWorks = ({
  heading = "How it Works?",
  cards = [
    {
      title: "Categorize with AI",
      description:
        "Nimbey AI can categorize deductions across 30+ retailers and distributor specific codes.",
      imgSrc: clipboardImage,
      size: "medium", // Default size
    },
    {
      title: "Analyze Disputes",
      description:
        "Our fine-tuned model can analyze data across any document format and assess validity of each claim. Nimbey AI can seamlessly file disputes on your behalf.",
      imgSrc: analysisImage,
      size: "large", // Large card
    },
    {
      title: "Automated Reconciliation",
      description:
        "Easily reconcile deductions using credit memos with integrations to Quickbooks and Netsuite.",
      imgSrc: reconciliationImage,
      size: "medium", // Default size
    },
  ], // Default cards data
}) => {
  const [updatedCards, setUpdatedCards] = useState([]);

  // Function to update the card sizes based on screen width
  const updateCardSizes = useCallback(() => {
    const screenWidth = window.innerWidth;

    // Check screen size and update the size of the middle card dynamically
    if (screenWidth < 768) {
      // On mobile (less than 768px), keep all cards medium
      setUpdatedCards(cards.map(card => ({ ...card, size: "medium" })));
    } else {
      // On medium and larger screens (768px and above), make the middle card large
      setUpdatedCards(
        cards.map((card, index) =>
          index === 1 ? { ...card, size: "large" } : card
        )
      );
    }
    // eslint-disable-next-line
  }, [cards]);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const cardContainerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  // Run the size update on initial load and on window resize
  useEffect(() => {
    updateCardSizes(); // Initial run
    window.addEventListener("resize", updateCardSizes); // Listen to window resize

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateCardSizes);
  }, [updateCardSizes]);

  return (
    <motion.section
      id="howitworks"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
      variants={containerVariants}
      className="mt-10 pt-40 py-10 md:pb-20 bg-white w-11/12 mb-[40px] text-center"
    >
      {/* Section Heading */}
      <motion.h2
        variants={itemVariants}
        className="text-3xl font-poppins font-semibold mb-[40px]"
      >
        {heading}
      </motion.h2>

      {/* Cards Container */}
      <motion.div
        variants={cardContainerVariants}
        className="flex flex-col md:flex-row w-[90vw] px-10 gap-2 justify-center items-center space-y-4 md:space-y-0 md:space-x-2"
      >
        {updatedCards.map((card, index) => (
          <motion.div
            key={index}
            variants={cardVariants}
            className="flex-shrink-0 md:w-1/3 flex justify-center items-center"
          >
            <InfoCard
              title={card.title}
              description={card.description}
              imgSrc={card.imgSrc}
              size={card.size}
              imageHeight={card.imageHeight}
              imageWidth={card.imageWidth}
            />
          </motion.div>
        ))}
      </motion.div>
    </motion.section>
  );
};

export default HowItWorks;