import {useEffect, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import {getProfile} from "../../../api/sideQuest";

function SupplierClients({chat, supplier_uri, setSupplierURI, expand = false}) {
    const [supplier, setSupplier] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!supplier_uri) return setSupplier(null)
        setLoading(true);
        getProfile(supplier_uri).then(([status, data]) => {
            if (status === 200) {
                setSupplier(data);
            }
        }).finally(() => setLoading(false));
    }, [supplier_uri]);

    const chart = useRef();

    useEffect(() => {
        chart.current?.chartInstance?.update();
    }, [supplier_uri, chat]);

    if (loading)
    return <div
        className={`flex flex-col md:items-stretch max-h-96 card p-0 w-full bg-gray-200 animate-pulse ` +
         `${chat ? 'xl:max-w-sm 2xl:max-w-2xl' : 'xl:max-w-sm 1xl:max-w-lg 2xl:max-w-screen-sm'}`}>

    </div>

    if (!supplier) return;

    const clients = supplier.clients.sort((x, y) => x.shipments > y.shipments ? -1 : 1);
    const others = clients.slice(9,).reduce((a, b) => a + b.shipments, 0);
    const proc = [
        ...clients.slice(0, 9),
    ]
    if (others) proc.push({
        name: "Others",
        shipments: others
    })
    const labels = proc.map(x => x.name);
    const total = proc.reduce((prev, curr) => prev + curr.shipments, 0);

    return <div className={`flex flex-col md:items-stretch max-h-96 h-full card p-0 w-full ${chat ? 'xl:max-w-96' : 'xl:max-w-screen-sm'}`}>
        <div className={"flex items-center gap-2 justify-between p-4 pb-0 w-full"}>
            <div className={"flex items-center gap-2 flex-1"}>
                <span className={"material-symbols-rounded text-md"}>drag_indicator</span>
                <span className={"text-sm font-semibold"}>{supplier.profile.name}'s Clients</span>
            </div>
            <button onClick={() => {
                setSupplier(null);
                setSupplierURI(null);
            }}
                    className={"flex material-symbols-rounded w-fit text-lg"}>close
            </button>
        </div>
        <div className={"p-4 w-full flex-1"}>
            <Bar ref={chart} className={"overflow-hidden w-full"} data={{
                labels: labels,
                datasets: [
                    {
                        data: proc.map(x => -(x.shipments * 100 / total).toFixed(1)),
                        fill: true,
                        borderWidth: 1,
                        borderColor: "rgba(0, 150, 255, 0.5)",
                        backgroundColor: proc.map(x => `rgba(0, 150, 255, ${x.shipments * 100 / total}%)`),
                    }
                ],
            }} options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {callbacks: {label: (tooltipItem) => `${tooltipItem.raw}%`}},
                    legend: {display: false}
                },
                scales: {
                    x: {ticks: {callback: (x) => `${-x}%`}},
                    y: {position: "right", ticks: {callback: (x) => labels[x]}}
                },
                indexAxis: "y",
            }}/>
        </div>
    </div>
}

export default SupplierClients;