import React from 'react'
import emailjs from 'emailjs-com'

const SendMail = async ({ otp = '00000', email = '', setCodeSent = () => {} }) => {
    if(!email) return;
    // emailjs.send('Service_id', 'Template_id', '#form_id', 'public key')

    const templateParams = {
                from_name: "Nimbey Ltd",
                email: email,
                to_name: "User",
                message: `Your otp: ${otp}`
            }

    await emailjs.send('service_pid68tn', 'template_1611hgm', templateParams, 'o-eMeAPqvkXmp3rXP')
    .then((result) => {
        console.log("email sent successfully")
        setCodeSent(true);
    })
    .catch((err) => {
        console.log("error in sending mail ", err)
    })
}

export default SendMail;