// PLEASE ADD THE IMPORT SELECTED LOGIC - BUTTON IS CREATED UNDER OPTIONS COMMENT
import React, { useState, useRef, useEffect } from "react";
import Notify from "./Notification";
import { uploadAnalysis, downloadPDF } from "../api/dashboard";
import { DropDown } from "./DropDown";
import { Checkbox } from "./ui/checkbox";
import { MdOutlineFileDownload, MdPictureAsPdf, MdRefresh } from "react-icons/md";

function AttachmentSidebar({ isSidebarOpen, onClose, attachments, onUpload, setAttachments, setIsSidebarOpen }) {
  console.log("gmail-attachments: ", attachments);
  const [status, setStatus] = useState(null);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [docType, setDocType] = useState(null);
  const modalRef = useRef();
  // ADDING THE SELECT ALL LOGIC USESTATE - REMOVE THIS COMMNENT WHILE MERGING OR NOT
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  
  const handleImportSelected = async () => {
    if (!selectedAttachments.length) {
      Notify("No attachments selected", "info");
      return;
    }
  
    Notify("Uploading selected attachments...", "info");
  
    // Clone the attachments array for updating
    let updatedAttachments = [...attachments];
  
    for (const attachmentKey of selectedAttachments) {
      const attachment = attachments.find((item) => item.s3_key === attachmentKey);
      const name = attachment.filename.toLowerCase();
      let doc_type = null;
      if(name.includes("invoice")) doc_type = "iv";
      else if(name.includes("contract")) doc_type = "co";
      else if(name.includes("delivery")) doc_type = "dd";
      else if(name.includes("purchase")) doc_type = "po";
      else if(name.includes("bol")) doc_type = "bo";
      else if(name.includes("distribution")) doc_type = "da";
      else if(name.includes("ra")) doc_type = "ra";


      if (attachment) {
        const isUploaded = await handleUpload(attachment, doc_type); // Use "auto" or default logic for docType if undefined
        if (isUploaded) {
          // Remove the uploaded attachment from the updatedAttachments array
          updatedAttachments = updatedAttachments.filter(
            (item) => item.s3_key !== attachmentKey
          );
        }
      }
    }
  
    // Update the state with the new attachments list
    setAttachments(updatedAttachments);
    setSelectedAttachments([]); // Clear selection after upload
    setSelectedAttachment(null); // Clear selected attachment
    setSelectAll(false); // Uncheck the "Select All" box
  };  

  // handle individual checkbox change
  const handleCheckboxChange = (attachmentId) => {
    setSelectedAttachments((prevSelected) => {
      const newSelected = prevSelected.includes(attachmentId)
        ? prevSelected.filter(id => id !== attachmentId) // deselect the attachment
        : [...prevSelected, attachmentId]; // select the attachment

      // if the selectAll is true and we're deselecting an item, uncheck selectAll
      if (selectAll && newSelected.length < selectedAttachments.length) {
        setSelectAll(false);
      }

      return newSelected;
    });
  };

  // handle select all checkbox change
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedAttachments([]); // deselect all
    } else {
      setSelectedAttachments(attachments.map(attachment => attachment.s3_key)); // Select all
    }
    setSelectAll(!selectAll); // toggle select all state
  };

  const handleUpload = async (attachment, docType) => {
    try {
      const [status, response] = await downloadPDF(attachment.s3_key);
      if (status !== 200 || !response.data?.url) {
        throw new Error("Failed to get presigned URL for download.");
      }

      const fileResponse = await fetch(response.data.url);
      if (!fileResponse.ok) {
        throw new Error("Failed to fetch the file from S3 using presigned URL.");
      }

      const fileBlob = await fileResponse.blob();
      const formData = new FormData();
      formData.append("files", fileBlob, attachment.filename);
      formData.append("types", JSON.stringify({ '0': docType }));

      const uploadResponse = await uploadAnalysis(formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (ev) => setStatus(ev),
      });

      if (uploadResponse[0] === 200) {
        Notify("Attachment uploaded successfully", "success");
        onUpload?.(attachment);
        const updatedAttachments = attachments.filter(
          (item) => item.s3_key !== attachment.s3_key
        );
        setAttachments(updatedAttachments);
        return true; // Upload successful
      } else {
        Notify("Failed to upload attachment", "error");
        return false; // Upload failed
      }
    } catch (error) {
      console.error("Error uploading attachment:", error);
      Notify("Error uploading attachment", "error");
    } finally {
      setStatus(null);
      modalRef.current.close();
    }
  };

  const handleDialog = (attachment) => {
    setSelectedAttachment(attachment);
    modalRef.current.showModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedAttachment && docType) {
      Notify("Uploading...", "info");
      setStatus("uploading");
      modalRef.current.close();
      handleUpload(selectedAttachment, docType);
    }
  };

  const handleChange = (attachment) => {
    if (status) return;
    console.log("handleChange attachment: ", attachment)

    const name = attachment.filename.toLowerCase();
    console.log("name: ", name);
    let detectedDocType = null;

    if (name.includes("invoice")) {
      if(window.location.href.includes("invoice-analysis")) detectedDocType = "iv";
      else if(window.location.href.includes("ra-analysis")) detectedDocType = "iv_ra";
    }
    else if (name.includes("contract") || name.includes("cont")) detectedDocType = "co";
    else if (name.includes("delivery")) detectedDocType = "dd";
    else if (name.includes("purchase")) detectedDocType = "po";
    else if (name.includes("bol")) detectedDocType = "bo";
    else if (name.includes("distribution")) detectedDocType = "da";
    else if (name.includes("ra")) detectedDocType = "ra";

    setDocType(detectedDocType);
  };

  const handleViewAttachments = async () => {
    try {
      const yourAuthToken = localStorage.getItem('authToken');
      if (!yourAuthToken) {
        Notify("Authentication token not found", "error");
        return;
      }  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/connect-gmail`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${yourAuthToken}`, // Include token if required
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        // Handle non-OK responses (e.g., 500 error)
        const errorText = await response.text();  // Read the plain text in case of error
        console.error("Server error:", errorText);
        Notify("Error initiating authentication", "error");
        return;
      }

      const data = await response.json();
      console.log("handleViewAttachments response:", data);

      if (data.success) {
        if (data.auth_url) window.location.href = data.auth_url;
        else handleFetchAttachments();
        // Redirect user to the Google authentication URL
      } else {
        Notify(data.message || "Failed to initiate authentication", "error");
      }
    } catch (error) {
      console.error("Error initiating authentication:", error);
      Notify("Error initiating authentication", "error");
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");

    if (code && state) {
      console.log("Google OAuth callback:", code, "\nstate:", state);

      // Call the /google-callback endpoint
      fetch(`${process.env.REACT_APP_API_URL}/api/google-callback?code=${code}&state=${state}`)
        .then(response => {
          if (!response.ok) {
            // Handle error responses
            return response.text(); // In case the response is not JSON
          }
          return response.json();  // Parse as JSON if the response is okay
        })
        .then(data => {
          if (typeof data === 'string') {
            console.error("Server error:", data);
            Notify("Authentication failed", "error");
            return;
          }

          if (data.success) {
            Notify("Authentication successful", "success");
          } else {
            Notify("Authentication failed", "error");
          }
        })
        .catch(error => {
          console.error("Error during callback:", error);
          Notify("Authentication failed", "error");
        });
    }
  }, []);

  // gmail api call
  const handleFetchAttachments = async () => {
    try {
      console.log("Fetching email attachments...");
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/fetch-and-upload-pdfs`); // Adjust the URL as needed
      if (!response.ok) {
        Notify("Failed to fetch attachments", "error");
        return;
      }

      const data = await response.json(); // Parse the JSON response
      console.log("Attachments response:", data);

      if (data.success) {
        if (data.s3_keys === undefined) {
          Notify("No PDF attachments found", "error");
        } else {
          const attachmentsWithUrls = data.s3_keys.map((key) => ({ // Corrected this part
            // s3_url: `https://nimbey-contracts.s3.amazonaws.com/${key}`,
            s3_key: key,
            filename: key.split("/").pop(),
          }));
          setAttachments(attachmentsWithUrls); // Update state with the processed s3_keys
          setIsSidebarOpen(true); // Open the sidebar
          Notify("Email attachments fetched successfully", "success");
        }
      } else {
        Notify(data.error || "Failed to fetch attachments", "error");
      }
    } catch (error) {
      console.error("Error fetching attachments:", error);
      Notify("Error fetching attachments", "error");
    }
  };

  const handleInvoiceDownload = async (attachment) => {
    try {
      const [status, response] = await downloadPDF(attachment.s3_key);
      if (status === 200 && response.data?.url) {
        window.open(response.data.url, "_blank");
        Notify("Invoice downloaded successfully", "success");
      } else {
        Notify(response?.message || "Failed to download the invoice", "error");
      }
    } catch (error) {
      console.error("Error downloading invoice:", error);
      Notify("Failed to download the invoice", "error");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex">
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>

      {/* Sidebar */}
      <div
        className={`relative w-full md:w-2/5 px-4 bg-white shadow-lg h-full overflow-hidden ml-auto transform ${isSidebarOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out`}
      >
        {/* Header */}
        <div className="sticky top-0 z-10 bg-white flex items-center justify-between p-4 border-b">
          <h2 className="text-lg font-semibold text-gray-800">Attachments</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800"
          >
            <span className="material-symbols-rounded">close</span>
          </button>
        </div>

        {/* options */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center p-4">
          <div className="flex items-center">
            <Checkbox checked={selectAll} onClick={handleSelectAllChange} />
            <span className="ml-2">Select All</span>
          </div>
          <div className="space-x-4">
            <button
              className="w-fit flex-shrink-0 rounded-md text-sm text-black py-2 px-3 transition-all duration-100 ease-in-out hover:bg-lime-300 hover:text-black focus:ring-2 focus:ring-lime-400 focus:ring-offset-2"
              onClick={handleImportSelected}
            > Import Selected </button>
            <button
              className="w-fit flex-shrink-0 rounded-md text-gray-500 font-medium py-2 px-3 transition-all duration-100 ease-in-out hover:text-gray-800"
              onClick={handleViewAttachments}
            > <MdRefresh />
            </button>
          </div>
        </div>

        {/* Attachments List */}
        <div className="overflow-y-auto h-[calc(100%-128px)] p-4">
          {attachments && attachments.length > 0 ? (
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-transparent border-b">
                  <th className="p-2 text-left"></th>
                  <th className="p-2 text-left font-semibold text-gray-700">Name</th>
                  <th className="p-2 text-left font-semibold text-gray-700">Time & Date</th>
                  <th className="p-2 text-left font-semibold text-gray-700">Download & Import</th>
                </tr>
              </thead>
              <tbody>
                {attachments.map((attachment) => (
                  <tr key={attachment.id} className="border-b border-dashed border-gray-200">
                    <td className="py-4 px-1 ">
                      <Checkbox
                        checked={selectedAttachments.includes(attachment.s3_key)}
                        onClick={() => handleCheckboxChange(attachment.s3_key)}
                      />
                    </td>
                    <td className="py-4 px-2 w-40 font-semibold text-gray-800 flex items-center">
                      {/* <MdPictureAsPdf className="h-5 w-5 mr-2" /> */}
                      {attachment.filename}
                    </td>
                    <td className="p-2 text-gray-600 text-sm">
                      {(() => {
                        const date_data = attachment?.email_date.split(' ');
                        const formattedDate = date_data[0];
                        const formattedTime = date_data[1];
                        return (
                          <>
                            <p className="text-sm text-gray-900 mb-2">{formattedDate}</p>
                            <p className="text-xs text-gray-500">{formattedTime}</p>
                          </>
                        );
                      })()}
                    </td>

                    <td className="p-2 flex items-end justify-start space-x-2">
                      <button
                        onClick={() => handleInvoiceDownload(attachment)}
                        className="px-3 py-1 text-gray-500 text-sm rounded hover:text-black"
                      >
                        <MdOutlineFileDownload />
                      </button>
                      <button
                        onClick={() => {
                          handleChange(attachment);
                          handleDialog(attachment);
                        }}
                        className="px-3 py-1 bg-gray-100 text-black text-xs rounded hover:bg-gray-300"
                      >
                        Import
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            // <p className="text-gray-600 text-center">No attachments found.</p>
            <div className="flex justify-center items-center h-full">
              <div className="w-10 h-10 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <dialog
        ref={modalRef}
        className="backdrop:backdrop-blur-[1px] hidden open:flex z-10 bg-white max-w-[480px] w-full p-8 outline-0 shadow rounded-lg flex-col items-center"
      >
        <span className="font-bold mb-4 text-lg">Upload File</span>
        <form onSubmit={handleSubmit} className="w-full mt-4">
          {selectedAttachment && (
            <ul className="w-full min-h-80 flex flex-col gap-1 max-h-40 mt-4 overflow-y-auto light-scroll">
              <li
                key={selectedAttachment.id}
                className="text-nowrap w-full px-4 py-3 bg-gray-100 rounded-md flex items-center justify-between gap-4"
              >
                <div className="overflow-hidden flex flex-col">
                  <span className="text-sm font-medium overflow-hidden overflow-ellipsis">
                    {selectedAttachment.filename}
                  </span>
                </div>
                <div className="flex items-center gap-4">
                  {window.location.href.includes("invoice-analysis") && 
                  <DropDown
                  options={{ 
                          iv: "Invoice",
                          co: "Contract",
                          bo: "BOL",
                   }}
                  Item={({ docKey, name }) => (
                    <div
                      onClick={() => {
                        setDocType(docKey);
                      }}
                      className="text-xs cursor-pointer bg-white px-4 py-2 border-b hover:bg-gray-100"
                    >
                      {name}
                    </div>
                  )}
                >
                  <div className="px-3 py-2 bg-white border border-gray-50 rounded-lg text-xs flex items-center gap-2">
                    <span>
                      {docType
                        ? {
                          iv: "Invoice",
                          co: "Contract",
                          bo: "BOL",
                        }[docType]
                        : "Select Type"}
                    </span>
                    <span className="material-symbols-rounded text-sm">
                      expand_more
                    </span>
                  </div>
                </DropDown>}
                  {window.location.href.includes("ra-analysis") && 
                  <DropDown
                  options={{ 
                          iv_ra: "Invoice",
                          po: "Purchase Order",
                          da: "Distribution Agreement",
                          ra: "Remittance Advice",
                          co: "Contract",
                   }}
                  Item={({ docKey, name }) => (
                    <div
                      onClick={() => {
                        setDocType(docKey);
                      }}
                      className="text-xs cursor-pointer bg-white px-4 py-2 border-b hover:bg-gray-100"
                    >
                      {name}
                    </div>
                  )}
                >
                  <div className="px-3 py-2 bg-white border border-gray-50 rounded-lg text-xs flex items-center gap-2">
                    <span>
                      {docType
                        ? {
                          iv_ra: "Invoice",
                          po: "Purchase Order",
                          da: "Distribution Agreement",
                          ra: "Remittance Advice",
                          co: "Contract",
                        }[docType]
                        : "Select Type"}
                    </span>
                    <span className="material-symbols-rounded text-sm">
                      expand_more
                    </span>
                  </div>
                </DropDown>}
                  
                </div>
              </li>
            </ul>
          )}
          <div className="flex w-full flex-col items-center gap-1 mt-10">
            <button
              type="submit"
              disabled={!docType}
              className="w-full font-medium bg-lime-600 text-white disabled:bg-gray-200 p-3 rounded-md text-sm"
            >
              Upload
            </button>
            <button
              type="button"
              onClick={() => modalRef.current.close()}
              className="font-medium hover:bg-gray-100 w-full p-3 text-gray-600 rounded-md text-sm"
            >
              Cancel
            </button>
          </div>
        </form>
      </dialog>
    </div>
  );
}

export default AttachmentSidebar;
