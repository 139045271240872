import Img from "../components/Img";
import Img404 from "../static/img/illustration_404.svg";
import {Link} from "react-router-dom";

export default function Page404() {
    return (
        <div className={"flex flex-col items-center justify-center h-full w-full mb-24"}>
            <Img src={Img404} alt={"404"} />
            <span>We couldn't find the page you were looking for.</span>

            <Link to={"/staging-app/dashboard"} className={"px-4 py-3 rounded-md text-accent font-bold bg-nimbey mt-10"}>Go Home</Link>
        </div>
    )
}