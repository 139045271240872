import {Bar} from "react-chartjs-2";
import React from "react";
import {getTimePeriod, handleTip, reduceStats, unitizeAmount, colors} from "./utils";

function TopSuppliers({data, term, postFilters, filters, setFilters}) {
    const activeTab = term === 0 ? "Year" : term === 1 ? "Quarter" : "Month"
    const [reduced, index] = reduceStats(data, "supplier");

    const labels = Object.keys(reduced).filter(x => index[0] in reduced[x]);
    const datasets = [];
    labels.sort((a, b) => reduced[b][index[0]] - reduced[a][index[0]])

    index.slice(0, 2).forEach((index, _in) => {
        datasets.push({
            label: `In ${index}`,
            barThickness: "flex",
            minBarLength: 2,
            backgroundColor: colors[_in],
            data: labels.map(x => reduced[x][index]),
            stack: `stack-${index}`
        })
    })

    return <div id={"supplier-graph"} className={"flex flex-col card aspect-[0.8] lg:aspect-square flex-1 lg:max-h-96"}>
        <div className={"flex flex-col item-start w-full"}>
                <div className={"flex items-center justify-between w-full gap-2 relative"}>
                    <span className={"font-bold text-gray-800 text-md"}>Top Suppliers</span>

                    <div onMouseEnter={(e) => handleTip(e, "supplier-graph")}
                         onMouseLeave={(e) => handleTip(e, "supplier-graph")}
                         className={"show-tip"}>
                        <span className={"material-symbols-rounded text-md"}>info</span>
                        <div className={"tool-tip-cover"}>
                    <span
                        className={"p-2 block px-4 whitespace-normal z-10 text-sm w-[20rem]"}>{`The bar graph shows spending across top suppliers in ${activeTab} (${getTimePeriod(term)})`}</span>
                        </div>
                    </div>
                </div>
                <span className={"text-xs text-gray-600"}>Latest {activeTab}</span>
            </div>
        <div className={"w-full flex-1 overflow-hidden"}>
            <Bar id={"topSuppliers"} width={"100%"} className={"p-2"}
                 data={{
                     labels: labels,
                     datasets: datasets
                 }}
                 options={{
                     responsive: true,
                     maintainAspectRatio: false,
                     aspectRatio: 1.5,
                     indexAxis: 'y',
                     onClick(event, elements, chart) {
                         if (elements.length && elements[0].element) {
                             if (filters?.supplier === labels[elements[0].index]) {
                                 let d = {...filters};
                                 delete d.supplier;
                                 setFilters(d);
                             } else {
                                 setFilters({...filters, supplier: labels[elements[0].index]});
                             }
                         }
                     },
                     plugins: {
                         tooltip: {
                             callbacks: {
                                 label(tooltipItem) {
                                     return `${tooltipItem.dataset.label}: $${tooltipItem.formattedValue}`;
                                 }
                             }
                         }
                     },
                     scales: {
                         x: {
                             min: postFilters.amount,
                             ticks: {
                                 stepSize: postFilters.amount * 0.2,
                                 callback: (tickValue, index, ticks) => unitizeAmount(tickValue)}
                         },
                         y: {
                             ticks: {autoSkip: false},
                             stacked: true
                         }
                     }
                 }}
            />
        </div>
    </div>

}

export default TopSuppliers;