import React, { useState } from "react";
import { Link } from "react-router-dom";
import NimbeyLogo from "../assets_lp2/NimbeyLogo.svg";
import { FaBars } from "react-icons/fa"; // Icon for menu toggle
import Sidebar from "./ui/Sidebar";
import "./Navbar.css";

const navLinkStyle = {
  color: "#000000",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "16px",
  transition: "color 0.3s",
};

const Navbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // State to control sidebar visibility

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav className="w-full flex items-center justify-between sticky top-0 rounded-xl shadow-md py-8 px-4 bg-white mb-4 none lg:mb-0 z-50">
      {/* Logo Section */}
      <div className="">
        <img src={NimbeyLogo} alt="Nimbey Logo" className="h-10 mr-4 ml-20" />
      </div>

      {/* Hamburger Icon for Screens Below 900px */}
      <div
        className="lg:hidden text-2xl cursor-pointer"
        onClick={() => setSidebarOpen(true)} // Open sidebar on click
      >
        <FaBars />
      </div>

      {/* Navigation Links for Screens 900px and Above */}
      <ul className="hidden lg:flex space-x-8 hover:text-lime-600">
        <li>
          <button onClick={() => handleScrollTo("hero")} style={navLinkStyle} className="hover:text-[#B8F668]">
            Home
          </button>
        </li>
        <li>
          <button onClick={() => handleScrollTo("howitworks")} style={navLinkStyle} className="hover:text-[#B8F668]">
            How it works
          </button>
        </li>
        <li>
          <button onClick={() => handleScrollTo("whychoose")} style={navLinkStyle} className="hover:text-[#B8F668]">
            Why Choose Nimbey
          </button>
        </li>
        <li>
          <button onClick={() => handleScrollTo("integrations")} style={navLinkStyle} className="hover:text-black">
            Integrations
          </button>
        </li>
      </ul>

      {/* Try Nimbey Button for Desktop */}
      <div className="hidden lg:block">
        <a target="_blank" href="https://cal.com/agrima/nimbey" className="custom-button flex items-center justify-center border border-[#A8E45B] font-semibold text-[#212B36] w-[150px] h-[45px] rounded-[8px] mr-16">
          Try Nimbey
        </a>
      </div>

      {/* Sidebar Component for Mobile Screens */}
      <Sidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
    </nav>
  );
};

export default Navbar;
