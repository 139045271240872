import React, { useRef, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  DoughnutController,
} from 'chart.js';
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdRefresh } from 'react-icons/md';

// Register necessary components from Chart.js
ChartJS.register(ArcElement, DoughnutController, Tooltip, Legend);

const TopVendors = ({ data, title }) => {
  const chartRef = useRef(null); // Reference to the canvas element
  const chartInstanceRef = useRef(null); // Store the Chart.js instance
  const [filteredData, setFilteredData] = useState(data); // State for filtered data

  useEffect(() => {
    // Cleanup previous chart instance
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Ensure data is structured correctly
    if (!filteredData || !filteredData.currentYear || !filteredData.previousYear) {
      return <div>Error: Invalid data format</div>;
    }

    // Prepare chart data
    const chartData = {
      labels: filteredData.currentYear.map(item => item.name),
      datasets: [
        {
          label: 'Current Year',
          data: filteredData.currentYear.map(item => item.value),
          backgroundColor: '#98CDF3', // Blue
          borderRadius: 4,
          barThickness: 12,
        },
        {
          label: 'Previous Year',
          data: filteredData.previousYear.map(item => item.value),
          backgroundColor: '#97F2B2', // Green
          borderRadius: 4,
          barThickness: 12,
        },
      ],
    };

    // Define chart options
    const options = {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          align: 'center',
          labels: {
            boxWidth: 12,
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 20,
            color: '#6B7280',
          },
        },
      },
      scales: {
        x: {
          grid: {
            color: 'rgba(243, 244, 246, 1)',
            lineWidth: 1,
          },
          ticks: {
            color: '#6B7280',
            font: {
              size: 12,
            },
            callback: (value) => `$${value.toLocaleString()}`,
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            color: '#6B7280',
            font: {
              size: 12,
            },
          },
        },
      },
      onClick(event, elements) {
        if (elements.length) {
          const clickedIndex = elements[0].index;
          const clickedVendor = filteredData.currentYear[clickedIndex].name;

          // Filter data based on clicked vendor
          const newFilteredData = {
            currentYear: filteredData.currentYear.filter(item => item.name === clickedVendor),
            previousYear: filteredData.previousYear.filter(item => item.name === clickedVendor),
          };
          
          setFilteredData(newFilteredData);
        }
      }
    };

    // Create new chart instance
    if (chartRef.current) {
      chartInstanceRef.current = new ChartJS(chartRef.current, {
        type: 'bar',
        data: chartData,
        options,
      });
    }

    // Cleanup on unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [filteredData]); // Only re-run if filteredData changes

  // Function to refresh the chart data
  const handleRefresh = () => {
    setFilteredData(data);
  };

  return (
    <div className="bg-white border rounded-xl text-left h-fit">
      <div className="border-b pb-1 p-6 flex items-center justify-between">
        <div>
          <h2 className="text-lg font-semibold text-gray-800">{title? title : "Top Vendors by Spend"}</h2>
          <span className="text-gray-500 text-xs">This Year</span>
        </div>
        <div>
          <IoIosInformationCircleOutline />
        </div>
      </div>
      
      {/* Refresh Button */}
      <button 
        onClick={handleRefresh} 
        className="text-gray-500 py-2 px-4 rounded hover:text-gray-800 transition"
      >
        <MdRefresh />
      </button>

      <div className="h-[300px] -mt-2 relative mx-6 pb-6 cursor-pointer"> {/* Added cursor-pointer */}
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default TopVendors;
