import axios from "axios";

/*

axios is the only way used in this React application to communicate with the server.
given below are some endpoints created for all the routes present in the server.

REACT_APP_API_URL is an environment variable present in the .env file, which should be located in the same folder as
package.json. If not present then please create it and set this variable to the backend url

for development, it should be as follows
REACT_APP_API_URL=http://localhost:8000

for production,
REACT_APP_API_URL=https://nimbey.com

Remember do not add forward slash '/' at the end of the URL

*/

axios.defaults.withCredentials = true; // this makes sure that cookies and other authentications are sent in every request, this is a global configuration

// following will set api to "http://localhost:8000/api"
const api = `${process.env.REACT_APP_API_URL}/api`;

// all the request made for authentication goes through auth
const auth = `${api}/auth`;

// same for products and orders
const products = `${api}/products`;
const orders = `${api}/orders`;

// any request which needs admin privileges are present in the admin sub app of the server and this points it to that
const admin = `${api}/admin`;
const utils = `${api}/utils`;

// the side-quest part of the application, uses this
const sideQuest = `${api}/side-quest`;

async function request(url, payload, method = "POST", config = {}) {
  /*
   * this request function is made which contains all the parameter ever used in this application
   * url -> the path you have to make request to
   * payload -> a json object in case of POST method or null in case of GET
   * config -> a json object which is spread in the axios config object to cover rest of the parameters
   *
   * this function is used for every request made is recommended to continue to do so. in case this doesn't
   * match with the requirements the please improve it as required
   *
   *
   * the api responds in following structure in success
   *
   * {
   *       success: true, payload: ...data....
   * }
   *
   * if case of fails
   *
   * {
   *       success: false, detail: ...string....       the detail here is a string which tells about the error, status codes are also present
   * }
   *
   *
   * read the backend code to understand the endpoints created here
   * */
  try {
    const res = await axios.request({
      method: method,
      url: url,
      data: payload,
      credentials: "include",
      withCredentials: true, // Ensure cookies are sent
      ...config,
    });
    return [res.status, res.data.success ? res.data.payload : res.data.detail];
  } catch (e) {
    if (e.code === "ERR_NETWORK") {
      return [500, "Network error"];
    }
    return [e.response.status, e.response.data];
  }
}

function waitList(payload) {
  return request(`${api}/nimbey/wait-list`, payload);
}

export default request;
export { api, auth, products, orders, admin, utils, sideQuest, waitList };
