import {createSlice} from "@reduxjs/toolkit";


const userDataSlice = createSlice({
    name: 'userData',
    initialState: {
        locations: []
    },
    reducers: {
        init: (state, action) => {
            return {locations: state.locations || []};
        },
        setState: (state, action) => {
            return {...action.payload};
        },
        updateState: (state, action) => {
            return {...state, ...action.payload};
        }
    }
})

export default userDataSlice;