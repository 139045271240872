import React from "react";
import { motion } from "framer-motion";
import automatedImage from "../assets_lp2/updated_aiCopilot.svg";
import nimbeyImage from "../assets_lp2/updated_100sOfCarriers.svg";
import emailIcon from "../assets_lp2/icons/emailIcon.svg";
import shieldIcon from "../assets_lp2/icons/shieldIcon.svg";
import recycleIcon from "../assets_lp2/icons/recycleIcon.svg";

const Features = () => {
    const features = [
        {
            title: "Automate your Accounts Payable.",
            descriptions: [
                "Nimbey is the first fully automated accounts payable/receivable co-pilot built for logistics. ",
                "Speed up your entire process, from gathering data to making payments - save finance team up to 80% of cost and time.",
            ],
            buttonText: "How it works?",
            imageSrc: automatedImage,
        },
        {
            title: "100s of freight invoices?",
            description:
                "Nimbey AI effortlessly pulls invoices from any source and cross-references them with relevant documents for quick validation. 100s of documents? Multiple formats? Nimbey will get the job done.",
            buttonText: "Get Started",
            imageSrc: nimbeyImage,
            subFeatures: [
                {
                    icon: emailIcon,
                    text: "AI recognizes and captures invoice info coming through email, EDI, or TMS.",
                },
                {
                    icon: shieldIcon,
                    text: "AI matches invoice to contract, Bill of Lading, Proof of delivery, and other documents.",
                },
                {
                    icon: recycleIcon,
                    text: "Expert Humans in the loop review payments before they are made.",
                },
            ],
        },
    ];

    // Animation Variants
    const featureVariants = {
        hidden: (direction) => ({
            opacity: 0,
            x: direction === "left" ? -100 : 100,
        }),
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut",
            },
        },
    };

    const subFeatureVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2,
                duration: 0.5,
                ease: "easeOut",
            },
        }),
    };

    return (
        <section className="relative mx-4 py-10 px-4 sm:px-8 lg:px-16 bg-[#201c1c] rounded-3xl">
            {/* Fader Effect */}
            {/* <div className="absolute inset-x-0 -bottom-1 h-20 lg:h-[100px] bg-gradient-to-t from-gray-800 to-transparent"></div>
            <div className="absolute inset-x-0 -top-1 h-20 lg:h-[100px] bg-gradient-to-t from-transparent to-[#ffffff]"></div> */}

            <div className="container mx-auto my-20 flex flex-col gap-10">
                {features.map((feature, index) => (
                    <motion.div
                        key={index}
                        className={`flex flex-col md:flex-row justify-center items-center mb-12 gap-20 ${index % 2 === 0 ? "md:flex-row-reverse" : ""
                            }`}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        custom={index % 2 === 0 ? "right" : "left"}
                        variants={featureVariants}
                    >
                        {/* Image */}
                        <motion.div
                            className="w-full md:w-5/12 p-6 mb-6 md:mb-0"
                            custom={index % 2 === 0 ? "right" : "left"}
                            variants={featureVariants}
                        >
                            <img
                                src={feature.imageSrc}
                                loading="lazy"
                                alt={feature.title}
                                className="w-full h-auto rounded-lg"
                            />
                        </motion.div>

                        {/* Content */}
                        <motion.div
                            className="w-full md:w-5/12 px-4 md:px-20 text-center md:text-left"
                            custom={index % 2 === 0 ? "left" : "right"}
                            variants={featureVariants}
                        >
                            <h2 className="text-3xl text-gray-100 mb-6 w-auto md:w-96">
                                {feature.title}
                            </h2>

                            {/* Descriptions */}
                            {feature.descriptions ? (
                                feature.descriptions.map((desc, i) => (
                                    <p key={i} className="text-gray-400 text-base mb-2">
                                        {desc}
                                    </p>
                                ))
                            ) : (
                                <p className="text-gray-400 text-base mb-4">{feature.description}</p>
                            )}

                            {/* Subfeatures */}
                            {feature.subFeatures && (
                                <motion.ul
                                    className="mt-6 flex gap-4"
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                >
                                    {feature.subFeatures.map((subFeature, i) => (
                                        <motion.li
                                            key={i}
                                            className="flex flex-col items-start w-1/3"
                                            custom={i}
                                            variants={subFeatureVariants}
                                        >
                                            <img
                                                src={subFeature.icon}
                                                alt="Subfeature Icon"
                                                className="w-6 h-6"
                                            />
                                            <span className="text-gray-300 text-xs">{subFeature.text}</span>
                                        </motion.li>
                                    ))}
                                </motion.ul>
                            )}

                            {/* Button */}
                            <motion.button
                                className="mt-6 px-6 py-2 border text-gray-100 border-[#E4E4E4] rounded-md transition-all duration-300 hover:border-green-500 focus:outline-none"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {feature.buttonText}
                            </motion.button>
                        </motion.div>
                    </motion.div>
                ))}
            </div>
        </section>
    );
};

export default Features;