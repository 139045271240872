import React, { useEffect, useState } from "react";
import Table from "./TableForAnalysisSidebar";
import { downloadRaDoc, getInvoiceDocs } from "../api/dashboard";

function AnalysisSideBar({ record_id, invoiceData, setDescribeAnalysis }) {
  console.log("AnalysisSideBar invoiceData: ", invoiceData);
  // console.log("current url: ", window.location.href);
  const [tableItems, setTableItems] = useState(invoiceData.table_data);
  const [parsedSummary, setParsedSummary] = useState(null);
  const [documents, setDocuments] = useState([]);


  // Uncomment this before
  useEffect(() => {
    if (invoiceData) {
      setTableItems(invoiceData.table_data || []);
      // Parse the summary if it's a string
      if (typeof invoiceData.summary === 'string') {
        // console.log(invoiceData.summary);
        setParsedSummary(invoiceData.summary);
      } else {
        try {
          // Clean up the summary string if necessary
          const cleanedSummary = invoiceData.summary.replace(/\\/g, ''); // Remove any escape characters
          const parsed = JSON.parse(cleanedSummary);
          setParsedSummary(parsed);
        } catch (e) {
          console.error("Error parsing summary:", e);
          setParsedSummary(null);
        }
      }
    }
  }, [invoiceData]);

  // gets all the ra uploaded docs and downloads each doc
  useEffect(() => {
    const fetchDocuments = async () => {
      if (invoiceData?.invoice_number) {
        const docs = await getInvoiceDocs(invoiceData.invoice_number);
        setDocuments(docs);
      }
    };

    fetchDocuments();
  }, [invoiceData?.invoice_number]);
  
  const expectedDocTypes = ["Invoice", "Bol", "Contract", "POD"];

  const presentTypes = documents.map((document) => document.type);

  // Check if "Signed BOL" is in presentTypes and add "POD" if true
  if (presentTypes.includes("Signed BOL")) {
    presentTypes.push("POD");
  }

  const missingTypes = expectedDocTypes.filter(type => !presentTypes.includes(type));

  const handleDownload = async (s3_key) => {
    const downloadUrl = await downloadRaDoc(s3_key);
    if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    } else {
      console.log("Failed to get download URL.");
    }
  };

  const renderSummarySection = () => {
    // console.log(" invoiceData.summary.Summary: ", typeof invoiceData.summary.Summary);
    // console.log(" invoiceData.summary: ", invoiceData.summary);
    const parsedSummary = JSON.parse(invoiceData.summary);
    // console.log("renderSummarySection parsedSummary:", parsedSummary);
    if (!invoiceData) return <p>No analysis summary available</p>;

    return (
      <div className="flex flex-col gap-4">
        {/* Top Level Summary Information */}
        <div className="flex flex-col gap-3">
          <div className="flex items-center">
            <span className="w-40">Pay to:</span>
            <span>{invoiceData.billed_to_name}</span>
          </div>
          <div className="flex items-center">
            <span className="w-40">Invoice Amount:</span>
            <span>{invoiceData.total_amount}</span>
          </div>
          <div className="flex items-center">
            <span className="w-40">Total Invoice Amount:</span>
            <span>{invoiceData.total_amount}</span>
          </div>
        </div>

        {/* Table Component */}
        {/* <Table invoice_number={record_id} data={tableItems} setData={setTableItems} /> */}
        <Table invoice_number={record_id} data={tableItems.data} seData={setTableItems} />


        {parsedSummary.Summary && (
          <div className="mt-6">
            <h3 className="font-bold mb-4 text-gray-700">Detailed Analysis</h3>
            {Object.entries(parsedSummary.Summary).map(([key, value]) => (
              <div key={key} className="mb-4 pb-3 border-b border-gray-100">
                <p className="text-sm text-gray-600 leading-relaxed">{value}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex items-stretch fixed h-full lg:max-w-screen-md w-full shadow-xl bg-white right-0 bottom-0 z-50 flex-1 overflow-x-auto lg:overflow-hidden lg:overflow-y-auto light-scroll">
      {/* Borders */}
      <div className="flex flex-col w-[5px] h-full">
        {["#005B8C", "#00A7C9", "#A58EFD", "#005162"].map((color) => (
          <div
            key={color}
            className="h-full w-[5px]"
            style={{ backgroundColor: color }}
          />
        ))}
      </div>

      {/* Content */}
      <div className="flex flex-col w-full pt-6 px-6 flex-1 h-full">
        {/* Header */}
        <div className="flex items-center justify-between pb-2 mb-4 border-b">
          <h1 className="text-xl w-3/4">Invoice Analysis</h1>
          <button onClick={() => setDescribeAnalysis(null)}>
            <span className="material-symbols-rounded text-2xl">close</span>
          </button>
        </div>

        {/* Summary Content */}
        <div className="flex flex-col w-full">
          {renderSummarySection()}
        </div>

        <div className="h-fit pb-20 my-10">
          <h1>Documents</h1>
          <div>
              <ul className="divide-y divide-gray-200">
                {documents.filter((document) => document.type !== "Signed BOL").map((document, index) => {
                  const filename = document.name.split("/").pop();
                  const parts = filename.split("-");
                  const timestamp = parts[0];
                  const type = parts[1];
                  const nameAndExtension = parts.slice(2).join("-");
                  const name = nameAndExtension.replace(".pdf", "");
                  const extension = ".pdf";

                  return (
                    <li
                      key={index}
                      className="flex items-center justify-between px-4 py-3 hover:bg-gray-50 transition-colors"
                    >
                      <div className="flex items-center">
                        <span className="text-blue-600 material-symbols-rounded mr-3">description</span>
                        <div>
                          <span className="font-medium text-gray-900">{name}</span>
                          <span className="text-gray-500">{extension}</span>
                        </div>
                      </div>
                      <button
                        onClick={() => handleDownload(document.s3_key)}
                        className="flex items-center text-sm text-blue-600 hover:text-blue-800 font-medium"
                      >
                        <span className="material-symbols-rounded mr-1">download</span>
                      </button>
                    </li>
                  );
                })}
              </ul>

              {/* Missing Document Types Alert */}
              {missingTypes.length > 0 && (
                <div className="mt-4 p-4 border-l-4 border-amber-500 bg-amber-50 rounded-md">
                  <div className="flex">
                    <span className="flex-shrink-0 text-amber-500 material-symbols-rounded">warning</span>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-amber-800">Missing Required Documents</h3>
                      <div className="mt-1 text-sm text-amber-700">
                        <ul className="list-disc pl-5 space-y-1">
                          {missingTypes.map((type) => (
                            <li key={type}>{type}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Conditional Render for "POD AS SIGNED BOL" */}
              {presentTypes.includes("Signed BOL") && (
                <div className="mt-4 p-4 border-l-4 border-green-500 bg-green-50 rounded-md">
                  <div className="flex">
                    <span className="flex-shrink-0 text-green-500 material-symbols-rounded">check_circle</span>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-green-800">POD available - Signed BOL</h3>
                    </div>
                  </div>
                </div>
              )}
            </div>
        </div>
      </div>
    </div>
  );
}

export default AnalysisSideBar;


////////////////////////
// import React, { useEffect, useState } from "react";
// import Table from "./TableForAnalysisSidebar";

// function AnalysisSideBar({ record_id, invoiceData, setDescribeAnalysis }) {
//   const [tableItems, setTableItems] = useState([]);
//   const [parsedSummary, setParsedSummary] = useState([]);

//   // Mock data for testing
//   const mockInvoiceData = {
//     "Invoice Amount": "$2343.00",
//     "Total Invoice Amount": "$2343.00",
//     "table_data": [
//       {
//         description: "Freight Charge",
//         unit_price: 3200.00,
//         quantity: 1,
//         amount: 3200.00,
//         corrected_amount: 3200.00,
//         verified: true
//       },
//       {
//         description: "Freight Discount",
//         unit_price: -2176.00,
//         quantity: 1,
//         amount: 2176.00,
//         corrected_amount: 2176.00,
//         verified: true
//       },
//       {
//         description: "Residential Delivery",
//         unit_price: 28.00,
//         quantity: 1,
//         amount: 28.00,
//         corrected_amount: 28.00,
//         verified: false
//       },
//       {
//         description: "Liftgate Charge",
//         unit_price: 30.00,
//         quantity: 1,
//         amount: 30.00,
//         corrected_amount: 30.00,
//         verified: true
//       },
//     ],
//     "summary": [
//       "Freight Charge - The freight charge is listed as $10,000 on the Invoice.",
//       "Freight Discount - The invoice mentions a discount of 70% on the freight which comes to $7,000...",
//     ],
//     billed_to_name: "John Doe",
//     total_amount: "$2343.00"
//   };

//   // Use mock data if invoiceData is not provided
//   const dataToUse = invoiceData || mockInvoiceData;
//   // const dataToUse = mockInvoiceData || invoiceData;

//   useEffect(() => {
//     if (dataToUse) {
//       setTableItems(dataToUse.table_data || []);
      
//       // Check if summary is an array
//       if (Array.isArray(dataToUse.summary)) {
//         setParsedSummary(dataToUse.summary);
//       } else if (typeof dataToUse.summary === 'string') {
//         setParsedSummary([dataToUse.summary]); // Wrap string in an array
//       } else {
//         console.error("Invalid summary format:", dataToUse.summary);
//         setParsedSummary([]); // Set to empty array if format is invalid
//       }
//     }
//   }, [dataToUse]);

//   const renderSummarySection = () => {
//     if (!dataToUse) return <p>No analysis summary available</p>;

//     setParsedSummary(JSON.parse(dataToUse.summary))

//     return (
//       <div className="flex flex-col gap-4">
//         {/* Top Level Summary Information */}
//         <div className="flex flex-col gap-3">
//           <div className="flex items-center">
//             <span className="w-40">Pay to:</span>
//             <span>{dataToUse.billed_to_name}</span>
//           </div>
//           <div className="flex items-center">
//             <span className="w-40">Invoice Amount:</span>
//             <span>{dataToUse.total_amount}</span>
//           </div>
//           <div className="flex items-center">
//             <span className="w-40">Total Invoice Amount:</span>
//             <span>{dataToUse.total_amount}</span>
//           </div>
//         </div>

//         {/* Table Component */}
//         <Table invoice_number={record_id} data={tableItems} setData={setTableItems} />

//         {/* Summary Section */}
//         {parsedSummary.length > 0 && (
//           <div className="mt-6">
//             <h3 className="font-bold mb-4 text-gray-700">Detailed Analysis</h3>
//             {parsedSummary.map((item, index) => (
//               <div key={index} className="mb-4 pb-3 border-b border-gray-100">
//                 <p className="text-sm text-gray-600 leading-relaxed">{item}</p>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     );
//   };

//   return (
//     <div className="flex items-stretch fixed h-full lg:max-w-screen-md w-full shadow-xl bg-white right-0 bottom-0 z-50 flex-1 overflow-x-auto lg:overflow-hidden lg:overflow-y-auto light-scroll">
//       {/* Borders */}
//       <div className="flex flex-col w-[5px] h-full">
//         {["#005B8C", "#00A7C9", "#A58EFD", "#005162"].map((color) => (
//           <div
//             key={color}
//             className="h-full w-[5px]"
//             style={{ backgroundColor: color }}
//           />
//         ))}
//       </div>

//       {/* Content */}
//       <div className="flex flex-col w-full pt-6 px-6 flex-1 h-full">
//         {/* Header */}
//         <div className="flex items-center justify-between pb-2 mb-4 border-b">
//           <h1 className="text-xl w-3/4">Invoice Analysis</h1>
//           <button onClick={() => setDescribeAnalysis(null)}>
//             <span className="material-symbols-rounded text-2xl">close</span>
//           </button>
//         </div>

//         {/* Summary Content */}
//         <div className="flex flex-col w-full">
//           {renderSummarySection()}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AnalysisSideBar;