import {useEffect, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import Brand from "./cards/Brand";
import NewsCard from "./cards/NewsCard";
import TopSupplier from "./cards/TopSupplier";
import SupplierClients from "./cards/SupplierClients";
import TotalPurchases from "./cards/TotalPurchases";
import ProductCategories from "./cards/ProductCategories";
import SupplierLocations from "./cards/SupplierLocations";
import FinancialInformation from "./cards/FinancialInformation";
import FinancialStats from "./cards/FinancialStats";
import Statistics from "./cards/Statistics";
import {getFinancialStatements, getProfile} from "../../api/sideQuest";


function SideQuests() {
    const {uri} = useParams(); // /p/:uri part of the URL

    const chat = useOutletContext(); // gets the unique chat ID
    const [loading, setLoading] = useState(false); // fetching the whole profile
    const [data, setData] = useState(null); // saves the profiles data
    const [supplierURI, setSupplierURI] = useState(null); // saves the selected supplier uri
    const [filings, setFilings] = useState(null); // saves the financial statements
    const [secLoading, setSecLoading] = useState(false); // fetching financial statements
    const [ratios, setRatios] = useState(null); // saves the financial ratios
    const [popFinance, setPopFinance] = useState(false); // toggle for finance card pop-up
    const [tab, setTab] = useState(false); // toggle for the stats to mini-finance card

    // whole profile
    useEffect(() => {
        if (!uri) return;

        // clearing previous values
        setData(null);
        setTab(false);
        setRatios(null);
        setPopFinance(false);
        setSupplierURI(null);
        setFilings(null);

        // starts loading
        setLoading(true);
        getProfile(`company/${uri}`)
            .then(([status, data]) => {
            if (status === 200) {
                setData(data);
            }
        })
            .finally(() => setLoading(false));
    }, [uri]);

    // financial information
    useEffect(() => {
        if (data && data.profile.stock_symbol) {
            setSecLoading(true);
            const ticker = data.profile.stock_symbol.split(":")[1];
            getFinancialStatements(ticker)
                .then(([status, data]) => {
                if (status === 200) {
                    setFilings(data.docs);
                    setRatios(data.ratios);
                    setTab(true);
                } else {
                    setFilings([]);
                    setRatios({});
                }
            })
                .finally(() => setSecLoading(false));
        } else {
            setSecLoading(false); setFilings([]);
        }
    }, [data]);

    if (loading) return <div id={"content"}
                             className={`flex flex-col h-full w-full gap-3 md:gap-2 p-2 xl:px-0 pb-24 xl:pb-0`}>
        <div className={"flex items-center gap-3 md:gap-2"}>
            <div className={"animate-pulse bg-gray-200 flex-1 h-40 rounded-md"}/>
            <div className={"animate-pulse bg-gray-200 flex-1 hidden lg:flex h-40 rounded-md"}/>

        </div>
        <div className={"flex items-center gap-3 md:gap-2"}>
            <div className={"animate-pulse bg-gray-200 flex-1 h-64 rounded-md"}/>
            <div className={"animate-pulse bg-gray-200 flex-1 hidden lg:flex h-64 rounded-md"}/>
            <div className={"animate-pulse bg-gray-200 flex-1 hidden lg:flex h-64 rounded-md"}/>
        </div>
        <div className={"flex items-center gap-3 md:gap-2 flex-1"}>
            <div className={"animate-pulse bg-gray-200 flex-1 h-full rounded-md"}/>
            <div className={"animate-pulse bg-gray-200 flex-[2] hidden lg:flex h-full rounded-md"}/>
        </div>
    </div>

    if (!data) return <></>

    return <div id={"content"} className={`flex flex-col w-full gap-3 md:gap-2 p-2 md:px-0`}>
        <div className={"flex flex-wrap w-full gap-3 md:gap-2"}>
            {/*  Brand Data  */}
            <Brand chat={chat} data={data}/>

            <div className={"flex flex-col lg:flex-row flex-1 gap-3"}>
                {/*  Quick Stats  */}
                <Statistics filings={filings} setTab={setTab} tab={tab} data={data} secLoading={secLoading}/>

                {/*  Financial Stats  */}
                <FinancialStats popFinance={popFinance} filings={filings} setPopFinance={setPopFinance}
                                setTab={setTab} tab={tab} ratios={ratios} secLoading={secLoading}/>
            </div>
        </div>
        {/*  Financial Information  */}
        <FinancialInformation filings={filings} setPopFinance={setPopFinance} setTab={setTab} tab={tab}
                              popFinance={popFinance} ratios={ratios}/>

        <div className={"flex flex-col lg:flex-row lg:flex-wrap w-full gap-3 md:gap-2"}>
            {/*  Top Supplier  */}
            <TopSupplier chat={chat} setSupplierURI={setSupplierURI} supplierURI={supplierURI} data={data}/>

            {/*  Supplier Clients  */}
            <SupplierClients chat={chat} setSupplierURI={setSupplierURI} supplier_uri={supplierURI}/>

            {/*  News Card  */}
            <NewsCard data={data}/>
        </div>

        <div className={"flex flex-wrap w-full gap-3 md:gap-2"}>
            <ProductCategories chat={chat} data={data}/>
            <TotalPurchases chat={chat} data={data}/>
        </div>

        <div className={"flex flex-wrap w-full gap-3 md:gap-2"}>
            <SupplierLocations chat={chat} data={data}/>
        </div>
    </div>

}


export default SideQuests;