import React, { useState, useEffect } from "react";
import logo from "../static/img/logos/NimbeyLogo.svg";
import mobilelogo from "../static/img/logos/Lemon.png";
import './navbar.css';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <nav className={`shadow-md rounded-b-md bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20 fixed top-0 left-0 right-0 md:right-4 z-50 transition-all duration-300 ${
      isScrolled ? 'bg-gray-200 text-black ' : 'bg-gray-900 text-white'
    }`}>
      <div className="w-full container mx-auto px-4 py-3 flex justify-between items-center relative">
        {/* Logo */}
        <div className="w-[150px] text-lg font-bold">
          <a href="/learnmore2">
            <img
              src={logo}
              alt="Nimbey Logo"
              className="hidden md:block h-8"
            />
            <img
              src={mobilelogo}
              alt="Mobile Logo"
              className="block md:hidden h-8"
            />
          </a>
        </div>

        {/* Navigation Links */}
        <ul className="hidden md:flex font-medium space-x-6 absolute left-1/2 transform -translate-x-1/2">
          {['Home', 'How It Works?', 'Why Choose Nimbey?', 'Integrations'].map((item) => (
            <li key={item}>
              <p
                onClick={() => handleScrollTo(item.toLowerCase().replace(/\s/g, '-').replace(/\?/g, ''))}
                className={`hover:text-[#A8E45B] transition-colors duration-300 cursor-pointer ${
                  isScrolled ? 'text-gray-800' : 'text-white'
                }`}
              >
                {item}
              </p>
            </li>
          ))}
        </ul>

        {/* Try Nimbey Button */}
        <div className="w-[150px] flex justify-end">
          <a
            target="_blank"
            href="https://cal.com/agrima/nimbey"
            rel="noreferrer"
            className={`hidden custom-button md:flex items-center justify-center border text-sm font-medium border-gray-100 rounded-lg transition-all duration-300 `}
          >
            Try Nimbey
          </a>

          {/* Mobile Menu Button */}
          <button
            className={`md:hidden focus:outline-none transition-colors duration-300 `}
            onClick={() => {
              const menu = document.getElementById("mobile-menu");
              if (menu) menu.classList.toggle("hidden");
            }}
          >
            <span className="material-symbols-rounded">menu</span>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div id="mobile-menu" className={`hidden md:hidden w-full `}>
        <ul className="flex flex-col space-y-2 p-4">
          {['Home', 'How It Works', 'Why Choose Nimbey', 'Integrations'].map((item) => (
            <li key={item}>
              <p
                onClick={() => handleScrollTo(item.toLowerCase().replace(/\s/g, '-').replace(/\?/g, ''))}
                className="hover:text-[#A8E45B] transition-colors duration-300"
              >
                {item}
              </p>
            </li>
          ))}
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://cal.com/agrima/nimbey"
              className={`custom-button flex items-center justify-center border font-semibold w-[150px] h-[45px] rounded-[8px] transition-all duration-300 ${
                isScrolled 
                  ? 'border-[#A8E45B] text-[#212B36] hover:bg-[#A8E45B] hover:text-white' 
                  : 'border-white text-white hover:bg-white hover:text-[#212B36]'
              }`}
            >
              Try Nimbey
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;