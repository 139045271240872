import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import FallbackImage from "../../static/img/fallback.png";
import Img from "../../components/Img";
import {orderDetails} from "../../api/dashboard";


export default function OrderDetails() {

    const {orderID} = useParams();
    const [order, setOrderDetails] = useState(null);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true);
        orderDetails(orderID)
            .then(([status, data]) => {
                if (status === 200) {
                    setOrderDetails(data);
                }
            })
            .finally(() => setLoader(false));
    }, [orderID]);

    if (loader)
        return <div className={"w-full h-full flex items-center justify-center"}>
            <span className={"material-symbols-rounded animate-spin"}>progress_activity</span>
        </div>

    return <div className={"flex flex-col w-full flex-1 rounded-md"}>
        <div className={"p-2"}>
            <span className={"flex text-xl gap-1 w-full"}><b className={"font-semibold"}>Order</b> Details</span>
        </div>
        <div className={"flex gap-4 w-full my-4 items-stretch p-0 overflow-hidden flex-wrap"}>
            <div className={"flex flex-col flex-1 card border-0 gap-4 p-6 w-full bg-green-100"}>

                <div className={"flex flex-col gap-2"}>
                        <span
                            className={"font-bold text-2xl"}>{order.status === "pending" ? "Delivery Pending..." : "Order Declined"}</span>
                    {
                        order.status === "pending" && <span
                            className={"text-sm"}>Your item is expected to be delivered by {new Date(order.expected_delivery_date).toDateString()}<br/>
                            Ordered on <b>{new Date(order.order_date).toDateString()}</b></span>
                    }
                </div>

                <div className={"flex gap-4 w-full"}>
                    <div className={"flex flex-col flex-[2] gap-4"}>
                        <div className={"flex flex-col"}>
                                    <span className={"text-md font-bold"}>
                                        {order.product_description}{order.product_description && order.product_name ? " | " : ""}{order.product_name}
                                    </span>
                            <span className={"flex items-center"}>
                                        {Array(5).fill(0).map((x, i) =>
                                            <span key={i.toString()}
                                                  className={`material-symbols-rounded mso-fill ${i <= 3 ? "text-amber-400" : "text-gray-600"}`}>star</span>
                                        )}
                                <span className={"text-sm mx-2 text-gray-600"}>(12k reviews)</span>
                                    </span>
                        </div>

                        <div className={"flex items-center gap-2"}>
                            <span className={"font-bold text-xl"}>${Number(order.total_amount).toLocaleString("default", {currency: "USD", minimumFractionDigits: 2})}</span>
                        </div>

                        <div className={"flex flex-col"}>
                            <span className={"text-xs font-bold text-cyan-500"}>ABOUT THIS ITEM</span>
                            <span className={"text-sm text-gray-600"}>{order.product_specifications || "-"}</span>
                        </div>

                    </div>
                    <div className={"flex flex-1"}>
                        <Img src={order.product_image || FallbackImage} alt={order.product_name}
                             className={"w-full aspect-video rounded-md object-contain"}/>
                    </div>
                </div>
            </div>
            <div className={"flex flex-col flex-1 card"}>
                <div className={"flex flex-col p-4 w-full gap-4"}>
                    <span className={"text-cyan-500 font-semibold text-sm"}>Order Information</span>
                    <div className={"flex flex-col items-start justify-evenly gap-4 flex-wrap"}>
                        <div className={"flex items-start justify-between flex-1 w-full"}>
                            <div className={"flex flex-col gap-1 items-start flex-1 min-w-[150px] "}>
                                <span className={"text-sm text-gray-600"}>Price</span>
                                <span className={"text-xl font-bold"}>${Number(order.total_amount).toLocaleString("default", {currency: "USD", minimumFractionDigits: 2})}</span>
                            </div>
                            <div className={"flex flex-col gap-1 items-start flex-1 min-w-[150px] "}>
                                <span className={"text-sm text-gray-600"}>Quantity</span>
                                <span className={"text-xl font-bold"}>{order.quantity}</span>
                            </div>
                        </div>
                        <div className={"flex items-center w-full justify-between flex-1 min-w-[150px]"}>
                                <span className={"text-xs flex-1 text-gray-600"}>Unit Price</span>
                            <span className={"text-xs flex-1 font-semibold"}>${Number(order.unit_price).toLocaleString("default", {currency: "USD"}) || "-"}</span>
                        </div>
                        <div className={"flex items-center w-full justify-between flex-1 min-w-[150px]"}>
                                <span className={"text-xs flex-1 text-gray-600"}>Name of
                                    Supplier</span>
                            <span className={"text-xs flex-1 font-semibold"}>{order.supplier_name || "-"}</span>
                        </div>
                        <div className={"flex items-center w-full justify-between flex-1 min-w-[150px]"}>
                            <span className={"text-xs flex-1 text-gray-600"}>Specification</span>
                            <span
                                className={"text-xs flex-1 font-semibold"}>{order.product_specifications || "-"}</span>
                        </div>
                        <div className={"flex items-center w-full justify-between flex-1 min-w-[150px]"}>
                                <span className={"text-xs flex-1 text-gray-600"}>Approved
                                    by</span>
                            <span className={"text-xs flex-1 font-semibold"}>{order.full_name}</span>
                        </div>

                        <div className={"flex items-center w-full justify-between flex-1 min-w-[150px]"}>
                                <span className={"text-xs flex-1 text-gray-600"}>Product
                                    Description</span>
                            <span
                                className={"text-xs flex-1 font-semibold"}>{order.product_description || "-"}</span>
                        </div>

                        <div className={"flex items-center w-full justify-between flex-1 min-w-[150px]"}>
                            <span className={"text-xs flex-1 text-gray-600"}>Category</span>
                            <span className={"text-xs flex-1 font-semibold"}>{order.cate}</span>
                        </div>

                        <div className={"flex items-center w-full justify-between flex-1 min-w-[150px]"}>
                                <span className={"text-xs flex-1 text-gray-600"}>Required
                                    By</span>
                            <span className={"text-xs flex-1 font-semibold"}>{order.expected_delivery_date}</span>
                        </div>

                        <div className={"flex items-center w-full justify-between flex-1 min-w-[150px]"}>
                            <span className={"text-xs flex-1 text-gray-600"}>Location</span>
                            <span className={"text-xs flex-1 font-semibold"}>{order.loc}</span>
                        </div>

                        <div className={"flex items-center w-full justify-between flex-1 min-w-[150px]"}>
                                <span className={"text-xs flex-1 text-gray-600"}>Details about
                                    request</span>
                            <span className={"text-xs flex-1 font-semibold"}>{order.request_info || "-"}</span>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>
}