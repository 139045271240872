// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { Helmet } from "react-helmet-async";
// import { FormElement } from "../../components/Input";
// import { activateUser, requestAuthCode } from "../../api/auth";

// export default function Activate() {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [errors, setErrors] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [code, setCode] = useState(Array(5).fill(undefined));
//   const [codeSent, setCodeSent] = useState(false);

//   const requestCode = (evt) => {
//     evt.preventDefault();
//     if (email && evt.target.reportValidity()) {
//       setLoading(true);
//       requestAuthCode({ email })
//         .then(([status, _]) => {
//           switch (status) {
//             case 200: {
//               setCodeSent(true);
//               break;
//             }
//             case 404: {
//               document.querySelector("input[name=email]").focus();
//               setErrors({ email: "User doesn't exist" });
//               break;
//             }
//             default: {
//               setErrors({ form: "Something went wrong" });
//             }
//           }
//         })
//         .finally(() => setLoading(false));
//     }
//   };

//   const activate = (evt) => {
//     evt.preventDefault();
//     if (
//       evt.target.reportValidity() &&
//       codeSent &&
//       email &&
//       code.filter((c) => typeof c === "number" && !isNaN(c)).length === 5
//     ) {
//       setLoading(true);
//       activateUser({ email, code: code.join("") })
//         .then(([status, _]) => {
//           console.log("signup code status: ", status);
//           switch (status) {
//             case 200: {
//               navigate("/staging-app/activate-success");
//               break;
//             }
//             case 401: {
//               setErrors({ email: "Invalid code" });
//               break;
//             }
//             case 404: {
//               setErrors({ email: "User doesn't exist" });
//               break;
//             }
//             default: {
//               setErrors({ form: "Something went wrong" });
//               break;
//             }
//           }
//         })
//         .finally(() => setLoading(false));
//     }
//   };

//   const handleKeys = (evt, index) => {
//     if (evt.key === "Backspace") {
//       const el = document.querySelector(
//         `input[name=code-${Math.max(
//           evt.target.value === "" ? index - 1 : index,
//           0
//         )}]`
//       );
//       if (el) {
//         let k = [...code];
//         k[index] = undefined;
//         setCode(k);
//         el.focus();
//       }
//     }
//   };

//   const handleCode = (evt, index) => {
//     const val = Number.parseInt(evt.target.value);
//     if (!isNaN(val)) {
//       let k = [...code];
//       k[index] = val;
//       setCode(k);
//       if (index < 4)
//         document.querySelector(`input[name=code-${index + 1}]`)?.focus();
//     } else evt.target.value = "";
//   };

//   const clear = () => {
//     setCodeSent(false);
//     setCode(code.map((_) => null));
//     setEmail("");
//   };

//   return (
//     <>
//       <Helmet title={"Activate | Nimbey"} />
//       <form
//         onSubmit={codeSent ? activate : requestCode}
//         className={"flex flex-col card w-full shadow gap-4"}
//       >
//         <div className={"flex flex-col w-full"}>
//           {codeSent && (
//             <span
//               className={
//                 "text-4xl font-extrabold tracking-widest w-full text-left"
//               }
//             >
//               *****
//             </span>
//           )}
//           {!codeSent && (
//             <span className={"text-lg font-bold"}>Activate Account</span>
//           )}
//           {codeSent ? (
//             <p className={`text-gray-800 w-full text-sm`}>
//               Please enter the one time password sent to <br />
//               <button
//                 onClick={clear}
//                 type={"button"}
//                 className={"flex items-center gap-2 font-semibold"}
//               >
//                 {email}
//                 <span className={"material-symbols-rounded text-sm"}>edit</span>
//               </button>
//             </p>
//           ) : (
//             <p className={"text-gray-800 w-full text-xs"}>
//               Please enter the email address associated with your account, and
//               we'll email you a link to activate your account
//             </p>
//           )}
//         </div>
//         <div className={"flex flex-col w-full gap-3"}>
//           {codeSent && (
//             <div className={"flex items-center w-full gap-2  pb-2"}>
//               {code.map((c, index) => (
//                 <div
//                   key={`code-${index}`}
//                   className={"gap-3 w-10 overflow-hidden"}
//                 >
//                   <input
//                     name={`code-${index}`}
//                     autoComplete={"one-time-code"}
//                     type={"text"}
//                     onKeyDown={(evt) => handleKeys(evt, index)}
//                     value={c}
//                     onChange={(evt) => handleCode(evt, index)}
//                     disabled={loading}
//                     className={
//                       "p-2 text-center border-2 aspect-square flex items-center text-sm rounded-md outline-accLinks w-full"
//                     }
//                     maxLength={1}
//                   />
//                 </div>
//               ))}
//             </div>
//           )}
//           {!codeSent && (
//             <FormElement
//               name={"email"}
//               type={"email"}
//               inputClass={"text-sm"}
//               autoComplete={"email"}
//               value={email}
//               errors={errors}
//               setValue={setEmail}
//               required={true}
//               autoFocus={true}
//             />
//           )}

//           <button
//             type={"submit"}
//             disabled={loading}
//             className={
//               "w-full px-4 py-3 bg-accentBack text-gray-200 rounded-xl flex items-center justify-center"
//             }
//           >
//             <span
//               className={`text-md animate-pulse material-symbols-rounded ${!loading && "invisible"
//                 } absolute`}
//             >
//               more_horiz
//             </span>
//             <span className={`text-sm ${loading && "invisible"}`}>
//               {codeSent ? "Activate" : "Send Request"}
//             </span>
//           </button>
//           {codeSent && (
//             <div className={"w-full flex justify-center"}>
//               <button
//                 type={"button"}
//                 onClick={() => {
//                   clear();
//                   setEmail(email);
//                 }}
//                 className={"text-xs text-gray-600 hover:text-black font-medium"}
//               >
//                 Resend Code
//               </button>
//             </div>
//           )}
//           {errors.form && <p className="text-red-500 text-sm">{errors.form}</p>}
//           {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

//         </div>
//       </form>
//     </>
//   );
// }


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FormElement } from "../../components/Input";
import { activateEmailJs } from "../../api/auth";
import SendMail from "../../gmail_service/OtpVerification";

export default function Activate() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(Array(5).fill(""));
  const [codeSent, setCodeSent] = useState(false);
  const [generatedOtp, setGeneratedOtp] = useState("");

  // Generate OTP and store as string
  const generateOtp = () => (Math.floor(10000 + Math.random() * 90000)).toString();

  const requestCode = async (evt) => {
    evt.preventDefault();
    if (!email.trim()) return;

    setLoading(true);
    const otp = generateOtp();
    setGeneratedOtp(otp);
    
    try {
      await SendMail({ otp, email, setCodeSent });
      setCodeSent(true);
      setErrors({});
    } catch (error) {
      setErrors({ form: "Failed to send OTP. Try again." });
    }
    
    setLoading(false);
  };

  const activate = async (evt) => {
    console.log("Inside activate")
    evt.preventDefault();
    console.log(code.join(""))
    console.log(email)
    // if (!codeSent || !email.trim() || code.join("").length !== 5) return;

    if (code.join("") === generatedOtp) {
      setLoading(true);
      try {
        const [status] = await activateEmailJs({ email: email });
        if (status === 200) {
          navigate("/staging-app/activate-success");
        } else {
          setErrors({ form: "Something went wrong. Try again." });
        }
      } catch (error) {
        setErrors({ form: "Activation failed. Try again." });
      }
      setLoading(false);
    } else {
      setErrors({ email: "Invalid code. Please try again." });
    }
  };

  const handleKeys = (evt, index) => {
    if (evt.key === "Backspace" && index > 0) {
      let newCode = [...code];
      newCode[index] = "";
      setCode(newCode);
      document.querySelector(`input[name=code-${index - 1}]`)?.focus();
    }
  };

  const handleCode = (evt, index) => {
    const val = evt.target.value.trim();
    if (/^\d$/.test(val)) {
      let newCode = [...code];
      newCode[index] = val;
      setCode(newCode);
      if (index < 4) document.querySelector(`input[name=code-${index + 1}]`)?.focus();
    } else {
      evt.target.value = "";
    }
  };

  const clear = () => {
    setCodeSent(false);
    setCode(Array(5).fill(""));
    setEmail("");
    setGeneratedOtp("");
    setErrors({});
  };

  return (
    <>
      <Helmet title={"Activate | Nimbey"} />
      <form onSubmit={codeSent ? activate : requestCode} className="flex flex-col card w-full shadow gap-4">
        <div className="flex flex-col w-full">
          {codeSent ? (
            <>
              <p className="text-gray-800 w-full text-sm">Please enter the OTP sent to:</p>
              <button onClick={clear} type="button" className="flex items-center gap-2 font-semibold">
                {email} <span className="material-symbols-rounded text-sm">edit</span>
              </button>
            </>
          ) : (
            <p className="text-gray-800 w-full text-xs">Enter your email to receive an activation code.</p>
          )}
        </div>

        <div className="flex flex-col w-full gap-3">
          {codeSent ? (
            <div className="flex items-center w-full gap-2 pb-2">
              {code.map((c, index) => (
                <input
                  key={`code-${index}`}
                  name={`code-${index}`}
                  type="text"
                  onKeyDown={(evt) => handleKeys(evt, index)}
                  value={c}
                  onChange={(evt) => handleCode(evt, index)}
                  disabled={loading}
                  className="p-2 text-center border-2 aspect-square flex items-center text-sm rounded-md outline-accLinks w-10"
                  maxLength={1}
                />
              ))}
            </div>
          ) : (
            <FormElement
              name="email"
              type="email"
              inputClass="text-sm"
              autoComplete="email"
              value={email}
              errors={errors}
              setValue={setEmail}
              required
              autoFocus
            />
          )}

          <button
            type="submit"
            disabled={loading || (codeSent && code.join("").length !== 5)}
            className="w-full px-4 py-3 bg-accentBack text-gray-200 rounded-xl flex items-center justify-center"
          >
            <span className={`text-md animate-pulse material-symbols-rounded ${!loading ? "invisible" : ""} absolute`}>
              more_horiz
            </span>
            <span className={`text-sm ${loading ? "invisible" : ""}`}>
              {codeSent ? "Activate" : "Send Request"}
            </span>
          </button>

          {codeSent && (
            <div className="w-full flex justify-center">
              <button type="button" onClick={clear} className="text-xs text-gray-600 hover:text-black font-medium">
                Resend Code
              </button>
            </div>
          )}

          {errors.form && <p className="text-red-500 text-sm">{errors.form}</p>}
          {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
        </div>
      </form>
    </>
  );
}
