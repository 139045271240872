import React from "react";
import { motion } from "framer-motion"; // Import motion
import SuperchargeSection from "../components_landingpage/SuperchargeSection";
import HeroSection from "../components_landingpage/HeroSection";
import AutomatedDeductionManagement from "../components_landingpage/AutomatedDeductionManagement";
import DraftClaimsWithAI from "../components_landingpage/DraftClaimsWithAI";
import HowItWorks from "../components_landingpage/HowItWorks";
import IntegrationCard from "../components_landingpage/IntegrationCard";
import WhyChooseNimbey from "../components_landingpage/WhyChooseNimbey";

const Home = () => (
  <div className="w-full flex flex-col items-center"> {/* Parent Container */}
    {/* Wrap components with motion.div and centering wrapper */}
    <div
      className="w-full flex justify-center"
      id="hero"
    >
      <HeroSection />
    </div>

    <motion.div
      className="w-full flex justify-center"
      initial={{ opacity: 0, y: 50 }} // Change x to y for up-down animation
      whileInView={{ opacity: 1, y: 0 }} // Animate to original y position
      transition={{ duration: 0.8, ease: "easeOut" }}
      viewport={{ once: true }} // Ensures animation plays only once
    >
      <AutomatedDeductionManagement />
    </motion.div>

    <motion.div
      className="w-full flex justify-center"
      initial={{ opacity: 0, y: 50 }} // Start 50px below
      whileInView={{ opacity: 1, y: 0 }} // Move to original position
      transition={{ duration: 0.8, ease: "easeOut" }}
      viewport={{ once: true }} // Ensures animation plays only once
    >
      <DraftClaimsWithAI />
    </motion.div>

    <motion.div
      className="w-full flex justify-center"
      initial={{ opacity: 0, y: 50 }} // Start 50px below
      whileInView={{ opacity: 1, y: 0 }} // Move to original position
      transition={{ duration: 0.8, ease: "easeOut" }}
      viewport={{ once: true }} // Ensures animation plays only once
    >
      <HowItWorks />
    </motion.div>

    <motion.div
      className="w-full flex justify-center"
      initial={{ opacity: 0, y: 50 }} // Start 50px below
      whileInView={{ opacity: 1, y: 0 }} // Move to original position
      transition={{ duration: 0.8, ease: "easeOut" }}
      viewport={{ once: true }} // Ensures animation plays only once
    >
      <IntegrationCard />
    </motion.div>

    <motion.div
      className="w-full flex justify-center"
      initial={{ opacity: 0, y: 50 }} // Start 50px below
      whileInView={{ opacity: 1, y: 0 }} // Move to original position
      transition={{ duration: 0.8, ease: "easeOut" }}
      viewport={{ once: true }} // Ensures animation plays only once
    >
      <WhyChooseNimbey />
    </motion.div>

    <motion.div
      className="w-full flex justify-center overflow-hidden relative z-10"
      initial={{ opacity: 0, y: 50 }} // Start 50px below
      whileInView={{ opacity: 1, y: 0 }} // Move to original position
      transition={{ duration: 0.8, ease: "easeOut" }}
      viewport={{ once: true }} // Ensures animation plays only once
      style={{ marginBottom: "-1px" }} // Adjusted margin to remove bottom gap
    >
      <SuperchargeSection />
    </motion.div>
  </div>
);

export default Home;
