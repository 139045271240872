import React, { useEffect, useState } from "react";
import { Checkbox } from './ui/checkbox'; // Ensure this is correctly imported if needed
import { updateRaInvoice, updateInvoiceStatus } from '../api/dashboard';

// Mock Invoice Data
// const mockInvoiceData = {
//     invoice_date: "2025-02-01", // Example date
//     invoice_number: "INV-001",
//     "Invoice Amount": "$2343.00",
//     "Total Invoice Amount": "$2343.00",
//     "table_data": [
//         {
//             invoice_date: "2025-02-01",
//             invoice_number: "INV-001",
//             description: "Freight Charge",
//             gross_amount: 3200.00,
//             discount_amount: 0.00,
//             net_amount: 3200.00,
//             corrected_amount: 3000.00
//         },
//         {
//             invoice_date: "2025-02-01",
//             invoice_number: "INV-001",
//             description: "Freight Discount",
//             gross_amount: -2176.00,
//             discount_amount: -2176.00,
//             net_amount: -2176.00,
//             corrected_amount: 2000.00
//         },
//         {
//             invoice_date: "2025-02-01",
//             invoice_number: "INV-001",
//             description: "Residential Delivery",
//             gross_amount: 28.00,
//             discount_amount: 0.00,
//             net_amount: 28.00,
//             corrected_amount: 28.00
//         },
//         {
//             invoice_date: "2025-02-01",
//             invoice_number: "INV-001",
//             description: "Liftgate Charge",
//             gross_amount: 30.00,
//             discount_amount: 0.00,
//             net_amount: 30.00,
//             corrected_amount: 30.00
//         },
//     ],
//     summary: {
//         "Charge A": "Charge A was incorrectly billed due to a system error.",
//         "Charge C": "Charge C was charged twice in error and needs to be refunded."
//     },
//     billed_to_name: "John Doe",
//     total_amount: "$2343.00"
// };

const RATable = ({ invoice_number, data, setData = () => { } }) => {
    console.log(data);
    const [editableData, setEditableData] = useState([]);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        let updatedData = [];

        if (Array.isArray(data)) {
            updatedData = data;  // Use the existing array
        }
        else if (typeof data === "string") {
            try {
                updatedData = JSON.parse(data);
            } catch (error) {
                console.error("Error parsing table data:", error);
            }
        }
        else if (typeof data === 'object' && data !== null) {
            updatedData = Object.entries(data).map(([description, values]) => {
                const isDiscount = String(description).toLowerCase().includes("discount");

                return {
                    invoice_date: values?.invoice_date,
                    invoice_number: values?.invoice_number,
                    description,
                    gross_amount: isDiscount ? -values?.['Unit Price'] || 0 : values?.['Unit Price'] || 0,
                    discount_amount: isDiscount ? -values?.['Discount Amount'] || 0 : values?.['Discount Amount'] || 0,
                    net_amount: isDiscount ? -values?.['Net Amount'] || 0 : values?.['Net Amount'] || 0,
                    corrected_amount: isDiscount ? -values?.['Net Amount'] || 0 : values?.['Net Amount'] || 0,
                };
            });
        }
        setEditableData(updatedData);
    }, [data]); //  added data dependency

    useEffect(() => {
        setData(editableData)
    }, [editableData, setData]); // added editableData and setData


    const handleInputChange = (index, field, value) => {
        const updatedItems = [...editableData];
        updatedItems[index][field] = value;

        if (field === "gross_amount" || field === "discount_amount") {
            const grossAmount = parseFloat(updatedItems[index].gross_amount) || 0;
            const discountAmount = parseFloat(updatedItems[index].discount_amount) || 0;
            updatedItems[index].net_amount = (grossAmount + discountAmount).toFixed(2);
        }

        setEditableData(updatedItems);
        setIsChanged(true);
    };

    const handleSaveChanges = () => {
        const payload = { invoice_number, table_data: editableData };

        updateRaInvoice(payload)
            .then(() => {
                alert("Changes saved successfully!");
                setIsChanged(false);
            })
            .catch(error => {
                console.error("Error saving changes:", error);
                alert("An error occurred while saving changes: " + error.message);
            });
    };

    const handleStatusChange = async (newStatus) => {  // Removed Invoice Id from here
        try {
            const type = 'ra';
            const invoiceId = invoice_number;  // Defined here so it does not give error.
            const payload = { invoiceId, newStatus, type };
            updateInvoiceStatus(payload);  // nakshatra - this is the new status change function
            window.location.reload();
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleReject = async () => { // Removed Invoice Id from here
        await handleStatusChange('Rejected');  // Pass the argument directly.
    };

    const handleApprove = async () => { // Removed Invoice Id from here
        await handleStatusChange('Accepted'); // Pass the argument directly.
    };

    return (
        <div>
            <h2 className="text-lg font-semibold">Invoice Details</h2>

            <div className="p-6">
                <table className="min-w-full border-collapse table-auto rounded-lg">
                    <thead className="border-b border-gray-200">
                        <tr className="flex-nowrap">
                            <th className="py-3 px-4 text-left font-semibold text-gray-700">
                                Invoice Date
                            </th>
                            <th className="py-3 px-4 text-left font-semibold text-gray-700">
                                Invoice Number
                            </th>
                            <th className="py-3 px-4 text-left font-semibold text-gray-700">
                                Description
                            </th>
                            <th className="py-3 px-4 text-left font-semibold text-gray-700">
                                Gross Amount
                            </th>
                            <th className="py-3 px-4 text-left font-semibold text-gray-700">
                                Discount Amount
                            </th>
                            <th className="py-3 px-4 text-left font-semibold text-gray-700">
                                Net Amount
                            </th>
                            <th className="py-3 px-4 text-left font-semibold text-gray-700">
                                Corrected Amount
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(editableData) &&
                            editableData.map((item, index) => (
                                <tr key={index} className="border-b border-gray-200">
                                    <td className="py-3 px-4">{item.invoice_date}</td>
                                    <td className="py-3 px-4">{item.invoice_number}</td>
                                    <td className="py-3 px-4">{item.description}</td>
                                    <td className="py-3">
                                        <input
                                            type="number"
                                            value={item.gross_amount}
                                            onChange={(e) =>
                                                handleInputChange(index, "gross_amount", e.target.value)
                                            }
                                            className="w-full bg-white rounded focus:outline-none focus:ring-1 focus:ring-lime-200 py-2 px-3"
                                        />
                                    </td>
                                    <td className="py-3">
                                        <input
                                            type="number"
                                            value={item.discount_amount}
                                            onChange={(e) =>
                                                handleInputChange(index, "discount_amount", e.target.value)
                                            }
                                            className="w-full bg-white rounded focus:outline-none focus:ring-1 focus:ring-lime-200 py-2 px-3"
                                        />
                                    </td>
                                    <td className="py-3 px-4">{item.net_amount}</td>
                                    <td className="py-3 px-4 text-[#00A76F]">{item.corrected_amount}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            
            <div className="mt-6 flex justify-end gap-4">
                <button
                    onClick={handleReject}
                    className="border border-red-500 text-red-500 px-6 py-2 rounded-lg text-sm hover:bg-red-600 hover:text-white"
                >
                    Reject
                </button>
                <button
                    onClick={handleApprove}
                    className="bg-lime-300 text-black px-6 py-2 rounded-lg text-sm hover:bg-lime-200"
                >
                    Approve
                </button>

                {/* Conditionally render Save Changes button */}
                {isChanged && (
                    <button
                        onClick={handleSaveChanges}
                        className="text-gray-500 px-6 py-2 rounded-lg text-sm hover:bg-gray-900"
                    >
                        Save Changes
                    </button>
                )}
            </div>
        </div>
    );
};

export default RATable
