import request, { products, admin, orders, utils, api } from "./init";

function dashboard() {
  return request(`${admin}/invoice-analysis/dashboard`, null, "GET");
}

function searchProducts(q, paramsOverride = null) {
  return request(
    `${products}/search?${paramsOverride ? paramsOverride : `query=${q}`}`,
    null,
    "GET"
  );
}

function productInfo(product_id) {
  return request(`${products}/product/${product_id}`, null, "GET");
}

function previousOrders(forAdmin) {
  return request(`${forAdmin ? admin : orders}/previous-orders`, null, "GET");
}

function getLocations() {
  return request(`${utils}/locations`, null, "GET");
}

function getCategories() {
  return request(`${utils}/categories`, null, "GET");
}

function placeOrder(payload) {
  return request(`${orders}/place-order`, payload);
}

function newItemOrder(payload) {
  return request(`${orders}/new-item`, payload);
}

function orderDetails(order_id) {
  return request(`${orders}/order-details?order_id=${order_id}`, null, "GET");
}

function getOrderRequest(cart_id) {
  return request(`${orders}/approve?cart_id=${cart_id}`, null, "GET");
}

function approveOrder(payload) {
  return request(`${orders}/approve`, payload, "POST");
}

function adminStats(filters) {
  console.log(filters);
  return request(`${admin}/stats`, { filters }, "POST"); 
}

function adminRAStats(filters) {
  console.log(filters);
  return request(`${admin}/ra-stats`, { filters }, "POST"); 
}

function adminContracts() {
  return request(`${admin}/contracts`, null, "GET");
}

function groupContracts(payload) {
  return request(`${admin}/contracts/group`, payload);
}

function disableContracts(payload) {
  return request(`${admin}/contracts/disable`, payload, "PATCH");
}

function deleteContracts(payload) {
  return request(`${admin}/contracts/delete`, payload, "PATCH");
}

function contractStatus(payload) {
  return request(`${admin}/contracts/status`, payload);
}

function contractUpload(payload, config) {
  return request(`${admin}/contracts/upload`, payload, "POST", config);
}

function contractDetails(contract_id) {
  return request(
    `${admin}/contracts/details?contract_id=${contract_id}`,
    null,
    "GET"
  );
}

function nimbotChat(init = false, payload = null) {
  return request(`${api}/nimbey/chat`, payload, init ? "GET" : "POST");
}

function getCart() {
  return request(`${api}/orders/cart`, null, "GET");
}

function updateCart(payload) {
  return request(`${api}/orders/cart`, payload, "PATCH");
}

function submitCart(payload) {
  return request(`${api}/orders/cart`, payload, "POST");
}

function adminInvoices() {
  return request(`${admin}/invoice-analysis/`, null, "GET"); //invoice
}

function updateInvoice(updated_data) {
  return request(`${admin}/invoice-analysis/update-table`, updated_data, "PATCH"); 
}

function updateRaInvoice(updated_data) {
  return request(`${admin}/invoice-analysis/update-ra-table`, updated_data, "PATCH"); // update invoice table data
}

function updateInvoiceStatus(payload) {
  console.log("payload : ", payload);
  return request(`${admin}/invoice-analysis/update-invoice-status`, payload, "PATCH");
}

function Invoicesdownload(invoice_id) {
  return fetch(`${admin}/invoice-analysis/download?invoice_id=${invoice_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
    credentials: "include", // This is important for session cookies
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log(response);
      return response.json();
    })
    .then((data) => [200, data]);
}

async function getInvoiceDocs(invoice_id){
  try {
    const response = await fetch(`${admin}/invoice-analysis/list-invoice-documents?invoice_id=${invoice_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });
    const data = await response.json();

    if (data.success) {
      console.log(data);
      return data.data.document_names;
    } else {
      console.error("Failed to retrieve documents:", data.message);
      return [];
    }
  } catch (error) {
    console.error("Error fetching documents:", error);
    return [];
  }
}

async function getRaDocs(invoice_id){
  try {
    const response = await fetch(`${admin}/invoice-analysis/list-ra-documents?invoice_id=${invoice_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });
    const data = await response.json();

    if (data.success) {
      console.log(data);
      return data.data.document_names;
    } else {
      console.error("Failed to retrieve documents:", data.message);
      return [];
    }
  } catch (error) {
    console.error("Error fetching documents:", error);
    return [];
  }
}

async function downloadRaDoc(s3_key) {
  try {
    const response = await fetch(`${admin}/invoice-analysis/download-ra-document?s3_key=${s3_key}`,
      {
        // Updated endpoint to match backend
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });
    const data = await response.json();

    if (data.success) {
      return data.data.download_url;
    } else {
      console.error("Failed to retrieve download URL:", data.message);
      return null;
    }
  } catch (error) {
    console.error("Error fetching download URL:", error);
    return null;
  }
}

function RaInvoicesdownload(invoice_id) {
  return fetch(
    `${admin}/invoice-analysis/ra-download?invoice_id=${invoice_id}`,
    {
      // Updated endpoint to match backend
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    }
  )
    .then(async (response) => {
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || `HTTP error! status: ${response.status}`
        );
      }
      return response.json();
    })
    .then((data) => {
      if (!data.success) {
        throw new Error(data.message || "Failed to download document");
      }
      return [200, data];
    })
    .catch((error) => {
      console.error("Download API error:", error);
      throw error;
    });
}

function adminRAInvoices() {
  return request(`${admin}/invoice-analysis/ra-analysis`, null, "GET"); //invoice
}

function quickbooks(payload) {
  return request(
    `${admin}/quickbooks/upload`,
    payload,
    "POST"
  ).then((response) => {
    console.log("Raw response:", response); // Debug log
    return {
      success: response[0] === 200,
      status: response[0],
      message: response[1]?.message,
      quickbooks_result: response[1],
    };
  });
}

function invoiceStatus(payload) {
  return request(`${admin}/invoice-analysis/status`, payload, "POST"); //status
}

function uploadAnalysis(payload) {
  return request(`${admin}/invoice-analysis`, payload, "POST")
    .then((response) => {
      // Log success message with relevant data
      // console.log("Invoice upload response:", response);
      return response; // Return the response for further handling
    })
    .catch((error) => {
      // Log error message if the upload fails
      console.error("Error uploading invoice:", error);
      throw error; // Re-throw the error for further handling
    });
}

function analysisDetails(invoice_id) {
  return request(
    `${admin}/invoice-analysis/details?invoice_id=${invoice_id}`,
    null,
    "GET"
  );
}

function deleteAnalysis(key) {
  return request(
    `${admin}/invoice-analysis/delete?invoice_id=${key}`,
    null,
    "GET"
  );
}

function deleteRaAnalysis(key) {
  return request(
    `${admin}/invoice-analysis/ra-delete?invoice_id=${key}`,
    null,
    "GET"
  );
}

function getInvoicesFromEmail(payload){
  return request(
    `${api}/fetch-and-upload-pdfs`,
    null,
    "GET"
  )
}

function quickbooks_sync() {
  return fetch(`${admin}/quickbooks/refresh-token`, {
    method: "GET",
    credentials: "include", // Ensure cookies or tokens are sent
  })
    .then((response) =>
      response.json().then((data) => ({
        status: response.status,
        data: data,
      }))
    )
    .catch((error) => {
      console.error("Error during QuickBooks sync:", error);
      throw error;
    });
}

function downloadPDF(s3_key) {
  return fetch(`${admin}/invoice-analysis/download-pdf?s3_key=${s3_key}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
    credentials: "include", // This is important for session cookies
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log(response);
      return response.json();
    })
    .then((data) => [200, data]);
}

// function updateInvoice(updated_data) {
//   return request(`${admin}/invoice-analysis/update-table`, updated_data, "PATCH");
// }

export {
  dashboard,
  updateInvoice,
  updateRaInvoice,
  updateInvoiceStatus,
  downloadPDF,
  searchProducts,
  productInfo,
  previousOrders,
  getLocations,
  getCategories,
  placeOrder,
  newItemOrder,
  orderDetails,
  quickbooks,
  getOrderRequest,
  approveOrder,
  adminStats,
  adminRAStats,
  adminContracts,
  groupContracts,
  disableContracts,
  deleteContracts,
  contractStatus,
  contractUpload,
  contractDetails,
  nimbotChat,
  getCart,
  updateCart,
  submitCart,
  adminInvoices,
  invoiceStatus,
  uploadAnalysis,
  analysisDetails,
  deleteAnalysis,
  adminRAInvoices,
  deleteRaAnalysis,
  Invoicesdownload,
  getInvoiceDocs,
  getRaDocs,
  downloadRaDoc,
  RaInvoicesdownload,
  getInvoicesFromEmail,
  quickbooks_sync
};