import React, { useState, useEffect } from 'react';
import { dashboardData } from '../data/RAdashboardData';
import MetricCard from '../new/MetricCard';
import InvoiceAgeBreakdown from '../new/InvoiceAgeBreakdown';
import GenericBarChart from '../new/HorizontalBarGraph';
import { adminStats } from '../../../api/dashboard';

const RAWidgets = () => {
  // Uncomment this befor deployment
  const [dashboard_data, setdashboard_data] = useState('');
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await adminStats();
        if (response[0] !== 200) throw new Error('Failed to fetch dashboard data');

        // uncomment this this saves the actual data
        // const data = response[1];
        // Comment this before deployment - uses mockdata
        const data = dashboardData;
        console.log("data : ", data);

        setdashboard_data(data);
        // Store data in local storage
        localStorage.setItem('dashboardData', JSON.stringify(data));

      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setdashboard_data({}); // Fallback to an empty object if the API fails
      }
    };

    fetchDashboardData();

  }, []);


  return (
    <div className="w-full h-auto rounded-lg hidden pb-20 overflow-hidden md:grid grid-cols-1 md:grid-cols-4 auto-rows-fr gap-6 ">

      <MetricCard
        title="Open Disputes"
        // value={dashboard_data.open}
        value={dashboardData.metrics.open.value}
        trend={dashboardData.metrics.open.trend}
        data={dashboardData.metrics.open.data}
      />
      <MetricCard
        title="Active Disputes"
        prefix="$"
        value={dashboardData.metrics.active.value}
        trend={dashboardData.metrics.active.trend}
        data={dashboardData.metrics.active.data}
      />
      <MetricCard
        title="Disputes Won"
        // value={dashboard_data.won}
        value={dashboardData.metrics.won.value}
        trend={dashboardData.metrics.won.trend}
        data={dashboardData.metrics.won.data}
      />
      <MetricCard
        title="Disputes Lost"
        suffix="%"
        value={dashboardData.metrics.lost.value}
        trend={dashboardData.metrics.lost.trend}
        data={dashboardData.metrics.lost.data}
      />
    </div>

  );
};

export default RAWidgets;