function Comparison({curr, prev, text, className = ""}) {
    const percent = ((curr - prev) * 100 / (prev || 1)).toFixed(1);

    if (prev >= 0)
    return (
        <span className={"text-xs text-gray-600 whitespace-nowrap " + className} title={prev}>
            <span className={`${percent > 0 ? "text-green-400" : "text-red-400"} font-semibold`}>
                {prev === 0 ? 100 : percent}%
            </span> {text}</span>
    )
}

export default Comparison;