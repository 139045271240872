import React from "react";
import dashboardImage from "../assets_landingpage/FreightHighestQuality.webp";
import "./hero.css";

const Hero = ({
  mainHeading = "Nimbey AI.",
  subHeading = "Freigt audit automation",
  highlightColor = "xyz",
  subText = "made nimble and easy.",
  buttonText = "Book Demo",
  imageSrc = dashboardImage,
}) => {
  return (
    <section id="home" className="relative w-full mb-10 flex flex-col items-center text-center py-10 sm:py-16 bg-gradient-to-br from-[#081f21] via-[#0b2a2d] to-[#2d494a] rounded-b-3xl overflow-hidden">
      
      {/* Bottom white space */}
      <div className="absolute inset-x-0 -bottom-1 h-0 lg:h-[400px] bg-white rounded-t-3xl"></div>

      {/* Main Heading - Supports Line Breaks in Both Mobile and Desktop Views */}
      <h1 className="font-semibold text-3xl sm:text-3xl md:text-5xl text-[#1B1B1B] leading-tight mt-28 mb-2 md:mb-4">
        <span
          className={`block ${
            highlightColor
              ? "h-14 bg-gradient-to-r from-[#96d69f] to-[#96d69f] font-extrabold bg-clip-text text-transparent drop-shadow-md"
              : ""
          }`}
        >
          {mainHeading}
        </span>
      </h1>

      <h2 className="font-semibold text-3xl sm:text-3xl md:text-5xl text-gray-50 leading-tight mb-8 md:mb-6">
        {subHeading}
      </h2>

      {/* Subtext - Default or Custom Text */}
      <p className="text-md sm:text-xl md:text-2xl text-gray-200 mb-8 md:mb-6">
        {subText}
      </p>

      {/* Call to Action Button - Default or Custom Text */}
      <a target="_blank" rel="noreferrer" href="https://cal.com/agrima/nimbey" className="custombutton">
        <span className="icon-container">
          <span className="icon">›</span>
          <span className="icon">›</span>
          <span className="icon">›</span>
          <span className="icon">›</span>
          <span className="icon">›</span>
          <span className="icon">›</span>
        </span>
        <span className="text">{buttonText}</span>
      </a>

      {/* Dashboard Image - Default or Custom Image */}
      <div className="flex justify-center">
        <div className="relative mt-[25px] overflow-hidden transition-transform transform hover:scale-105">
          <img
            src={imageSrc}
            loading="lazy"
            alt="Dashboard Preview"
            className="transition-opacity duration-200 w-[80%] mx-auto"
          />
          <div className="absolute inset-0 ripple-effect" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
