// import React, { useEffect, useState } from "react";
// import RATable from "./TableForRASidebar";

// function RaAnalysisSideBar({ invoiceData, setDescribeAnalysis }) {
//   console.log("RaAnalysisSideBar invoiceData: ", invoiceData)
//   const [activeTab, setActiveTab] = useState("analysis");
//   const [parsedSummary, setParsedSummary] = useState(null);

//   function extractRecoveryAmount(summary) {
//     try {
//       const equationPattern = /=\s*\$?([\d,]+\.?\d*)/;
//       const equationMatch = summary.match(equationPattern);
//       if (equationMatch) {
//         return parseFloat(equationMatch[1].replace(/,/g, ""));
//       }
//       return null;
//     } catch (error) {
//       console.error("Error extracting amount:", error);
//       return null;
//     }
//   }

//   const val = extractRecoveryAmount(invoiceData?.summary);
//   console.log(val);

//   useEffect(() => {
//     if (invoiceData?.summary) {
//       try {
//         // Log the incoming summary to debug
//         console.log("Raw summary:", invoiceData.summary);

//         let parsedData;
//         if (typeof invoiceData.summary === "string") {
//           // Try to parse if it's a string
//           parsedData = JSON.parse(invoiceData.summary);
//         } else {
//           // If it's already an object, use it directly
//           parsedData = invoiceData.summary;
//         }

//         // Log the parsed data to debug
//         console.log("Parsed summary:", parsedData);

//         setParsedSummary({
//           summary: parsedData,
//         });
//       } catch (e) {
//         console.error("Error parsing summary:", e);
//         // If parsing fails, try to use the summary as is
//         setParsedSummary({
//           summary: invoiceData.summary,
//         });
//       }
//     }
//   }, [invoiceData?.summary]);

//   const tabData = {
//     analysis: {
//       label: "Analysis Details",
//       content: [
//         { label: "Invoice Number", value: invoiceData.invoice_number || "N/A" },
//         { label: "PO Number", value: invoiceData.PO_Number || "N/A" },
//         { label: "Priority", value: "Medium" },
//         { label: "Remittance Advice Status", value: "Incorrect" },
//         {
//           label: "Incorrect Amount",
//           value: val ? (
//             <span className="font-bold text-blue-600">${val}</span>
//           ) : (
//             "N/A"
//           ),
//         },
//         { label: "Uploaded on", value: invoiceData.created_at || "N/A" },
//       ],
//     },
//     other: {
//       label: "Other Information",
//       content: [
//         { label: "Invoice ID", value: invoiceData.invoice_id || "N/A" },
//         { label: "Status", value: invoiceData.status || "N/A" },
//       ],
//     },
//   };

//   const SummaryDisplay = ({ summary }) => {
//     console.log("Summary Display Input:", summary); // Debug log

//     if (!summary?.summary) {
//       console.log("No summary data available"); // Debug log
//       return null;
//     }

//     let summaryData = summary.summary;

//     // Check if summaryData is a string and attempt to parse only if it's valid JSON
//     try {
//       summaryData = typeof summaryData === "string" ? JSON.parse(summaryData) : summaryData;
//     } catch (error) {
//       console.warn("Summary is not valid JSON, treating it as plain text.");
//       summaryData = null; // Reset to null if parsing fails
//     }

//     return (
//       <div className="mt-3 border-t pt-3">
//         <h3 className="text-md font-semibold text-gray-800 mb-3">Detailed Analysis</h3>
//         <div className="rounded-lg bg-gray-50 p-4 space-y-4 text-gray-700">
//           {/* If parsing fails, render plain text */}
//           {!summaryData ? (
//             <p className="whitespace-pre-wrap">{summary.summary}</p>
//           ) : Array.isArray(summaryData) ? (
//             summaryData.map((item, index) => (
//               <p key={index} className="text-sm leading-relaxed">
//                 {typeof item === "string"
//                   ? item
//                   : Object.values(item).join(" ")}
//               </p>
//             ))
//           ) : (
//             <p className="text-sm leading-relaxed">
//               {Object.values(summaryData).join(" ")}
//             </p>
//           )}
//         </div>
//       </div>
//     );
//   };


//   // const SummaryDisplay = ({ summary }) => {
//   //   console.log("Summary Display Input:", summary); // Debug log

//   //   if (!summary?.summary) {
//   //     console.log("No summary data available"); // Debug log
//   //     return null;
//   //   }

//   //   let summaryData = summary.summary;
//   //   try {
//   //     summaryData = typeof summaryData === "string" ? JSON.parse(summaryData) : summaryData;
//   //     console.log("summaryData in try block:", summaryData);
//   //   } catch (error) {
//   //     console.warn("Summary is not valid JSON, treating it as plain text.");
//   //     summaryData = null; // Reset to null if parsing fails
//   //   }
//   //   if (Array.isArray(summaryData)) {
//   //     return (
//   //       <div className="mt-3 border-t pt-3">
//   //         <h3 className="text-md font-semibold text-gray-800 mb-3">
//   //           Analysis Summary:
//   //         </h3>
//   //         <div className="rounded-lg bg-gray-50 p-4 space-y-4">
//   //           {summaryData.map((item, index) => (
//   //             <div key={index}>
//   //               {Object.entries(item).map(([key, value]) => (
//   //                 <div key={key} className="flex justify-between text-sm mb-2 last:mb-0">
//   //                   <span className="text-gray-600 font-medium">
//   //                     {key}:&nbsp;&nbsp;
//   //                   </span>
//   //                   <span className="text-gray-800">{value}</span>
//   //                 </div>
//   //               ))}
//   //               {/* Separator between items */}
//   //               {index !== summaryData.length - 1 && (
//   //                 <hr className="mt-2 border-gray-300" />
//   //               )}
//   //             </div>
//   //           ))}
//   //         </div>
//   //       </div>
//   //     );
//   //   }

//   //   // If the data is structured and not an array
//   //   return (
//   //     <div className="mt-3 border-t pt-3">
//   //       <h3 className="text-md font-semibold text-gray-800 mb-3">
//   //         Analysis Summary:
//   //       </h3>
//   //       <div className="rounded-lg bg-gray-50 p-4">
//   //         hiii
//   //       </div>
//   //     </div>
//   //   );
//   // };

//   const InfoCard = ({ title, children }) => (
//     <div className=" rounded-lg p-2 w-full">
//       <h3 className="text-sm font-semibold text-gray-900 mb-2 border-b border-gray-200  ">
//         {title}
//       </h3>
//       {children}
//     </div>
//   );

//   const InfoRow = ({ label, value }) => (
//     <div className="flex justify-between items-center py-1">
//       <span className="font-sm text-gray-800 font-bold">{label}</span>
//       <span className="text-gray-600">{value}</span>
//     </div>
//   );

//   return (
//     <div className="flex items-stretch fixed h-full lg:max-w-screen-sm w-full shadow-xl bg-white right-0 bottom-0 z-50 flex-1">
//       {/* Borders */}
//       <div className="flex flex-col w-1">
//         {["#005B8C", "#00A7C9", "#A58EFD", "#005162"].map((color) => (
//           <div
//             key={color}
//             className="h-full w-1"
//             style={{ backgroundColor: color }}
//           />
//         ))}
//       </div>

//       {/* Content */}
//       <div className="flex flex-col w-full p-6 flex-1 overflow-y-auto">
//         {/* Header */}
//         <div className="flex items-center justify-between mb-1  top-0 bg-white py-1">
//           <div className="overflow-hidden text-ellipsis flex-1 pr-4">
//             <h2 className="text-lg font-semibold text-gray-900">
//               Invoice Number: {invoiceData.invoice_number}
//             </h2>
//           </div>
//           <button
//             onClick={() => setDescribeAnalysis(null)}
//             className="hover:bg-gray-100 p-2 rounded-full"
//           >
//             <span className="material-symbols-rounded">close</span>
//           </button>
//         </div>

//         {/* Tabs */}
//         <div className="flex border-b mb-6">
//           {Object.entries(tabData).map(([key, data]) => (
//             <button
//               key={key}
//               onClick={() => setActiveTab(key)}
//               className={`px-8 py-3 font-medium text-sm transition-colors
//                 ${activeTab === key
//                   ? "text-blue-600 border-b-2 border-blue-600"
//                   : "text-gray-500 hover:text-gray-700"
//                 }`}
//             >
//               {data.label}
//             </button>
//           ))}
//         </div>

//         {/* Tab Content */}
//         <div className="space-y-1">
//           {activeTab === "analysis" && (
//             <>
//               <div className=" rounded-lg p-3 pt-0 ">
//                 {tabData.analysis.content.map((item, index) => (
//                   <div
//                     key={index}
//                     className="flex justify-between items-center py-1  last:border-0"
//                   >
//                     <span className="font-medium text-gray-700">
//                       {item.label}
//                     </span>
//                     <span className="text-gray-600">{item.value}</span>
//                   </div>
//                 ))}
//               </div>

//               {parsedSummary && <SummaryDisplay summary={parsedSummary} />}

//               {/* Table Component */}
//               <RATable />


//               <button className="w-44 h-12 bg-black flex items-center justify-center rounded-lg border border-transparent text-white text-lg font-extralight shadow-md transition-all duration-200 ease-in-out mb-4 ml-auto 
//   hover:bg-green-600 hover:shadow-lg 
//   focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 active:scale-95"
//               >
//                 Generate AI Email
//               </button>
//             </>
//           )}

//           {activeTab === "other" && (
//             <div className="space-y-6 ">
//               <div className="grid grid-cols-2 gap-6">
//                 <InfoCard title="Invoice Information">
//                   <InfoRow
//                     label="Invoice Number"
//                     value={invoiceData.invoice_number || "N/A"}
//                   />
//                   <InfoRow
//                     label="PO Number"
//                     value={invoiceData.PO_Number || "N/A"}
//                   />
//                   <InfoRow
//                     label="Total Amount"
//                     value={invoiceData.total_amount || "N/A"}
//                   />
//                   <InfoRow
//                     label="Date Created"
//                     value={invoiceData.created_at || "N/A"}
//                   />
//                 </InfoCard>

//                 <InfoCard title="PO Information">
//                   <InfoRow
//                     label="PO Number"
//                     value={invoiceData.PO_Number || "N/A"}
//                   />
//                   <InfoRow
//                     label="Date Created"
//                     value={invoiceData.created_at || "N/A"}
//                   />
//                 </InfoCard>
//               </div>

//               <InfoCard title="Remittance Advice">
//                 <InfoRow
//                   label="Document Id"
//                   value={invoiceData.invoice_number || "N/A"}
//                 />
//                 <InfoRow
//                   label="Date Created"
//                   value={invoiceData.created_at || "N/A"}
//                 />
//                 <InfoRow
//                   label="Total Amount paid"
//                   value={invoiceData.total_amount || "N/A"}
//                 />
//                 <InfoRow label="Sender Name" value="N/A" />
//               </InfoCard>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default RaAnalysisSideBar;

import React, { useEffect, useState } from "react";
import RATable from "./TableForRASidebar";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { downloadRaDoc, getRaDocs } from "../api/dashboard";

function RaAnalysisSideBar({ invoiceData, setDescribeAnalysis }) {
  console.log("RaAnalysisSideBar invoiceData: ", invoiceData)
  const [activeTab, setActiveTab] = useState("analysis");
  const [parsedSummary, setParsedSummary] = useState(null);
  const [documents, setDocuments] = useState([]);
  const table = invoiceData.table_data;

  function extractRecoveryAmount(summary) {
    try {
      const equationPattern = /=\s*\$?([\d,]+\.?\d*)/;
      const equationMatch = summary.match(equationPattern);
      if (equationMatch) {
        return parseFloat(equationMatch[1].replace(/,/g, ""));
      }
      return null;
    } catch (error) {
      console.error("Error extracting amount:", error);
      return null;
    }
  }

  const val = extractRecoveryAmount(invoiceData?.summary);
  console.log(val);

  useEffect(() => {
    if (invoiceData?.summary) {
      try {
        // Log the incoming summary to debug
        console.log("Raw summary:", invoiceData.summary);

        let parsedData;
        if (typeof invoiceData.summary === "string") {
          // Try to parse if it's a string
          parsedData = JSON.parse(invoiceData.summary);
        } else {
          // If it's already an object, use it directly
          parsedData = invoiceData.summary;
        }

        // Log the parsed data to debug
        console.log("Parsed summary:", parsedData);

        setParsedSummary({
          summary: parsedData,
        });
      } catch (e) {
        console.error("Error parsing summary:", e);
        // If parsing fails, try to use the summary as is
        setParsedSummary({
          summary: invoiceData.summary,
        });
      }
    }
  }, [invoiceData?.summary]);

  // gets all the ra uploaded docs and downloads each doc
  useEffect(() => {
    const fetchDocuments = async () => {
      if (invoiceData?.invoice_number) {
        const docs = await getRaDocs(invoiceData.invoice_number);
        setDocuments(docs);
      }
    };

    fetchDocuments();
  }, [invoiceData?.invoice_number]);

  const expectedDocTypes = ["RA", "Invoice", "Purchase Order", "Distribution Agreement", "POD"];

  const presentTypes = documents.map((document) => document.type);

  const missingTypes = expectedDocTypes.filter(type => !presentTypes.includes(type));

  const handleDownload = async (s3_key) => {
    const downloadUrl = await downloadRaDoc(s3_key);
    if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    } else {
      console.log("Failed to get download URL.");
    }
  };

  const tabData = {
    analysis: {
      label: "Analysis Details",
      content: [
        { label: "Invoice Number", value: invoiceData.invoice_number || "N/A" },
        { label: "PO Number", value: invoiceData.PO_Number || "N/A" },
        { label: "Priority", value: "Medium" },
        { label: "Remittance Advice Status", value: "Incorrect" },
        {
          label: "Incorrect Amount",
          value: val ? (
            <span className="font-bold text-blue-600">${val}</span>
          ) : (
            "N/A"
          ),
        },
        { label: "Uploaded on", value: invoiceData.created_at || "N/A" },
      ],
    },
    other: {
      label: "Other Information",
      content: [
        { label: "Invoice ID", value: invoiceData.invoice_id || "N/A" },
        { label: "Status", value: invoiceData.status || "N/A" },
      ],
    },
    documents: {
      label: "Documents",
    }
  };

  const SummaryDisplay = ({ summary }) => {
    console.log("Summary Display Input:", summary); // Debug log

    if (!summary?.summary) {
      console.log("No summary data available"); // Debug log
      return null;
    }

    let summaryData = summary.summary;

    // Check if summaryData is a string and attempt to parse only if it's valid JSON
    try {
      summaryData = typeof summaryData === "string" ? JSON.parse(summaryData) : summaryData;
    } catch (error) {
      console.warn("Summary is not valid JSON, treating it as plain text.");
      summaryData = null; // Reset to null if parsing fails
    }

    return (
      <div className="mt-3 border-t pt-3">
        <h3 className="text-md font-semibold text-gray-800 mb-3">Detailed Analysis</h3>
        <div className="rounded-lg bg-gray-50 p-4 space-y-4 text-gray-700">
          {/* If parsing fails, render plain text */}
          {!summaryData ? (
            <p className="whitespace-pre-wrap">{summary.summary}</p>
          ) : Array.isArray(summaryData) ? (
            summaryData.map((item, index) => (
              <p key={index} className="text-sm leading-relaxed">
                {typeof item === "string"
                  ? item
                  : Object.values(item).join(" ")}
              </p>
            ))
          ) : (
            <p className="text-sm leading-relaxed">
              {Object.values(summaryData).join(" ")}
            </p>
          )}
        </div>
      </div>
    );
  };


  // const SummaryDisplay = ({ summary }) => {
  //   console.log("Summary Display Input:", summary); // Debug log

  //   if (!summary?.summary) {
  //     console.log("No summary data available"); // Debug log
  //     return null;
  //   }

  //   let summaryData = summary.summary;
  //   try {
  //     summaryData = typeof summaryData === "string" ? JSON.parse(summaryData) : summaryData;
  //     console.log("summaryData in try block:", summaryData);
  //   } catch (error) {
  //     console.warn("Summary is not valid JSON, treating it as plain text.");
  //     summaryData = null; // Reset to null if parsing fails
  //   }
  //   if (Array.isArray(summaryData)) {
  //     return (
  //       <div className="mt-3 border-t pt-3">
  //         <h3 className="text-md font-semibold text-gray-800 mb-3">
  //           Analysis Summary:
  //         </h3>
  //         <div className="rounded-lg bg-gray-50 p-4 space-y-4">
  //           {summaryData.map((item, index) => (
  //             <div key={index}>
  //               {Object.entries(item).map(([key, value]) => (
  //                 <div key={key} className="flex justify-between text-sm mb-2 last:mb-0">
  //                   <span className="text-gray-600 font-medium">
  //                     {key}:&nbsp;&nbsp;
  //                   </span>
  //                   <span className="text-gray-800">{value}</span>
  //                 </div>
  //               ))}
  //               {/* Separator between items */}
  //               {index !== summaryData.length - 1 && (
  //                 <hr className="mt-2 border-gray-300" />
  //               )}
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //     );
  //   }

  //   // If the data is structured and not an array
  //   return (
  //     <div className="mt-3 border-t pt-3">
  //       <h3 className="text-md font-semibold text-gray-800 mb-3">
  //         Analysis Summary:
  //       </h3>
  //       <div className="rounded-lg bg-gray-50 p-4">
  //         hiii
  //       </div>
  //     </div>
  //   );
  // };

  const InfoCard = ({ title, children }) => (
    <div className=" rounded-lg p-2 w-full">
      <h3 className="text-sm font-semibold text-gray-900 mb-2 border-b border-gray-200  ">
        {title}
      </h3>
      {children}
    </div>
  );

  const InfoRow = ({ label, value }) => (
    <div className="flex justify-between items-center py-1">
      <span className="font-sm text-gray-800 font-bold">{label}</span>
      <span className="text-gray-600">{value}</span>
    </div>
  );

  return (
    <div className="flex items-stretch fixed h-full lg:max-w-screen-md w-full shadow-xl bg-white right-0 bottom-0 z-50 flex-1">
      {/* Borders */}
      <div className="flex flex-col w-1">
        {["#005B8C", "#00A7C9", "#A58EFD", "#005162"].map((color) => (
          <div
            key={color}
            className="h-full w-1"
            style={{ backgroundColor: color }}
          />
        ))}
      </div>

      {/* Content */}
      <div className="flex flex-col w-full p-6 flex-1 overflow-y-auto">
        {/* Header */}
        <div className="flex items-center justify-between mb-1  top-0 bg-white py-1">
          <div className="overflow-hidden text-ellipsis flex-1 pr-4">
            <h2 className="text-lg font-semibold text-gray-900">
              Invoice Number: {invoiceData.invoice_number}
            </h2>
          </div>
          <button
            onClick={() => setDescribeAnalysis(null)}
            className="hover:bg-gray-100 p-2 rounded-full"
          >
            <span className="material-symbols-rounded">close</span>
          </button>
        </div>

        {/* Tabs */}
        <div className="flex border-b mb-6">
          {Object.entries(tabData).map(([key, data]) => (
            <button
              key={key}
              onClick={() => setActiveTab(key)}
              className={`px-8 py-3 font-medium text-sm transition-colors
                ${activeTab === key
                  ? "text-blue-600 border-b-2 border-blue-600"
                  : "text-gray-500 hover:text-gray-700"
                }`}
            >
              {data.label}
            </button>
          ))}
        </div>

        {/* Tab Content */}
        <div className="space-y-1">
          {activeTab === "analysis" && (
            <>
              <div className=" rounded-lg p-3 pt-0 ">
                {tabData.analysis.content.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center py-1  last:border-0"
                  >
                    <span className="font-medium text-gray-700">
                      {item.label}
                    </span>
                    <span className="text-gray-600">{item.value}</span>
                  </div>
                ))}
              </div>

              {parsedSummary && <SummaryDisplay summary={parsedSummary} />}

              {/* Table Component */}
              {/* Commenting it out for now............. */}
              <RATable invoice_number={invoiceData.invoice_number} data={table.data} />


              <button className="bg-black hover:bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2 mt-4">
                Generate AI Email
                <IoIosArrowDroprightCircle className='h-6 w-6' />
              </button>
            </>
          )}

          {activeTab === "other" && (
            <div className="space-y-6 ">
              <div className="grid grid-cols-2 gap-6">
                <InfoCard title="Invoice Information">
                  <InfoRow
                    label="Invoice Number"
                    value={invoiceData.invoice_number || "N/A"}
                  />
                  <InfoRow
                    label="PO Number"
                    value={invoiceData.PO_Number || "N/A"}
                  />
                  <InfoRow
                    label="Total Amount"
                    value={invoiceData.total_amount || "N/A"}
                  />
                  <InfoRow
                    label="Date Created"
                    value={invoiceData.created_at || "N/A"}
                  />
                </InfoCard>

                <InfoCard title="PO Information">
                  <InfoRow
                    label="PO Number"
                    value={invoiceData.PO_Number || "N/A"}
                  />
                  <InfoRow
                    label="Date Created"
                    value={invoiceData.created_at || "N/A"}
                  />
                </InfoCard>
              </div>

              <InfoCard title="Remittance Advice">
                <InfoRow
                  label="Document Id"
                  value={invoiceData.invoice_number || "N/A"}
                />
                <InfoRow
                  label="Date Created"
                  value={invoiceData.created_at || "N/A"}
                />
                <InfoRow
                  label="Total Amount paid"
                  value={invoiceData.total_amount || "N/A"}
                />
                <InfoRow label="Sender Name" value="N/A" />
              </InfoCard>
            </div>
          )}

          {activeTab === "documents" && (
            <div>
              <ul className="divide-y divide-gray-200">
                {documents.map((document, index) => {
                  const filename = document.name.split("/").pop();
                  const parts = filename.split("-");
                  const timestamp = parts[0];
                  const type = parts[1];
                  const nameAndExtension = parts.slice(2).join("-");
                  const name = nameAndExtension.replace(".pdf", "");
                  const extension = ".pdf";

                  return (
                    <li
                      key={index}
                      className="flex items-center justify-between px-4 py-3 hover:bg-gray-50 transition-colors"
                    >
                      <div className="flex items-center">
                        <span className="text-blue-600 material-symbols-rounded mr-3">description</span>
                        <div>
                          <span className="font-medium text-gray-900">{name}</span>
                          <span className="text-gray-500">{extension}</span>
                        </div>
                      </div>
                      <button
                        onClick={() => handleDownload(document.s3_key)}
                        className="flex items-center text-sm text-blue-600 hover:text-blue-800 font-medium"
                      >
                        <span className="material-symbols-rounded mr-1">download</span>
                      </button>
                    </li>
                  );
                })}
              </ul>

              {/* Missing Document Types Alert */}
              {missingTypes.length > 0 && (
                <div className="mt-4 p-4 border-l-4 border-amber-500 bg-amber-50 rounded-md">
                  <div className="flex">
                    <span className="flex-shrink-0 text-amber-500 material-symbols-rounded">warning</span>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-amber-800">Missing Required Documents</h3>
                      <div className="mt-1 text-sm text-amber-700">
                        <ul className="list-disc pl-5 space-y-1">
                          {missingTypes.map((type) => (
                            <li key={type}>{type}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RaAnalysisSideBar;