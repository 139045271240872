import React from "react";
import { motion } from "framer-motion";
import combinedImage from "../assets_landingpage/combined_integration_logos.svg"; // Replace with the actual path to your combined image

const Integrations = ({
  title = "Integrations",
  description = "Integrate your TMS, ERP, portals, email, financial tools with Nimbey to manage your supply chain data from one platform.",
  imageSrc = combinedImage, // Default image path
  titleColor = "text-black",
  descriptionColor = "text-gray-600",
  imageWidth = "w-3/4",
}) => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.7,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.section
      id="integrations"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
      variants={containerVariants}
      className="bg-white w-[87.50%] mb-[40px] text-center mx-auto pt-40"
    >
      {/* Title Section */}
      <motion.h2
        variants={itemVariants}
        className={`font-poppins font-semibold mb-4 ${titleColor} text-[26px] md:text-[36px]`}
      >
        {title}
      </motion.h2>

      {/* Description Section */}
      <motion.p
        variants={itemVariants}
        className={`font-public-sans font-medium ${descriptionColor} mb-12 mx-auto text-[16px] md:text-[20px] w-[408px] md:w-[592px]`}
      >
        {description}
      </motion.p>

      {/* Image Section for All Integrations */}
      <motion.div
        className="flex justify-center items-center"
        variants={imageVariants}
      >
        <img
          src={imageSrc}
          alt="Integrations"
          className={`${imageWidth} h-auto`} // Dynamic width from props
        />
      </motion.div>
    </motion.section>
  );
};

export default Integrations;
