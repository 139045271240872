import {Bar} from "react-chartjs-2";
import React, {useState} from "react";
import {colors, getTimePeriod, handleTip, humanizePrice, reduceStats, unitizeAmount} from "./utils";

function CategoryLevelOne({data, term, filters, setFilters, postFilters}) {
    const [alt, setAlt] = useState(false);

    const activeTab = term === 0 ? "Year" : term === 1 ? "Quarter" : "Month"
    const [reduced, index] = reduceStats(data, "c1");

    const labels = Object.keys(reduced).filter(x => index[0] in reduced[x]);
    const datasets = [];
    const altDatasets = [];

    const supCount = Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value.reduce((prev, curr) => {
        prev[curr.c1] = prev[curr.c1] || new Set();
        prev[curr.c1].add(curr.supplier);
        return prev;
    }, {})]));

    if (!alt)
        labels.sort((a, b) => reduced[b][index[0]] - reduced[a][index[0]]);
    else
        labels.sort((a, b) => (supCount[index[0]][b]?.size || 0) - (supCount[index[0]][a]?.size || 0));

    index.slice(0, 2).forEach((index, _in) => {
        datasets.push({
            label: `In ${index}`,
            barThickness: "flex",
            minBarLength: 2,
            backgroundColor: colors[_in],
            data: labels.map(x => reduced[x][index]),
            stack: `stack-${index}`
        });
        altDatasets.push({
            label: `In ${index}`,
            barThickness: "flex",
            minBarLength: 2,
            backgroundColor: colors[_in],
            data: labels.map(x => supCount[index][x]?.size || 0),
            stack: `stack-${index}`
        });
    })

    return <div id={"cat-1"} className={"flex flex-col card aspect-[0.8] lg:aspect-square flex-1 lg:max-h-96"}>
        <div className={"flex flex-col item-start w-full whitespace-nowrap"}>
            <div className={"flex items-center justify-between relative"}>
                <span
                    className={"font-bold text-gray-800 text-md flex items-center gap-2"}>Categories - Level 1</span>
                <div className={"flex items-center gap-4"}>
                    <button onClick={() => setAlt(!alt)}
                            className={"text-xs px-2 py-1 rounded-md bg-gray-100 shadow hover:bg-gray-200 active:shadow-none"}>vs {alt ? "Amount" : "Supplier Count"}</button>
                    <div onMouseEnter={(e) => handleTip(e, "cat-1")}
                         onMouseLeave={(e) => handleTip(e, "cat-1")}
                         className={"show-tip"}>
                        <span className={"material-symbols-rounded text-md"}>info</span>
                        <div className={"tool-tip-cover"}>
                                <span className={"p-2 block px-4 whitespace-normal text-sm w-[20rem]"}>
                                    {`The bar graph shows spending across top level 2 categories in ${activeTab} (${getTimePeriod(term)})`}
                                </span>
                        </div>
                    </div>
                </div>
            </div>
            <span className={"text-xs text-gray-600"}>Latest {activeTab}</span>
        </div>
        <div className={"w-full flex-1 overflow-hidden"}>
            <Bar id={"cat-graph"} width={"100%"} className={"p-2"}
                 data={{
                     labels: labels,
                     datasets: alt ? altDatasets : datasets
                 }}
                 options={{
                     responsive: true,
                     maintainAspectRatio: false,
                     aspectRatio: 1.5,
                     indexAxis: "y",
                     onClick(event, elements, chart) {
                         if (elements.length && elements[0].element) {
                             if (filters?.c1 === labels[elements[0].index]) {
                                 let d = {...filters};
                                 delete d.c1;
                                 delete d.c2;
                                 delete d.c3;
                                 setFilters(d);
                             } else {
                                 let d = {...filters};
                                 delete d['c2'];
                                 delete d['c3'];
                                 d['c1'] = labels[elements[0].index];
                                 setFilters(d);
                             }
                         }
                     },
                     plugins: {
                         tooltip: {
                             callbacks: {
                                 label(tooltipItem) {
                                     if (alt)
                                         return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} unique supplier${tooltipItem.formattedValue === '1' ? '' : 's'} | Amount: $${humanizePrice(datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex])}`
                                     return `${tooltipItem.dataset.label}: $${tooltipItem.formattedValue} | Supplier Count: ${supCount[index[tooltipItem.datasetIndex]][tooltipItem.label].size}`;
                                 }
                             }
                         }
                     },
                     scales: {
                         x: {
                             min: postFilters.amount,
                             ticks: {
                                 stepSize: alt ? 1 : undefined,
                                 callback: (tickValue, index, ticks) => alt ? tickValue : unitizeAmount(tickValue)
                             }
                         },
                         y: {
                             ticks: {autoSkip: false},
                             beginAtZero: true,
                             stacked: true
                         }
                     }
                 }}
            />
        </div>
    </div>
}

export default CategoryLevelOne;