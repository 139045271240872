import {Doughnut} from "react-chartjs-2";
import React, {useState} from "react";
import {colors, getTimePeriod, handleTip, reduceStats} from "./utils";

function TopSpendByDepartments({data, term, filters, setFilters, postFilters}) {
    const activeTab = term === 0 ? "Year" : term === 1 ? "Quarter" : "Month"
    const [isCurrent, setIsCurrent] = useState(true);
    const [reduced, index] = reduceStats(data, "department");

    const labels = Object.keys(reduced).filter(x => index[0] in reduced[x]);
    const datasets = [];
    labels.sort((a, b) => reduced[b][index[0]] - reduced[a][index[0]])

    index.slice(isCurrent ? 0 : 1, isCurrent ? 1 : 2).forEach((index, _in) => {
        datasets.push({
            label: `In ${index}`,
            backgroundColor: colors,
            data: labels.map(x => reduced[x][index]),
        })
    })


    return <div id={"spend-dept-graph"}
                className={"flex flex-col card aspect-[0.8] lg:aspect-square flex-1 lg:max-h-96"}>
        <div className={"flex flex-col item-start justify-between w-full whitespace-nowrap"}>

            <div className={"flex items-center w-full justify-between gap-2 relative"}>
                <span className={"font-bold text-gray-800 text-md"}>Spend By Department</span>

                <div className={"flex items-center gap-4"}>
                    <div className={"flex items-center text-md border rounded-lg overflow-hidden"}>
                        <button onClick={() => setIsCurrent(!isCurrent)}
                                className={"material-symbols-rounded rounded-l-lg hover:bg-gray-100 p-1 text-md disabled:opacity-50"}
                                disabled={!isCurrent}>chevron_left
                        </button>
                        <button onClick={() => setIsCurrent(!isCurrent)}
                                className={"material-symbols-rounded rounded-r-lg hover:bg-gray-100 p-1 text-md disabled:opacity-50"}
                                disabled={isCurrent}>chevron_right
                        </button>
                    </div>
                    <div onMouseEnter={(e) => handleTip(e, "spend-dept-graph")}
                         onMouseLeave={(e) => handleTip(e, "spend-dept-graph")}
                         className={"show-tip"}>
                        <span className={"material-symbols-rounded text-md"}>info</span>
                        <div className={"tool-tip-cover"}>
                    <span
                        className={"p-2 block px-4 whitespace-normal text-sm w-[20rem]"}>{`The chart shows spending across departments in ${activeTab} (${getTimePeriod(term)})`}</span>
                        </div>
                    </div>
                </div>
            </div>
            <span className={"text-xs text-gray-600"}>{isCurrent ? 'Latest' : 'Previous'} {activeTab}</span>
        </div>
        <div className={"w-full flex-1 overflow-hidden"}>
            <Doughnut id={"topDepartments"} width={"100%"} className={"p-2"}
                data={{
                    labels: labels,
                    datasets: datasets,
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    aspectRatio: 1.5,
                    onClick(event, elements, chart) {
                         if (elements.length && elements[0].element) {
                             if (filters?.department === labels[elements[0].index]) {
                                 let d = {...filters};
                                 delete d.department;
                                 setFilters(d);
                             } else {
                                 setFilters({...filters, department: labels[elements[0].index]});
                             }
                         }
                     },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label(tooltipItem) {
                                    return `${tooltipItem.dataset.label}: $${tooltipItem.formattedValue}`
                                }
                            }
                        }
                    }
                }}/>
        </div>
    </div>
}


export default TopSpendByDepartments;