import {humanizePrice} from "../views/dashboard/admin/graphs/utils";
import React, {useState} from "react";

function DashboardFilters({
                              Activator,
                              filters,
                              setFilters,
                              max,
                              setMinAmount,
                              minAmount,
                              setPostFilters,
                              postFilters,
                              term,
                              setTerm,
                              className
                          }) {
    const [openFilter, setOpenFilter] = useState(false);

    const filterDescriptions = {
        contract: (v) => `From Contract: ${v.toString()}`,
        new_request: (v) => `New Order: ${v.toString()}`
    };


    return <>
        <Activator onClick={() => setOpenFilter(!openFilter)}>
            {
                openFilter &&
                <div className={className}
                     hidden={true}>
                    {/*   min amount slider   */}
                    {
                        !!max && <div className={"flex items-center gap-2"}>
                            <span className={"text-sm font-semibold"}>Amount</span>
                            <span className={"text-xs font-semibold text-gray-600"}>${humanizePrice(minAmount)}</span>
                            <input className={"custom-slider"} type={"range"} min={0} max={max} defaultValue={minAmount}
                                   onChange={(evt) => {
                                       setPostFilters({...postFilters, amount: evt.target.value});
                                       setMinAmount(Number(evt.target.value));
                                   }}
                            />
                            <span className={"text-xs font-semibold text-gray-600"}>${humanizePrice(max)}</span>
                        </div>
                    }

                    {/*  term selector  */}
                    <div className={"flex items-center rounded-lg overflow-hidden bg-white shadow-sm w-full"}>
                        {
                            ["Year", "Quarter", "Month"].map((x, i) =>
                                <button
                                    key={`${x}-${i}`}
                                    onClick={() => setTerm(i)}
                                    className={`${i === 0 ? 'rounded-l-lg' : i === 2 ? 'rounded-r-lg' : ''} px-4 py-2 text-xs flex-1 
                                text-slate-950 ${term === i ? "font-bold bg-[#c1ff72] border-[#c1ff72]" : "font-medium"}`}>
                                    {x}
                                </button>
                            )
                        }
                    </div>

                    {
                        !!Object.keys(filters || {}).length &&
                        <div className={"flex flex-col gap-4 w-full"}>
                            <hr/>
                            <ul className={"flex items-center gap-2 flex-wrap"}>
                                {
                                    filters && Object.entries(filters).map(([k, v]) =>
                                        <li key={k}>
                                            <button
                                                className={"p-1 px-2 rounded-md bg-gray-200 hover:bg-gray-50 transition-colors duration-100 ease-in card flex items-center gap-1"}
                                                onClick={() => {
                                                    let d = {...filters};
                                                    delete d[k];
                                                    setFilters(d);
                                                }}>
                                    <span
                                        className={"text-xs whitespace-nowrap"}>{filterDescriptions.hasOwnProperty(k) ? filterDescriptions[k](v) : v.toString()}</span>
                                                <span className={"material-symbols-rounded text-md"}>close</span>
                                            </button>
                                        </li>)
                                }
                            </ul>
                            <button onClick={() => setFilters({})}
                                    className={"w-full px-3 py-2 bg-gray-200 text-xs rounded-md text-black"}>Clear
                                all
                            </button>
                        </div>
                    }

                </div>
            }
        </Activator>
    </>
}

export default DashboardFilters;