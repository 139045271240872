import React, {useEffect, useState} from "react";
import ContractSideBar from "../../../components/ContractSideBar";
import ContractUploadModal from "../../../components/ContractUploadModal";
import ContractRecord from "../../../components/ContractRecord";
import Notify from "../../../components/Notification";
import {
    adminContracts,
    contractStatus,
    deleteContracts,
    disableContracts
} from "../../../api/dashboard";
import {useOutletContext} from "react-router-dom";

const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes >= 1048576) {
        return `${(sizeInBytes / 1048576).toFixed(2)} MB`;
    }
    if (sizeInBytes >= 1024) {
        return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    }
    return `${sizeInBytes} B`;
}

function AdminContracts() {
    const {chat} = useOutletContext();
    const [contracts, setContracts] = useState([]);
    const [statusUpdates, setStatusUpdates] = useState({});
    const [loader, setLoader] = useState(false);
    const [describeContract, setDescribeContract] = useState(null);

    const getContracts = () => {
        setLoader(true);
        adminContracts()
            .then(([status, data]) => {
                if (status === 200) {
                    setContracts(data);
                    setStatusUpdates({});
                } else {
                    Notify("Couldn't fetch contracts", "err")
                }
            })
            .finally(() => setLoader(false));
    }

    const handleDisableContract = (id, isActive) => {
        disableContracts({contract_id: id.toString()}).then(([status, _]) => {
            if (status === 200) {
                getContracts();
                Notify(`Contract ${isActive ? 'disabled' : 'activated'}`, "success");
            } else {
                Notify("Couldn't disable contract", "failed");
            }
        })
    }

    const handleDeleteContract = (id) => {
        deleteContracts({contract_id: id.toString()}).then(([status, _]) => {
            if (status === 200) {
                getContracts();
                Notify(`Contract deleted`, "success");
            } else Notify("Couldn't delete contract", "failed");
        })
    }

    useEffect(getContracts, []);

    useEffect(() => {
        if (contracts && contracts.length) {
            const interval = setInterval(() => {
                contractStatus({ids: contracts.map((contract) => contract.id)})
                    .then(([status, data]) => {
                        if (status === 200) setStatusUpdates(data);

                    })
            }, 10000);
            return () => {
                clearInterval(interval);
            }
        }
    }, [contracts])

    if (loader)
        return <div className={"flex w-full items-center justify-center flex-1"}>
            <span className={"w-full text-center block"}>
                <span className={"material-symbols-rounded animate-spin"}>progress_activity</span>
            </span>
        </div>

    return (
        <div className={"flex flex-col items-start w-full gap-4 rounded-md relative flex-1 px-2"}>
            <div className={"py-2"}>
                <span className={"flex text-xl gap-1 w-full"}><b>Contract</b> Upload</span>
            </div>
            <div className={"card flex flex-col w-full h-full"}>
                <div className={"w-full"}>
                    <ContractUploadModal getContracts={getContracts}/>
                </div>
                <div className={"w-full flex flex-col"}>
                    <div key={"header-0"}
                         className={`rounded-md mt-4 w-full hidden ${chat ? "xl:flex" : "lg:flex"} items-center justify-between bg-gray-100 border mb-4 text-xs text-gray-600 font-semibold`}>
                        <div className={"flex items-center flex-[4] p-4"}>
                            <span className={"flex-[2]"}>Name</span>
                            <span className={"flex-1"}>File format & Size</span>
                            <span className={"flex-1"}>Uploaded on</span>
                        </div>
                        <div className={"flex-[2] flex items-center p-4"}>
                            <span className={"flex-1 text-center"}>Status</span>
                            <span className={"flex-1"}></span>
                        </div>
                    </div>
                    <div className={`flex flex-col w-full mb-4 h-full py-4 ${chat ? "xl:py-0" : "lg:py-0"} gap-4`}>
                        {
                            !!contracts.length ?
                                contracts.sort((a, b) => a.created_at > b.created_at ? -1 : 1).map(contract =>
                                    <ContractRecord contract={contract}
                                                    statusUpdates={statusUpdates}
                                                    handleDeleteContract={handleDeleteContract}
                                                    handleDisableContract={handleDisableContract}
                                                    setDescribeContract={setDescribeContract}/>
                                )
                                :
                                <div className={"w-full flex items-center justify-center p-4 text-sm text-gray-600"}>
                                    <span>No contracts available</span>
                                </div>
                        }
                    </div>
                </div>
                {
                    describeContract &&
                    <ContractSideBar contract={describeContract}
                                     setDescribeContract={setDescribeContract}/>
                }
            </div>
        </div>
    )
}

export default AdminContracts;
export {formatFileSize};