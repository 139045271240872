import React, { useState, useEffect } from 'react';
import { dashboardData } from '../data/dashboardData';
import MetricCard from '../new/MetricCard';
import TopVendors from '../new/TopVendors';
import SpendByCategory from '../new/SpendByCategory';
import InvoiceAgeBreakdown from '../new/InvoiceAgeBreakdown';
import InvoiceStatus from '../new/InvoiceStatus';
import PaymentMethod from '../new/PaymentMethod';
import InvoiceByCustomer from '../new/InvoiceByCustomer';
import SpendByYear from '../new/SpendByYear';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
import { dashboard, adminStats } from '../../../api/dashboard';
import { Link } from 'react-router-dom';
import GenericBarChart from '../new/HorizontalBarGraph';
import { FaSpinner } from 'react-icons/fa';
import { RiLoader4Fill } from 'react-icons/ri';

const Dashboard = () => {
  const [filters, setFilters] = useState({});
  const [spendFilter, setSpendFilter] = useState('monthly'); // State for SpendByYear filter
  const [dashboard_data, setdashboard_data] = useState('');
  const [invoiceage, setInvoiceage] = useState({"30 Days": 0, "30-60 Days": 0, "90+ Days": 0});
  const [topVendor, setTopVendor] = useState({ currentYear: [], previousYear: [] });
  const [spendByYear, setSpendByYear] = useState({
    currentYear: Array.from({ length: 12 }, (_, index) => ({
      month: new Date(0, index).toLocaleString('default', { month: 'short' }),
      amount: 0,
    })),
    previousYear: Array.from({ length: 12 }, (_, index) => ({
      month: new Date(0, index).toLocaleString('default', { month: 'short' }),
      amount: 0,
    })),
    value: "0.00M",
  });
  const [spendByCategory, setSpendByCategory] = useState({ currentYear: [], previousYear: [] });
  const [paymentMethods, setPaymentMethods] = useState({ 'Debit/Credit Card': 0, 'ACH': 0, 'Wire': 0, 'Other': 0 });
  const [userName, setUserName] = useState('');
  const [greeting, setGreeting] = useState('');

  const [loading, setLoading] = useState(true);

  const fetchDashboardData = async (filters) => {
    setLoading(true);
    try {
      const formattedFilters = JSON.parse(JSON.stringify(filters));
      console.log("Sending Payload with formatted filters:", formattedFilters);
      const response = await adminStats(formattedFilters);
      if (response[0] !== 200) throw new Error('Failed to fetch dashboard data');
  
      const data = response[1];
      console.log("Fetched Data: ", data);
  
      setUserName(data.userName.split(' ')[0]);
      setdashboard_data({ ...data }); // Ensure a new object reference
      setInvoiceage({ ...data.invoiceAgeBreakdown });
      setTopVendor({ 
        currentYear: [...(data.topVendors.currentYear || [])], 
        previousYear: [...(data.topVendors.previousYear || [])] 
      });
      setSpendByYear({
        ...data.spendByYear,
        currentYear: [...data.spendByYear.currentYear],
        previousYear: [...data.spendByYear.previousYear]
      });
      setSpendByCategory({ 
        ...data.spendByCategory, 
        currentYear: [...data.spendByCategory.currentYear], 
        previousYear: [...data.spendByCategory.previousYear] 
      });
      setPaymentMethods({ ...data.paymentMethods });
  
      localStorage.setItem('dashboardData', JSON.stringify(data));
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
    setLoading(false);
  };  


  useEffect(() => {

    fetchDashboardData(filters); // Call fetchDashboardData on initial load

    // Set greeting based on time of day
    const determineGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        setGreeting('Good Morning');
      } else if (currentHour < 18) {
        setGreeting('Good Afternoon');
      } else {
        setGreeting('Good Evening');
      }
    };

    determineGreeting();
  }, [filters]); // useEffect dependency array:  Only run when filters change


  const handleSpendFilterChange = (filter) => {
    setSpendFilter(filter);
  };

  const getFilteredSpendData = () => {
    const filter_data = spendByYear;
    if (spendFilter === 'quarterly') {
      return {
        currentYear: filter_data.currentYear.filter((_, index) => (index + 1) % 3 === 0),
        previousYear: filter_data.previousYear.filter((_, index) => (index + 1) % 3 === 0)
      };
    } else if (spendFilter === 'monthly') {
      return filter_data;
    } else { // yearly
      return {
        currentYear: [{ month: 'Total', amount: filter_data.currentYear.reduce((sum, item) => sum + item.amount, 0) }],
        previousYear: [{ month: 'Total', amount: filter_data.previousYear.reduce((sum, item) => sum + item.amount, 0) }]
      };
    }
  };


  const handleFilter = (filter) => {
    console.log("Received filter:", filter);
  
    // If filter is an array, extract the first object
    if (Array.isArray(filter) && filter.length > 0) {
      filter = filter[0];  
    }
  
    // Ensure the filter is a valid non-empty object
    if (!filter || typeof filter !== "object" || Object.keys(filter).length === 0) {
      console.log("Invalid or empty filter:", filter);
      return;
    }
  
    const filterKey = Object.keys(filter)[0]; // Extract key
    const filterValue = filter[filterKey]; // Extract value
  
    setFilters((prevFilters) => {
      if (filterValue === "" || filterValue === null) {
        // Remove the filter if the value is empty/null
        const updatedFilters = { ...prevFilters };
        delete updatedFilters[filterKey];
        return updatedFilters;
      }
      return { ...prevFilters, [filterKey]: filterValue };
    });
  };  
  console.log(filters);
  
  const handleRemoveFilter = (filterKey) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[filterKey];
      return updatedFilters;
    });
  };  


  const clearAllFilters = () => {
    setFilters({});
  };


  const filteredSpendData = getFilteredSpendData(); // Calculate this here, so it can be used in render
  
  if(loading){
    return (
      <div className="flex justify-center items-center h-screen text-lg font-semibold">
        <RiLoader4Fill className={"text-2xl animate-spin text-gray-700"}/>
      </div>
    );
  }
  return (
    <div className="w-full p-6">
      <div className="mb-8 text-left flex flex-col md:flex-row items-start md:items-center justify-between space-y-4">
        <div>
          <h1 className="text-2xl font-semibold text-gray-800">{greeting}, {userName}</h1>
          <p className="text-gray-600 mt-1">Here's what's happening with your invoices.</p>
          <Link to="/staging-app/dashboard/admin/invoice-analysis" className="mt-4">
            <button className="bg-black hover:bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2 mt-4">
              Review Invoices
              <IoIosArrowDroprightCircle className='h-6 w-6' />
            </button>
          </Link>
        </div>

        <div className="flex flex-wrap gap-4">
          {/* Spend Filter */}
          <select
            className='w-20 md:w-fit px-1 py-2 bg-gray-100 border rounded-lg text-gray-500 font-semibold'
            onChange={(e) => handleSpendFilterChange(e.target.value)}
            value={spendFilter}
          >
            <option value="">Select Spend Filter</option>
            <option value="yearly">Yearly</option>
            <option value="quarterly">Quarterly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
      </div>

      {/* Filter Display */}
      <div className='w-full mb-5'>
      {Object.keys(filters).length > 0 && (
        <ul className="flex items-center space-x-3 flex-wrap">
          {Object.entries(filters).map(([key, value]) => (
            <li 
              key={key} 
              className="text-sm text-blue-600 px-2 py-1 rounded-full border border-blue-600 bg-blue-100 flex items-center gap-2"
            >
              <span className="font-semibold">{key.replace('_', ' ')}:</span> {value}
              <button 
                onClick={() => handleRemoveFilter(key)} 
                className="text-red-500 hover:text-red-700"
              >
                ✖
              </button>
            </li>
          ))}
        </ul>
      )}
      </div>

      {/* Row 1 */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6">
        <MetricCard
          title="Outstanding Invoices"
          // value={dashboard_data.outstandingInvoices}
          value={dashboardData.metrics.outstandingInvoices.value}
          trend={dashboardData.metrics.outstandingInvoices.trend}
          data={dashboardData.metrics.outstandingInvoices.data}
        />
        <MetricCard
          title="Amount Due"
          // value={dashboard_data.totalAmountDue}
          value={dashboardData.metrics.amountDue.value}
          trend={dashboardData.metrics.amountDue.trend}
          prefix={dashboardData.metrics.amountDue.prefix}
          data={dashboardData.metrics.amountDue.data}
        />
        <MetricCard
          title="Total Spend"
          // value={dashboard_data.totalSpend}
          value={dashboardData.metrics.totalSpend.value}
          trend={dashboardData.metrics.totalSpend.trend}
          prefix={dashboardData.metrics.totalSpend.prefix}
          data={dashboardData.metrics.totalSpend.data}
        />
        <InvoiceStatus data={dashboardData.invoiceStatus} />
      </div>

      {/* Row 2 */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <GenericBarChart data={topVendor} title="Top Vendors By Spend" xAxisLabel="" yAxisLabel="" onFilterChange={handleFilter} />
        <SpendByYear data={getFilteredSpendData()} timeFilter={dashboardData.spendByYear} spendFilter={spendFilter} />
        <GenericBarChart data={dashboard_data.spendByCategory} title="Spend by Category" xAxisLabel="" yAxisLabel="" onFilterChange={handleFilter} />
      </div>

      {/* Row 3 */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <PaymentMethod data={dashboardData.paymentMethods} />
        <GenericBarChart data={dashboard_data.outstandingInvoicesByCustomer} title="Outstanding Invoice by Customers" xAxisLabel="" yAxisLabel="" onFilterChange={handleFilter} />
        <InvoiceAgeBreakdown data={invoiceage} />
      </div>
    </div>
  );
};

export default Dashboard;




// import React, { useState, useEffect } from 'react';
// import { dashboardData } from '../data/dashboardData'; // Mock data
// import MetricCard from '../new/MetricCard';
// import TopVendors from '../new/TopVendors';
// import SpendByCategory from '../new/SpendByCategory';
// import InvoiceAgeBreakdown from '../new/InvoiceAgeBreakdown';
// import InvoiceStatus from '../new/InvoiceStatus';
// import PaymentMethod from '../new/PaymentMethod';
// import InvoiceByCustomer from '../new/InvoiceByCustomer';
// import SpendByYear from '../new/SpendByYear';
// import { IoIosArrowDroprightCircle } from 'react-icons/io';
// import { adminStats } from '../../../api/dashboard';

// const Dashboard = () => {
//   const [spendFilter, setSpendFilter] = useState('yearly');
//   const [dashboardDataFetched, setDashboardDataFetched] = useState(null); // State for fetched dashboard data
//   const [invoiceage, setInvoiceage] = useState({"30 Days": 0, "30-60 Days": 0, "90+ Days": 0});
//   const [topVendor, setTopVendor] = useState({ currentYear: [], previousYear: [] });
//   const [spendByYear, setSpendByYear] = useState({ currentYear: Array.from({ length: 12 }, (_, index) => ({ month: new Date(0, index).toLocaleString('default', { month: 'short' }), amount: 0 })), previousYear: Array.from({ length: 12 }, (_, index) => ({ month: new Date(0, index).toLocaleString('default', { month: 'short' }), amount: 0 }), value: "0.00M" });
//   const [spendByCategory, setSpendByCategory] = useState({ currentYear: [{ category: 'Carrier', amount: 500 }], previousYear: [{ category: 'Carrier', amount: 0 }] });
//   const [paymentMethods, setPaymentMethods] = useState({ 'Debit/Credit Card': 0, 'ACH': 0, 'Wire': 0, 'Other': 0 });
//   const [userName, setUserName] = useState('');
//   const [greeting, setGreeting] = useState('');

//   useEffect(() => {
//     const fetchDashboardData = async () => {
//       try {
//         const response = await adminStats();
//         if (response[0] !== 200) throw new Error('Failed to fetch dashboard data');

//         const data = response[1]; // Use actual fetched data
//         setUserName(data.userName.split(' ')[0]);
        
//         // Update state with fetched data
//         setDashboardDataFetched(data);
//         setInvoiceage(data.invoiceAgeBreakdown);
//         setTopVendor({ currentYear: data.topVendors.currentYear || [], previousYear: data.topVendors.previousYear || [] });
//         setSpendByYear({ ...spendByYear, ...data.spendByYear });
//         setSpendByCategory({ ...spendByCategory, ...data.spendByCategory });
//         setPaymentMethods({ ...paymentMethods, ...data.paymentMethods });

//         // Store data in local storage
//         localStorage.setItem('dashboardData', JSON.stringify(data));

//       } catch (error) {
//         console.error('Error fetching dashboard data:', error);
//         // If API call fails or there's an error, fallback to mock data
//         setDashboardDataFetched(dashboardData); // Use mock data as fallback
//       }
//     };

//     fetchDashboardData();

//     // Set greeting based on time of day
//     const determineGreeting = () => {
//       const currentHour = new Date().getHours();
//       if (currentHour < 12) {
//         setGreeting('Good Morning');
//       } else if (currentHour < 18) {
//         setGreeting('Good Afternoon');
//       } else {
//         setGreeting('Good Evening');
//       }
//     };

//     determineGreeting();
//   }, []);

//   const handleSpendFilterChange = (filter) => {
//     setSpendFilter(filter);
//   };

//   const getFilteredSpendData = () => {
//     const filter_data = spendByYear;
//     if (spendFilter === 'quarterly') {
//       return {
//         currentYear: filter_data.currentYear.filter((_, index) => (index + 1) % 3 === 0),
//         previousYear: filter_data.previousYear.filter((_, index) => (index + 1) % 3 === 0)
//       };
//     } else if (spendFilter === 'monthly') {
//       return filter_data;
//     } else { // yearly
//       return {
//         currentYear: [{ month: 'Total', amount: filter_data.currentYear.reduce((sum, item) => sum + item.amount, 0) }],
//         previousYear: [{ month: 'Total', amount: filter_data.previousYear.reduce((sum, item) => sum + item.amount, 0) }]
//       };
//     }
//   };

//   return (
//     <div className="p-6">
//       <div className="mb-8 text-left flex flex-col md:flex-row items-start md:items-center justify-between space-y-4">
//         <div>
//           <h1 className="text-2xl font-semibold text-gray-800">{greeting}, {userName}</h1>
//           <p className="text-gray-600 mt-1">Here's what's happening with your invoices.</p>
//           <button className="mt-4 bg-black hover:bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2">
//             Review Invoices
//             <IoIosArrowDroprightCircle className='h-6 w-6' />
//           </button>
//         </div>

//         <div className="flex flex-wrap gap-4">
//           {/* Spend Filter */}
//           <select
//             className='w-20 md:w-fit px-4 py-2 bg-gray-100 border rounded-lg text-gray-500 font-semibold'
//             onChange={(e) => handleSpendFilterChange(e.target.value)}
//             value={spendFilter}
//           >
//             <option value="">Select Spend Filter</option>
//             <option value="yearly">Yearly</option>
//             <option value="quarterly">Quarterly</option>
//             <option value="monthly">Monthly</option>
//           </select>
//         </div>
//       </div>

//       {/* Row 1 */}
//       <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6">
//         <MetricCard
//           title="Outstanding Invoices"
//           value={dashboardDataFetched ? dashboardDataFetched.metrics.outstandingInvoices.value : dashboardData.metrics.outstandingInvoices.value}
//           trend={dashboardDataFetched ? dashboardDataFetched.metrics.outstandingInvoices.trend : dashboardData.metrics.outstandingInvoices.trend}
//           data={dashboardDataFetched ? dashboardDataFetched.metrics.outstandingInvoices.data : dashboardData.metrics.outstandingInvoices.data}
//         />
//         <MetricCard
//           title="Amount Due"
//           value={dashboardDataFetched ? dashboardDataFetched.metrics.amountDue.value : dashboardData.metrics.amountDue.value}
//           trend={dashboardDataFetched ? dashboardDataFetched.metrics.amountDue.trend : dashboardData.metrics.amountDue.trend}
//           prefix={dashboardDataFetched ? dashboardDataFetched.metrics.amountDue.prefix : dashboardData.metrics.amountDue.prefix}
//           data={dashboardDataFetched ? dashboardDataFetched.metrics.amountDue.data : dashboardData.metrics.amountDue.data}
//         />
//         <MetricCard
//           title="Total Spend"
//           value={dashboardDataFetched ? dashboardDataFetched.metrics.totalSpend.value : dashboardData.metrics.totalSpend.value}
//           trend={dashboardDataFetched ? dashboardDataFetched.metrics.totalSpend.trend : dashboardData.metrics.totalSpend.trend}
//           prefix={dashboardDataFetched ? dashboardDataFetched.metrics.totalSpend.prefix : dashboardData.metrics.totalSpend.prefix}
//           data={dashboardDataFetched ? dashboardDataFetched.metrics.totalSpend.data : dashboardData.metrics.totalSpend.data}
//         />
//         <InvoiceStatus data={dashboardDataFetched ? dashboardDataFetched.invoiceStatus : dashboardData.invoiceStatus} />
//       </div>

//       {/* Row 2 */}
//       <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
//         <TopVendors data={dashboardDataFetched ? dashboardDataFetched.topVendors : dashboardData.topVendors} />
//         <SpendByYear data={getFilteredSpendData()} timeFilter={dashboardDataFetched ? dashboardDataFetched.spendByYear : dashboardData.spendByYear} />
//         <SpendByCategory data={dashboardDataFetched ? dashboardDataFetched.spendByCategory : dashboardData.spendByCategory} />
//       </div>

//       {/* Row 3 */}
//       <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//         <PaymentMethod data={dashboardDataFetched ? dashboardDataFetched.paymentMethods : dashboardData.paymentMethods} />
//         <InvoiceByCustomer data={dashboardDataFetched ? dashboardDataFetched.outstandingInvoicesByCustomer : dashboardData.outstandingInvoicesByCustomer} />
//         <InvoiceAgeBreakdown data={dashboardDataFetched ? invoiceage : {"30 Days": 0, "30-60 Days": 0, "90+ Days": 0}} />
//       </div>
//     </div>
//   );
// };

// export default Dashboard;