import React, { useRef, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  DoughnutController,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  ArcElement,
  DoughnutController,
  Tooltip,
  Legend
);

const PaymentMethod = ({ data, title, onFilterChange }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [filteredData, setFilteredData] = useState(data);
  const [selectedLabel, setSelectedLabel] = useState(null); // Track the selected label

  useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const total = Object.values(filteredData).reduce((sum, value) => sum + value, 0);

    const chartData = {
      labels: Object.keys(filteredData),
      datasets: [
        {
          data: Object.values(filteredData),
          backgroundColor: [
            '#06B6D2',
            '#22D3EE',
            '#67E8F9',
            '#22C55E',
            '#84CC16',
            '#D2FF994D',
          ],
          borderWidth: 0,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: '50%',
      plugins: {
        legend: {
          display: true,
          position: 'right',
          align: 'center',
          labels: {
            boxWidth: 12,
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 15,
            color: '#6B7280',
            font: {
              size: 12,
            },
          },
        },
        datalabels: {
          formatter: (value) => {
            let percentage = Math.round((value / total) * 100);
            return `${percentage}%`; // Display percentage
          },
          color: '#fff', // Label color
        },
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem) => {
            const label = tooltipItem.label || '';
            const value = tooltipItem.raw;
            return `${label}: $${value.toLocaleString()}`; // Format tooltip
          },
        },
      },
      onClick(event) {
        const elements = chartInstanceRef.current.getElementsAtEventForMode(event.native, 'nearest', { intersect: true }, false);
        if (elements.length) {
          const clickedIndex = elements[0].index;
          const clickedLabel = chartData.labels[clickedIndex];

          // Check if the clicked label is already selected
          if (selectedLabel === clickedLabel) {
            // Reset filter to show all data
            setFilteredData(data); // Reset to original data
            setSelectedLabel(null); 
            // Send filter value back - uncomment this when data is being fetched from the backend and dashboard has a filter change functiion
            // onFilterChange({ paymentMethod: "" });
          } else {
            // Filter data based on the clicked label
            const newFilteredData = { [clickedLabel]: filteredData[clickedLabel] };
            
            // Update state with new filtered data
            setFilteredData(newFilteredData);
            setSelectedLabel(clickedLabel); // Update selected label
            
            // Send filter value back - uncomment this when data is being fetched from the backend and dashboard has a filter change functiion
            // onFilterChange({ paymentMethod: clickedLabel });
          }
        }
      },
    };

    if (chartRef.current) {
      chartInstanceRef.current = new ChartJS(chartRef.current, {
        type: 'doughnut',
        data: chartData,
        options,
        plugins: [ChartDataLabels],
      });
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [filteredData]); // Re-run when filteredData changes

  return (
    <div className="bg-white p-6 rounded-xl shadow-md text-left border">
      <div>
        <p className='font-semibold text-sm'>{title ? title : "Spend By Payment Method"}</p>
      </div>
      <div className="h-[320px] relative p-8">
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default PaymentMethod;
