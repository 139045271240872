import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";

const Checkbox = React.forwardRef(
  (props, ref) => (
    <CheckboxPrimitive.Root
      ref={ref}
      className="peer h-4 w-4 shrink-0 rounded-md border border-[#00A76F] shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#00A76F] disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-[#00A76F] data-[state=checked]:text-white"
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className="flex items-center justify-center text-current"
      >
        <Check className="h-4 w-4" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )
);

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
