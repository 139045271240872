import React, { useRef, useEffect } from 'react';
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register necessary components from Chart.js
ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const MetricCard = ({ title, value, trend, data, prefix, suffix = '' }) => {
  const chartRef = useRef(null); // Reference to the canvas element
  const chartInstanceRef = useRef(null); // Store the Chart.js instance

  useEffect(() => {
    // Cleanup previous chart instance
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const chartData = {
      labels: Array(data.length).fill(''), // Empty labels for X-axis
      datasets: [
        {
          data: data,
          borderColor: trend.startsWith('+') ? '#10B981' : '#EF4444', // Green for positive trend, red for negative
          backgroundColor: trend.startsWith('+') ? 'rgba(16, 185, 129, 0.1)' : 'rgba(239, 68, 68, 0.1)',
          borderWidth: 1.5,
          tension: 0.4, // adds curve to the line
          fill: true,
          pointRadius: 1, // Hide points with radius of 0
        },
      ],
    };

    // Define chart options
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      scales: {
        x: {
          display: false,
          grid: {
            display: false,
          },
        },
        y: {
          display: false,
          grid: {
            display: false,
          },
          min: Math.min(...data) * 0.9, // adds padding
          max: Math.max(...data) * 1.0,
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
    };

    // Create new chart instance
    if (chartRef.current) {
      chartInstanceRef.current = new ChartJS(chartRef.current, {
        type: 'line',
        data: chartData,
        options: chartOptions
      });
    }

    // Cleanup on unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };

  }, [data, trend]); // Re-run if data or trend changes

  return (
    <div className="bg-white border p-6 rounded-xl h-[200px] max-w-full min-h-fit text-left">
      <h3 className="text-gray-400 text-base font-normal mb-1 truncate">{title}</h3>

      <div className="flex items-center justify-between gap-2 min-w-0 overflow-hidden">
        <p className="number text-3xl md:text-lg lg:text-2xl font-medium min-w-20 truncate">
          {prefix}{Number(value).toLocaleString()}{suffix}
        </p>
        <span
          className={`text-sm font-medium ${trend.startsWith("+") ? "text-green-500" : "text-red-500"
            } whitespace-nowrap`}
        >
          {trend}
        </span>
      </div>

      <div className="h-[70px] mt-4 w-full overflow-hidden">
        <canvas ref={chartRef} className="w-full h-full" />
      </div>
    </div>
  );
};

export default MetricCard;