import React from "react";
import stackImage from "../../static/img/stack.svg";
import {Helmet} from "react-helmet-async";
import Img from "../../components/Img";
import {Link} from "react-router-dom";

export default function ResetSuccess() {
    return <>
        <Helmet title={"Reset Success | Nimbey"}/>
        <div className={"flex flex-col card w-full shadow items-center gap-2"}>
            <Img src={stackImage} alt={"password-illus"}/>
            <span className={"text-md text-center font-bold"}>Password changed successfully!</span>
            <span className={"text-sm text-center text-gray-700"}>You can login using the button below.</span>
            <Link to={"/staging-app/login"} type={"submit"}
                  className={"w-full px-6 py-3 rounded-md bg-accentBack hover:bg-green-700 text-white text-center"}>
                Login
            </Link>
        </div>
    </>
}