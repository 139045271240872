import {Doughnut} from "react-chartjs-2";
import {useEffect, useRef} from "react";

function ProductCategories({data, chat}) {

    const chart = useRef();

    useEffect(() => {
        chart.current?.chartInstance?.update();
    }, [data, chat])

    if (!data) return;

    const chapters = {};
    for (let i of data.product_breakdown) {
        chapters[i.chapter] = (chapters[i.chapter] || 0) + i.shipments
    }
    const proc = Object.entries(chapters).sort((a, b) => a[1] > b[1] ? -1 : 1);
    if (proc.length > 4) {
        const other = proc.slice(4).reduce((a, b) => a + b[1], 0);
        proc.splice(4);
        proc.push(["Other", other]);
    }

    return <div className={"flex flex-col md:items-stretch w-full lg:max-w-96 2xl:max-w-screen-xs card p-0"}>
        <div className={"flex items-center gap-2 p-4 pb-0"}>
            <span className={"material-symbols-rounded text-md"}>drag_indicator</span>
            <span className={"text-sm font-semibold"}>Product Categories</span>
        </div>
        <div className={"flex flex-col gap-2 p-4"}>
                        <Doughnut ref={chart} data={{
                labels: proc.map(x => x[0]),
                datasets: [{
                    data: proc.map(x => x[1]), backgroundColor: ["rgba(0, 167, 111, 1)",
                        "rgba(183, 239, 111, 1)",
                        "rgba(255, 245, 0, 1)",
                        "rgba(96, 171, 203, 1)",
                        "rgba(183, 239, 191, 1)",]
                }]
            }} options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {legend: {position: 'right'}}
            }}/>

        </div>
    </div>
}

export default ProductCategories;