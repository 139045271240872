import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Nimbey from "../static/img/logos/NimbeyLogo.svg";
import NimbeyLemon from "../static/img/logos/Lemon.png";
import Img from "./Img";
import { logout } from "../api/auth";


const SideItem = ({
  text,
  icon,
  textStyle = {},
  path = "",
  onClick,
  toggleSide,
}) => {
  const active = Boolean(
    window.location.pathname
      .replace("/staging-app", "")
      .match(`^${path.replace("/", "/")}/?$`)
  );

  return (
    <li
      onClick={() => {
        if (onClick) onClick();
        document.getElementById("sideBar").classList.add("hidden");
      }}
    >
      <Link
        to={`/staging-app` + path}
        className={`flex items-center pl-3 pr-6 py-2 gap-4 my-2 text-sm rounded-2xl
              ${
                active
                  ? "bg-nimbey hover:bg-accentBackLight text-accent hover:text-white"
                  : "hover:bg-accentBackLight text-emerald-50"
              }`}
      >
        {icon}
        <span className={"overflow-hidden whitespace-nowrap"}>{text}</span>
      </Link>
    </li>
  );
};

export default function Sidebar(props = {}) {
  const navigate = useNavigate();
  const userWantSideOpen = Number(localStorage.getItem("sideOpen"));
  
  const [showLemon, setShowLemon] = useState(
    userWantSideOpen === undefined ? props.chat : !Boolean(userWantSideOpen)
  );
  const [isSideOpen, setIsSideOpen] = useState(
    userWantSideOpen === undefined ? !props.chat : Boolean(userWantSideOpen)
  );

  const handleLogout = () => {
    logout().then(([status, _]) => {
      if (status === 200) {
        navigate("/staging-app/login");
      } else alert(_);
    });
  };

  const toggleSideBar = () => {
    setIsSideOpen(!isSideOpen);
    localStorage.setItem("sideOpen", (!isSideOpen ? 1 : 0).toString());
    setShowLemon(false);
  };
  
  const toggleSideSub = (e, id) => {
    e.target.innerHTML === "chevron_right"
      ? (e.target.innerHTML = "expand_more")
      : (e.target.innerHTML = "chevron_right");
    const subMenu = document.getElementById(id);
    subMenu.style.display = subMenu.style.display === "none" ? "flex" : "none";
  };

  useEffect(() => {
    function switchLemon(ev) {
      const { width } = document
        .getElementById("sideBar")
        .getBoundingClientRect();
      if (!isSideOpen) {
        if (width < 161) {
          setShowLemon(ev.type !== "mouseenter" && !isSideOpen);
        } else {
          setShowLemon(ev.type === "mouseleave" && !isSideOpen);
        }
      }
    }

    document
      .getElementById("sideBar")
      ?.addEventListener("mouseenter", switchLemon);
    document
      .getElementById("sideBar")
      ?.addEventListener("mouseleave", switchLemon);
    return () => {
      document
        .getElementById("sideBar")
        ?.removeEventListener("mouseenter", switchLemon);
      document
        .getElementById("sideBar")
        ?.removeEventListener("mouseleave", switchLemon);
    };
  }, [showLemon, isSideOpen]);

  return (
    <div
      id={"sideBar"}
      className={`flex flex-col w-full  ${
        isSideOpen
          ? "lg:w-[250px]"
          : "md:w-[250px] lg:block lg:w-[67px] hover:lg:w-[250px]"
      } hidden lg:flex h-full bg-accentBack fixed lg:relative top-0 left-0 z-50`}
    >
      <div
        className={
          "p-4 lg:p-6 mb-2 flex items-center justify-center relative overflow-hidden"
        }
      >
        <Img
          src={NimbeyLemon}
          alt={"Nimbey-logo"}
          className={`h-[30px] bg-accentBack absolute`}
        />
        <div className={"flex items-stretch w-full z-10 overflow-hidden"}>
          <div className={"flex items-center gap-4 bg-accentBack"}>
            <button
              onClick={() =>{
                document.getElementById("sideBar").classList.toggle("hidden")
              }
              }
              className={
                "material-symbols-rounded text-xl text-gray-200 p-1 px-2 overflow-hidden lg:hidden"
              }
            >
              close
            </button>
            <Img
              id={"sideLogo"}
              src={Nimbey}
              alt={"Nimbey-logo"}
              className={`h-8 ${showLemon && "lg:hidden"}`}
            />
          </div>
          <div className={"flex flex-1 bg-accentBack overflow-hidden "} />
          <button
            onClick={toggleSideBar}
            className={
              "material-symbols-rounded pl-8 text-xl text-gray-200 overflow-hidden hidden lg:block"
            }
          >
            {isSideOpen ? "menu" : "menu_open"}
          </button>
        </div>
      </div>
      <div className={"flex flex-col w-full h-full overflow-hidden gap-3 px-3"}>
        <ul className={"flex flex-col items-stretch justify-start w-full"}>
          <SideItem
            toggleSide={isSideOpen}
            path={"/dashboard"}
            text={"Home"}
            icon={
              <span className={"material-symbols-rounded text-xl"}>home</span>
            }
          />
          <SideItem
            toggleSide={isSideOpen}
            path={"/dashboard/admin/deductions"}
            text={"Dashboard"}
            icon={
              <span className={"material-symbols-rounded text-xl"}>space_dashboard</span>
            }
          />
          {/* <SideItem
            toggleSide={isSideOpen}
            path={"/dashboard/products"}
            text={"Products"}
            icon={
              <span className={"material-symbols-rounded text-xl"}>hub</span>
            }
          /> */}
          {/* <SideItem
            toggleSide={isSideOpen}
            path={"/dashboard/settings"}
            text={"Settings"}
            icon={
              <span className={"material-symbols-rounded text-xl"}>
                settings
              </span>
            }
          /> */}
        </ul>

        {props.user && props.user && (
          <>
            <div
              className={
                "flex items-center w-full pl-3 pr-6 mt-4 gap-3 text-gray-500 overflow-hidden"
              }
            >
              <span className={"material-symbols-rounded text-xl"}>
                admin_panel_settings
              </span>
              <span
                className={" flex gap-2 items-center text-xs overflow-hidden"}
              >
                Admin
              </span>
              <hr className={"flex-[5] border-gray-500"} />
              <button
                onClick={(e) => {
                  toggleSideSub(e, "adminSideNav");
                }}
                className={
                  "outline-0 material-symbols-rounded text-black text-md rounded-full bg-gray-500"
                }
              >
                chevron_right
              </button>
            </div>
            <ul
              id={"adminSideNav"}
              className={
                "flex flex-col mt-2 items-stretch justify-start w-full"
              }
            >
              <SideItem
                toggleSide={isSideOpen}
                path={"/dashboard/admin/invoice-analysis"}
                text={"Invoice Analysis"}
                icon={
                  <span className={"material-symbols-rounded text-xl"}>
                    assignment
                  </span>
                }
              />
              <SideItem
                toggleSide={isSideOpen}
                path={"/dashboard/admin/ra-analysis"}
                text={"RA Analysis"}
                icon={
                  <span className={"material-symbols-rounded text-xl"}>
                    description
                  </span>
                }
              />
              {/* <SideItem
                toggleSide={isSideOpen}
                path={"/dashboard/admin"}
                text={"Dashboard"}
                icon={
                  <span className={"material-symbols-rounded text-xl"}>
                    bar_chart
                  </span>
                }
              />
              <SideItem
                toggleSide={isSideOpen}
                path={"/dashboard/admin/orders"}
                text={"Previous Orders"}
                icon={
                  <span className={"material-symbols-rounded text-xl"}>
                    orders
                  </span>
                }
              /> */}
            </ul>
          </>
        )}

        <ul
          className={
            "flex flex-col items-stretch justify-start w-full h-fit mt-4 mb-2"
          }
        >
          <SideItem 
            toggleSide={isSideOpen}
            path="/dashboard/settings"
            text={"Settings"}
            icon={
              <span className="material-symbols-rounded text-xl">
                settings
              </span>
            }
          />
          <SideItem
            toggleSide={isSideOpen}
            path={"/dashboard/help"}
            text={"Help"}
            icon={
              <span className={"material-symbols-rounded text-xl"}>info</span>
            }
          />
          <SideItem
            toggleSide={isSideOpen}
            onClick={handleLogout}
            text={"Logout"}
            icon={
              <span className={"material-symbols-rounded text-xl"}>logout</span>
            }
          />
        </ul>
      </div>
    </div>
  );
}