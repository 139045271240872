// import React, { useEffect, useState } from "react";
// import { Checkbox } from '../components/ui/checkbox';
// import { updateInvoice } from '../api/dashboard';

// const mockInvoiceData = {
//   "Invoice Amount": "$2343.00",
//   "Total Invoice Amount": "$2343.00",
//   "table_data": [
//     {
//       description: "Freight Charge",
//       unit_price: 3200.00,
//       quantity: 1,
//       amount: 3200.00,
//       corrected_amount: 3200.00,
//       verified: true
//     },
//     {
//       description: "Freight Discount",
//       unit_price: -2176.00,
//       quantity: 1,
//       amount: -2176.00,
//       corrected_amount: -2336.00,
//       verified: true
//     },
//     {
//       description: "Residential Delivery",
//       unit_price: 28.00,
//       quantity: 1,
//       amount: 28.00,
//       corrected_amount: 28.00,
//       verified: false
//     },
//     {
//       description: "Liftgate Charge",
//       unit_price: 30.00,
//       quantity: 1,
//       amount: 30.00,
//       corrected_amount: 30.00,
//       verified: true
//     },
//   ],
//   "summary": {
//     "Charge A": "Charge A was incorrectly billed due to a system error.",
//     "Charge C": "Charge C was charged twice in error and needs to be refunded."
//   },
//   billed_to_name: "John Doe",
//   total_amount: "$2343.00"
// };

// const Table = ({ invoice_number = "INV-001", data = mockInvoiceData.table_data, setData = () => {} }) => {
//   if (typeof data === 'object' && data !== null) {
//     data = Object.entries(data).map(([description, values]) => {
//       const isDiscount = String(description).toLowerCase().includes("discount");
  
//       return {
//         description,
//         unit_price: isDiscount ? -values?.['Unit Price'] || 0 : values?.['Unit Price'] || 0,
//         quantity: values?.['Qty'] || 0,
//         amount: isDiscount ? -values?.['Amount'] || 0 : values?.['Amount'] || 0,
//         corrected_amount: isDiscount
//           ? (values?.['Corrected Amount'] && values?.['Corrected Amount'] !== 0 ? values['Corrected Amount'] : values?.['Amount'] || 0)
//           : (values?.['Corrected Amount'] && values?.['Corrected Amount'] !== 0 ? values['Corrected Amount'] : values?.['Amount'] || 0),
//         verified: false
//       };
//     });
//   }
  
//   const [editableData, setEditableData] = useState(data);
//   const [isChanged, setIsChanged] = useState(false);

//   useEffect(() => {
//     setEditableData(data);
//   }, [data]);

//   const handleInputChange = (index, field, value) => {
//     const updatedItems = [...editableData];
//     updatedItems[index][field] = value;

//     if (field === "unit_price" || field === "quantity") {
//       const unitPrice = parseFloat(updatedItems[index].unit_price) || 0;
//       const quantity = parseFloat(updatedItems[index].quantity) || 0;
//       updatedItems[index].amount = (unitPrice * quantity).toFixed(2);
//     }

//     setEditableData(updatedItems);
//     setData(updatedItems);
//     setIsChanged(true);
//   };

//   const handleVerifyChange = (index) => {
//     const updatedItems = [...editableData];
//     updatedItems[index].verified = !updatedItems[index].verified;
//     setEditableData(updatedItems);
//     setData(updatedItems);
//     setIsChanged(true);
//   };

//   const handleSaveChanges = () => {
//     const payload = { invoice_number, table_data: editableData };
//     console.log(payload);
//     updateInvoice(payload)
//       .then(() => {
//         alert("Changes saved successfully!");
//         setIsChanged(false);
//       })
//       .catch(error => {
//         console.error("Error saving changes:", error);
//         alert("An error occurred while saving changes: " + error.message);
//       });
//   };

//   return (
//     <div className="p-6">
//       <table className="min-w-full border-collapse table-auto rounded-lg">
//         <thead className="border-b border-gray-200">
//           <tr>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Description</th>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Unit Price</th>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Qty.</th>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Amount</th>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Corrected Amount</th>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Verify</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((item, index) => (
//             <tr key={index} className="border-b border-gray-200">
//               <td className="py-3 px-4">
//                 <input
//                   type="text"
//                   value={item.description}
//                   onChange={(e) => handleInputChange(index, "description", e.target.value)}
//                   className="w-35 bg-white rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
//                 />
//               </td>
//               <td className="py-3 px-4 flex">
//                 $
//                 <input
//                   type="number"
//                   value={item.unit_price}
//                   onChange={(e) => handleInputChange(index, "unit_price", e.target.value)}
//                   className="w-20 bg-white rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
//                 />
//               </td>
//               <td className="py-3 px-4">
//                 <input
//                   type="number"
//                   value={item.quantity}
//                   onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
//                   className="w-10 rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
//                 />
//               </td>
//               <td className="py-3 px-4 flex">
//                 $
//                 <input
//                   type="number"
//                   value={item.amount}
//                   readOnly
//                   className="w-20 rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
//                 />
//               </td>
//               <td className="py-3 px-4">
//                 <input
//                   type="number"
//                   value={item.corrected_amount}
//                   onChange={(e) => handleInputChange(index, "corrected_amount", e.target.value)}
//                   className="w-20 rounded text-[#00A76F] font-semibold focus:outline-none focus:ring-1 focus:ring-lime-200"
//                 />
//               </td>
//               <td className="py-3 px-4">
//                 <Checkbox
//                   checked={item.verified}
//                   onCheckedChange={() => handleVerifyChange(index)}
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="mt-6 flex justify-end gap-4">
//         <button
//           // onClick={handleReject}
//           className="border border-red-500 text-red-500 px-6 py-2 rounded-lg text-sm hover:bg-red-600 hover:text-white"
//         >
//           Reject
//         </button>
//         <button
//           // onClick={handleApprove}
//           className="bg-lime-300 text-black px-6 py-2 rounded-lg text-sm hover:bg-lime-200"
//         >
//           Approve
//         </button>

//         {/* Conditionally render Save Changes button */}
//         {isChanged && (
//           <button
//             onClick={handleSaveChanges}
//             className="text-gray-500 px-6 py-2 rounded-lg text-sm hover:bg-gray-900"
//           >
//             Save Changes
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Table;


import React, { useEffect, useState } from "react";
import { Checkbox } from '../components/ui/checkbox';
import { updateInvoice, updateInvoiceStatus } from '../api/dashboard';

const Table = ({ invoice_number = "INV-001", data, setData = () => {} }) => {
  console.log("received data", data)
  console.log("type received data", typeof(data))
  const [editableData, setEditableData] = useState([]);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    let updatedData = [];
  
    if (Array.isArray(data)) {
      updatedData = data;  // Use the existing array
    } 
    else if (typeof data === "string") {
      try {
        updatedData = JSON.parse(data);
      } catch (error) {
        console.error("Error parsing table data:", error);
      }
    }
    else if (typeof data === 'object' && data !== null) {
      updatedData = Object.entries(data).map(([description, values]) => {
        const isDiscount = String(description).toLowerCase().includes("discount");
    
        return {
          description,
          unit_price: isDiscount ? -values?.['Unit Price'] || 0 : values?.['Unit Price'] || 0,
          quantity: values?.['Qty'] || 0,
          amount: isDiscount ? -values?.['Amount'] || 0 : values?.['Amount'] || 0,
          corrected_amount: isDiscount
            ? (values?.['Corrected Amount'] && values?.['Corrected Amount'] !== 0 ? values['Corrected Amount'] : values?.['Amount'] || 0)
            : (values?.['Corrected Amount'] && values?.['Corrected Amount'] !== 0 ? values['Corrected Amount'] : values?.['Amount'] || 0),
          verified: false
        };
      });
    }
    // setData(updatedData);
    setEditableData(updatedData);
  }, [data]);
  

  const handleInputChange = (index, field, value) => {
    console.log("debug1: handleInputChange",)
    const updatedItems = [...editableData];
    updatedItems[index][field] = value;

    console.log("updatedItems$$$$$: ", updatedItems)

    if (field === "unit_price" || field === "quantity") {
      const unitPrice = parseFloat(updatedItems[index].unit_price) || 0;
      const quantity = parseFloat(updatedItems[index].quantity) || 0;
      updatedItems[index].amount = (unitPrice * quantity).toFixed(2);
    }

    setEditableData(updatedItems);
    setData(updatedItems);
    setIsChanged(true);

    console.log("updated...")
  };

  const handleVerifyChange = (index) => {
    const updatedItems = [...editableData];
    updatedItems[index].verified = !updatedItems[index].verified;
    setEditableData(updatedItems);
    setData(updatedItems);
    setIsChanged(true);
  };

  const handleSaveChanges = () => {
    const payload = { invoice_number, table_data: editableData };
    console.log(payload);
    updateInvoice(payload)
      .then(() => {
        alert("Changes saved successfully!");
        setIsChanged(false);
      })
      .catch(error => {
        console.error("Error saving changes:", error);
        alert("An error occurred while saving changes: " + error.message);
      });
  };

  const handleKeyPress = (event, index, field) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleInputChange(index, field, event.target.value);
    }
  };

  const handleStatusChange = async (invoiceId, newStatus) => {
    try {
      const type = 'inv';
      const payload = {invoiceId, newStatus, type};
      updateInvoiceStatus(payload);  // nakshatra - this is the new status change function
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleReject = async () => {
    try {
      await handleStatusChange(invoice_number, 'Rejected');
      alert('Invoice Rejected');
      window.location.reload();
    } catch (error) {
      console.error("Error rejecting invoice:", error);
      alert("Failed to reject invoice."); 
    }
  };
  
  const handleApprove = async () => {
    try {
      await handleStatusChange(invoice_number, 'Accepted');
      alert('Invoice Approved!');
      window.location.reload();
    } catch (error) {
      console.error("Error approving invoice:", error);
      alert("Failed to approve invoice.");
    }
  };  

  return (
    <div className="p-6">
      <table className="min-w-full border-collapse table-auto rounded-lg">
        <thead className="border-b border-gray-200">
          <tr>
            <th className="py-3 px-4 text-left font-semibold text-gray-700">Description</th>
            <th className="py-3 px-4 text-left font-semibold text-gray-700">Unit Price</th>
            <th className="py-3 px-4 text-left font-semibold text-gray-700">Qty.</th>
            <th className="py-3 px-4 text-left font-semibold text-gray-700">Amount</th>
            <th className="py-3 px-4 text-left font-semibold text-gray-700">Corrected Amount</th>
            <th className="py-3 px-4 text-left font-semibold text-gray-700">Verify</th>
          </tr>
        </thead>
        <tbody>
          {console.log("I am from Debug: ", Array.isArray(editableData))}
          {Array.isArray(editableData) && editableData.map((item, index) => (
            <tr key={index} className="border-b border-gray-200">
              <td className="py-3 px-4">
                <input
                  type="text"
                  value={item.description}
                  onChange={(e) => handleInputChange(index, "description", e.target.value)}
                  className="w-35 bg-white rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
                />
              </td>
              <td className="py-3 px-4 flex">
                $
                <input
                  type="number"
                  value={item.unit_price}
                  onChange={(e) => handleInputChange(index, "unit_price", e.target.value)}
                  className="w-20 bg-white rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
                />
              </td>
              <td className="py-3 px-4">
                <input
                  type="number"
                  value={item.quantity}
                  onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
                  className="w-10 rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
                />
              </td>
              <td className="py-3 px-4 flex">
                $
                <input
                  type="number"
                  value={item.amount}
                  readOnly
                  className="w-20 rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
                />
              </td>
              <td className="py-3 px-4">
                <input
                  type="number"
                  value={item.corrected_amount}
                  onChange={(e) => handleInputChange(index, "corrected_amount", e.target.value)}
                  className="w-20 rounded text-[#00A76F] font-semibold focus:outline-none focus:ring-1 focus:ring-lime-200"
                />
              </td>
              <td className="py-3 px-4">
                <Checkbox
                  checked={item.verified}
                  onCheckedChange={() => handleVerifyChange(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-6 flex justify-end gap-4">
        <button
          onClick={handleReject}
          className="border border-red-500 text-red-500 px-6 py-2 rounded-lg text-sm hover:bg-red-600 hover:text-white"
        >
          Reject
        </button>
        <button
          onClick={handleApprove}
          className="border border-lime-400 text-black px-6 py-2 rounded-lg text-sm hover:bg-lime-400 hover:text-white"
        >
          Approve
        </button>

        {/* Conditionally render Save Changes button */}
        {isChanged && (
          <button
            onClick={handleSaveChanges}
            className="text-gray-500 px-6 py-2 rounded-lg text-sm hover:bg-gray-900"
          >
            Save Changes
          </button>
        )}
      </div>
    </div>
  );
};

export default Table;


// import React, { useEffect, useState } from "react";
// import { Checkbox } from '../components/ui/checkbox';
// import { updateInvoice, updateInvoiceStatus } from '../api/dashboard';

// const mockInvoiceData = {
//   "Invoice Amount": "$2343.00",
//   "Total Invoice Amount": "$2343.00",
//   "table_data": [
//     {
//       description: "Freight Charge",
//       unit_price: 3200.00,
//       quantity: 1,
//       amount: 3200.00,
//       corrected_amount: 3200.00,
//       verified: true
//     },
//     {
//       description: "Freight Discount",
//       unit_price: -2176.00,
//       quantity: 1,
//       amount: -2176.00,
//       corrected_amount: -2336.00,
//       verified: true
//     },
//     {
//       description: "Residential Delivery",
//       unit_price: 28.00,
//       quantity: 1,
//       amount: 28.00,
//       corrected_amount: 28.00,
//       verified: false
//     },
//     {
//       description: "Liftgate Charge",
//       unit_price: 30.00,
//       quantity: 1,
//       amount: 30.00,
//       corrected_amount: 30.00,
//       verified: true
//     },
//   ],
//   "summary": {
//     "Charge A": "Charge A was incorrectly billed due to a system error.",
//     "Charge C": "Charge C was charged twice in error and needs to be refunded."
//   },
//   billed_to_name: "John Doe",
//   total_amount: "$2343.00"
// };

// const Table = ({ invoice_number = "INV-001", data = mockInvoiceData.table_data, setData = () => {} }) => {
//   console.log("received data", data)
//   console.log("type received data", typeof(data))
//   const [editableData, setEditableData] = useState([]);
//   const [isChanged, setIsChanged] = useState(false);

//   useEffect(() => {
//     let updatedData = [];
  
//     if (Array.isArray(data)) {
//       updatedData = data;  // Use the existing array
//     } 
//     else if (typeof data === "string") {
//       try {
//         updatedData = JSON.parse(data);
//       } catch (error) {
//         console.error("Error parsing table data:", error);
//       }
//     }
//     else if (typeof data === 'object' && data !== null) {
//       updatedData = Object.entries(data).map(([description, values]) => {
//         const isDiscount = String(description).toLowerCase().includes("discount");
    
//         return {
//           description,
//           unit_price: isDiscount ? -values?.['Unit Price'] || 0 : values?.['Unit Price'] || 0,
//           quantity: values?.['Qty'] || 0,
//           amount: isDiscount ? -values?.['Amount'] || 0 : values?.['Amount'] || 0,
//           corrected_amount: isDiscount
//             ? (values?.['Corrected Amount'] && values?.['Corrected Amount'] !== 0 ? values['Corrected Amount'] : values?.['Amount'] || 0)
//             : (values?.['Corrected Amount'] && values?.['Corrected Amount'] !== 0 ? values['Corrected Amount'] : values?.['Amount'] || 0),
//           verified: false
//         };
//       });
//     }
//     // setData(updatedData);
//     setEditableData(updatedData);
//   }, [data]);
  

//   const handleInputChange = (index, field, value) => {
//     console.log("debug1: handleInputChange",)
//     const updatedItems = [...editableData];
//     updatedItems[index][field] = value;

//     console.log("updatedItems$$$$$: ", updatedItems)

//     if (field === "unit_price" || field === "quantity") {
//       const unitPrice = parseFloat(updatedItems[index].unit_price) || 0;
//       const quantity = parseFloat(updatedItems[index].quantity) || 0;
//       updatedItems[index].amount = (unitPrice * quantity).toFixed(2);
//     }

//     setEditableData(updatedItems);
//     setData(updatedItems);
//     setIsChanged(true);

//     console.log("updated...")
//   };

//   const handleVerifyChange = (index) => {
//     const updatedItems = [...editableData];
//     updatedItems[index].verified = !updatedItems[index].verified;
//     setEditableData(updatedItems);
//     setData(updatedItems);
//     setIsChanged(true);
//   };

//   const handleSaveChanges = () => {
//     const payload = { invoice_number, table_data: editableData };
//     console.log(payload);
//     updateInvoice(payload)
//       .then(() => {
//         alert("Changes saved successfully!");
//         setIsChanged(false);
//       })
//       .catch(error => {
//         console.error("Error saving changes:", error);
//         alert("An error occurred while saving changes: " + error.message);
//       });
//   };

//   const handleKeyPress = (event, index, field) => {
//     if (event.key === "Enter") {
//       event.preventDefault();
//       handleInputChange(index, field, event.target.value);
//     }
//   };

//   const handleStatusChange = async (invoiceId, newStatus) => {
//     try {
//       const type = 'inv';
//       const payload = {invoiceId, newStatus, type};
//       updateInvoiceStatus(payload);  // nakshatra - this is the new status change function
//     } catch (error) {
//       console.error('Error updating status:', error);
//     }
//   };

//   const handleReject = async () => {
//     try {
//       await handleStatusChange(invoice_number, 'Rejected');
//       alert('Invoice Rejected');
//       window.location.reload();
//     } catch (error) {
//       console.error("Error rejecting invoice:", error);
//       alert("Failed to reject invoice."); 
//     }
//   };
  
//   const handleApprove = async () => {
//     try {
//       await handleStatusChange(invoice_number, 'Accepted');
//       alert('Invoice Approved!');
//       window.location.reload();
//     } catch (error) {
//       console.error("Error approving invoice:", error);
//       alert("Failed to approve invoice.");
//     }
//   };  

//   return (
//     <div className="p-6">
//       <table className="min-w-full border-collapse table-auto rounded-lg">
//         <thead className="border-b border-gray-200">
//           <tr>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Description</th>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Unit Price</th>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Qty.</th>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Amount</th>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Corrected Amount</th>
//             <th className="py-3 px-4 text-left font-semibold text-gray-700">Verify</th>
//           </tr>
//         </thead>
//         <tbody>
//           {console.log("I am from Debug: ", Array.isArray(editableData))}
//           {Array.isArray(editableData) && editableData.map((item, index) => (
//             <tr key={index} className="border-b border-gray-200">
//               <td className="py-3 px-4">
//                 <input
//                   type="text"
//                   value={item.description}
//                   onChange={(e) => handleInputChange(index, "description", e.target.value)}
//                   className="w-35 bg-white rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
//                 />
//               </td>
//               <td className="py-3 px-4 flex">
//                 $
//                 <input
//                   type="number"
//                   value={item.unit_price}
//                   onChange={(e) => handleInputChange(index, "unit_price", e.target.value)}
//                   className="w-20 bg-white rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
//                 />
//               </td>
//               <td className="py-3 px-4">
//                 <input
//                   type="number"
//                   value={item.quantity}
//                   onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
//                   className="w-10 rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
//                 />
//               </td>
//               <td className="py-3 px-4 flex">
//                 $
//                 <input
//                   type="number"
//                   value={item.amount}
//                   readOnly
//                   className="w-20 rounded focus:outline-none focus:ring-1 focus:ring-lime-200"
//                 />
//               </td>
//               <td className="py-3 px-4">
//                 <input
//                   type="number"
//                   value={item.corrected_amount}
//                   onChange={(e) => handleInputChange(index, "corrected_amount", e.target.value)}
//                   className="w-20 rounded text-[#00A76F] font-semibold focus:outline-none focus:ring-1 focus:ring-lime-200"
//                 />
//               </td>
//               <td className="py-3 px-4">
//                 <Checkbox
//                   checked={item.verified}
//                   onCheckedChange={() => handleVerifyChange(index)}
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="mt-6 flex justify-end gap-4">
//         <button
//           onClick={handleReject}
//           className="border border-red-500 text-red-500 px-6 py-2 rounded-lg text-sm hover:bg-red-600 hover:text-white"
//         >
//           Reject
//         </button>
//         <button
//           onClick={handleApprove}
//           className="border border-lime-400 text-black px-6 py-2 rounded-lg text-sm hover:bg-lime-400 hover:text-white"
//         >
//           Approve
//         </button>

//         {/* Conditionally render Save Changes button */}
//         {isChanged && (
//           <button
//             onClick={handleSaveChanges}
//             className="text-gray-500 px-6 py-2 rounded-lg text-sm hover:bg-gray-900"
//           >
//             Save Changes
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Table;