import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: true
}

const openBotSlice = createSlice({
    name: 'openBot',
    initialState,
    reducers: {
        toggleChatBot: (state) => {
            state.isOpen = !state.isOpen;
        }
    }
});

export const { toggleChatBot } = openBotSlice.actions;

export default openBotSlice;