import {Line} from "react-chartjs-2";
import React from "react";
import {ComparisonArrow, getTimePeriod, handleTip, humanizePrice, unitizeAmount, colors} from "./utils";

function TotalSpend({data, term, postFilters, id = "totalSpend"}) {
    const activeTab = term === 0 ? "Year" : term === 1 ? "Quarter" : "Month"
    const labels = []
    const datasets = []

    const all = Object.keys(data).toSorted().toReversed()
        .map(k => [k, data[k].reduce((prev, curr) => prev + curr.total_amount, 0)]);

    if (term === 0) {
        const dataRecord = {label: `Past 5 years`, data: []}
        all.toReversed().forEach(([year, value]) => {
            labels.push(year);
            dataRecord.data.push(value);
        });
        datasets.push(dataRecord);
    } else if (term === 1) {
        if (all.length) {
            const years = Array.from(new Set(all.map(x => x[0].split("/")[0]))).toSorted().toReversed();
            const k = Object.fromEntries(all);
            labels.push(...["Q-1", "Q-2", "Q-3", "Q-4"]);
            years.slice(0, 2).forEach((year, i) => {
                const dataRecord = {borderColor: colors[i], label: `In ${year}`, backgroundColor: colors[i], data: []}
                for (let i = 0; i < 4; i++) {
                    dataRecord.data.push(k[`${year}/${i}`] || null)
                }
                datasets.push(dataRecord)
            })
        }
    } else {
        if (all.length) {
            const years = Array.from(new Set(all.map(x => x[0].split("/")[0]))).toSorted().toReversed();
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            const k = Object.fromEntries(all);
            labels.push(...months);
            years.slice(0, 2).forEach((year, i) => {
                const dataRecord = {borderColor: colors[i], label: `In ${year}`, backgroundColor: colors[i], data: []}
                for (let i = 0; i < 12; i++) {
                    dataRecord.data.push(k[`${year}/${i}`] || null);
                }
                datasets.push(dataRecord)
            })
        }
    }

    const [rawPrice, prevRawPrice] = term === 0 ? datasets[0].data.slice(-2).toReversed() :
        datasets.map(d => d.data.reduce((prev, curr) => prev + curr, 0));


    return <div id={"spend-graph"} className={"flex flex-col card aspect-[0.8] lg:aspect-square flex-1 lg:max-h-96"}>

        <div className={"flex flex-col item-start w-full whitespace-nowrap"}>
            <div className={"flex items-center justify-between w-full gap-2 relative"}>
                <div className={"flex items-stretch gap-2"}>
                    <span className={"font-bold text-gray-800 text-md"}>Total Spend</span>

                    <div onMouseEnter={(e) => handleTip(e, "spend-graph")}
                         onMouseLeave={(e) => handleTip(e, "spend-graph")}
                         className={"show-tip"}>
                        <span className={"material-symbols-rounded text-md"}>info</span>
                        <div className={"tool-tip-cover"}>
                    <span
                        className={"p-2 block px-4 whitespace-normal z-10 text-sm w-[20rem]"}>{`The bar graph shows spending across top suppliers in ${activeTab} (${getTimePeriod(term)})`}</span>
                        </div>
                    </div>
                </div>
                {
                    all.length ?
                        <div className={"flex items-end gap-1"}>
                            <span className={"font-extrabold xl:text-xl 2xl:text-2xl"}>${humanizePrice(rawPrice)}</span>
                            <ComparisonArrow one={rawPrice} two={prevRawPrice}/>
                        </div>
                        :
                        <span className={"font-extrabold text-2xl"}>$0</span>
                }

            </div>
            <span className={"text-xs text-gray-600"}>Latest {activeTab}</span>
        </div>

        <div className={"w-full flex-1 overflow-hidden"}>
            <Line id={id} width={"100%"} className={"p-2"}
                  data={{
                      labels: labels,
                      datasets: datasets
                  }}
                  options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      aspectRatio: 1.5,
                      plugins: {
                          tooltip: {
                              callbacks: {
                                  label(tooltipItem) {
                                      return `${tooltipItem.dataset.label}: $${tooltipItem.formattedValue}`;
                                  }
                              }
                          }
                      },
                      scales: {
                          y: {
                              min: postFilters.amount,
                              beginAtZero: false,
                              ticks: {callback: (tickValue, index, ticks) => unitizeAmount(tickValue)}
                          },
                          x: {
                              ticks: {autoSkip: false}
                          }
                      }
                  }}
            />
        </div>
    </div>

}

export default TotalSpend;