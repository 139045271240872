import NimbeyLogo from "../../../static/img/logos/Lemon.png";
import Nimbey from "../../../static/img/logos/NimbeyLogo.svg";
import {Link, useNavigate} from "react-router-dom";
import {logout} from "../../../api/auth";

function Sidebar() {

    const navigate = useNavigate();

    const handleLogout = () => {
        logout().then(([status, _]) => {
            if (status === 200) {
                navigate("/staging-app/login")
            }
        });
    }


    return (
        <div id={"sidebar"}
             className={"hidden fixed md:rounded-full top-0 left-0 bg-accentBack w-full md:w-fit h-full z-50 md:flex md:sticky flex-col md:items-center gap-4 justify-start pb-10 px-2 md:py-4 md:pb-8"}>
            <img alt={"nimbey"} src={NimbeyLogo} className={"p-2 w-9 my-4 mb-8 hidden md:block"}/>
            <div className={"flex w-full items-center gap-4 md:hidden py-4 p-2"}>
                <button onClick={() => document.getElementById("sidebar").classList.toggle("hidden")}
                        className={"material-symbols-rounded rounded-full px-2 text-xl block text-white"}>
                    close
                </button>
                <img alt={"nimbey"} src={Nimbey} className={""}/>
            </div>

            <ul className={"flex flex-col text-gray-100 flex-1 gap-4 lg:gap-0 md:px-0"}>
                <li className={"flex items-center gap-2"}>
                    <Link to={"/staging-app/dashboard"} className={"material-symbols-rounded p-3"}>home</Link>
                    <span className={"text-sm md:hidden font-semibold"}>Home</span>
                </li>
                <li className={"flex items-center gap-2"}>
                    <Link to={"/staging-app/dashboard/contract-upload"}
                          className={"material-symbols-rounded p-3"}>receipt</Link>
                    <span className={"text-sm md:hidden font-semibold"}>IDK</span>
                </li>
                <li className={"flex items-center gap-2"}>
                    <Link to={"#"} className={"material-symbols-rounded p-3"}>settings</Link>
                    <span className={"text-sm md:hidden font-semibold"}>Settings</span>
                </li>
            </ul>
            <ul className={"flex flex-col text-white gap-4 lg:gap-0 md:px-0"}>
                <li className={"flex items-center gap-2"}>
                    <span className={"material-symbols-rounded p-3"}>info</span>
                    <span className={"text-sm md:hidden font-semibold"}>Help</span>
                </li>
                <li className={"flex items-center gap-2"}>
                    <button onClick={handleLogout} className={"material-symbols-rounded p-3"}>logout</button>
                    <span className={"text-sm md:hidden font-semibold"}>Logout</span>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar;