import React, { useRef, useEffect, useState } from 'react';
import { Chart as ChartJS } from 'chart.js';

const SpendByYear = ({ data, timeFilter, spendFilter, title }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [label, setLabel] = useState("Month");

  useEffect(() => {
    // Cleanup previous chart instance
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Get appropriate labels based on time filter
    const getFilterLabel = () => {
      switch (spendFilter) {
        case 'monthly':
          return "Month";
        case 'quarterly':
          return "Quarter";
        default:
          return "Year";
      }
    };
    const currentLabel = getFilterLabel();
    setLabel(currentLabel);

    // Prepare chart data
    const chartData = {
      labels: data.currentYear.map(item => item.month),
      datasets: [
        {
          label: `Current Year ${getFilterLabel()}`,
          data: data.currentYear.map(item => item.amount),
          borderColor: 'rgb(96, 165, 250)', // Light blue
          backgroundColor: 'rgba(96, 165, 250, 0.1)',
          tension: 0,
          borderWidth: 2,
          fill: true,
          pointRadius: timeFilter === 'yearly' ? 4 : 0, // Show points for yearly view
        },
        {
          label: `Previous Year ${getFilterLabel()}`,
          data: data.previousYear.map(item => item.amount),
          borderColor: 'rgb(134, 239, 172)', // Light green
          backgroundColor: 'rgba(134, 239, 172, 0.1)',
          tension: 0,
          borderWidth: 2,
          fill: true,
          pointRadius: timeFilter === 'yearly' ? 4 : 0, // Show points for yearly view
        },
      ],
    };

    // Define chart options
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          align: 'end',
          labels: {
            boxWidth: 30,
            usePointStyle: true,
          },
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { 
                  style: 'currency', 
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: {
            display: false
          },
          beginAtZero: true,
          ticks: {
            callback: function(value) {
              return '$' + value.toLocaleString();
            }
          }
        }
      }
    };

    // Create new chart instance
    if (chartRef.current) {
      chartInstanceRef.current = new ChartJS(chartRef.current, {
        type: 'line',
        data: chartData,
        options,
      });
    }

    // Cleanup on unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data, timeFilter]);

  return (
    <div className="bg-white rounded-xl border h-[400px] relative">
      <div className="border-b pb-1 p-6 flex items-center justify-between">
        <div>
          <h2 className="text-lg font-semibold text-gray-800">{title ? title : "Spend"} by {label}</h2>
          <span className="text-gray-500 text-xs">This Year</span>
        </div>
        <div className='flex items-center space-x-3'>
          <h1 className='font-bold text-2xl'>${data.value}</h1>
        </div>
      </div>
      <div className='h-[320px] px-6 pb-6'>
        <canvas ref={chartRef} className='h-full w-full' />
      </div>
    </div>
  );
};

export default SpendByYear;