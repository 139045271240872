// old code
// import React, {useState} from "react";
// import {useNavigate, Link} from "react-router-dom";
// import {Helmet} from "react-helmet-async";
// import {FormElement} from "../../components/Input";
// import {requestAuthCode, resetPassword, verifyAuthCode} from "../../api/auth";

// export default function ResetPassword() {
//     const navigate = useNavigate();
//     const [email, setEmail] = useState("");
//     const [password, setPassword] = useState("");
//     const [confirmPassword, setConfirmPassword] = useState("");
//     const [errors, setErrors] = useState({});
//     const [loading, setLoading] = useState(false);

//     const [code, setCode] = useState(Array(5).fill(undefined));
//     const [codeSent, setCodeSent] = useState(false);
//     const [verified, setVerified] = useState(false);

//     const requestCode = (evt) => {
//         evt.preventDefault();
//         if (email && evt.target.reportValidity()) {
//             setLoading(true);
//             requestAuthCode({email})
//                 .then(([status, _]) => {
//                     switch (status) {
//                         case 200: {
//                             setCodeSent(true);
//                             break;
//                         }
//                         case 404: {
//                             document.querySelector('input[name=email]').focus();
//                             setErrors({email: "User doesn't exist"});
//                             break;
//                         }
//                         default: {
//                             setErrors({form: "Something went wrong"});
//                         }
//                     }
//                 })
//                 .finally(() => setLoading(false));
//         }
//     }

//     const verifyCode = (evt) => {
//         evt.preventDefault();
//         if (evt.target.reportValidity() &&
//             code.filter(c => typeof c === "number" && !isNaN(c)).length === 5 &&
//             codeSent && email) {
//             setLoading(true);

//             verifyAuthCode({email, code: code.join("")})
//                 .then(([status, _]) => {
//                     switch (status) {
//                         case 200: {
//                             setVerified(true);
//                             break;
//                         }
//                         case 401: {
//                             setErrors({form: "Invalid code"});
//                             break;
//                         }
//                         case 404: {
//                             setErrors({form: "User doesn't exist"});
//                             break;
//                         }
//                         default: {
//                             setErrors({form: "Something went wrong"});
//                         }
//                     }
//                 }).finally(() => setLoading(false));
//         }
//     }

//     const changePassword = (evt) => {
//         evt.preventDefault();
//         if (evt.target.reportValidity() &&
//             code.filter(c => typeof c === "number" && !isNaN(c)).length === 5 &&
//             codeSent && email) {
//             if (password === confirmPassword) {
//                 setLoading(true);
//                 resetPassword({email, code: code.join(""), password})
//                     .then(([status, _]) => {
//                         switch (status) {
//                             case 200: {
//                                 navigate("/staging-app/reset-success");
//                                 break;
//                             }
//                             case 401: {
//                                 setErrors({form: "Invalid code"});
//                                 break;
//                             }
//                             case 404: {
//                                 setErrors({form: "User doesn't exist"});
//                                 break;
//                             }
//                             default: {
//                                 setErrors({form: "Request failed, try again in some time"});
//                             }
//                         }
//                     }).finally(() => setLoading(false));
//             } else {
//                 setErrors({form: "Password don't match"})
//             }
//         }
//     }

//     const handleKeys = (evt, index) => {
//         if (evt.key === "Backspace") {
//             const el = document.querySelector(`input[name=code-${Math.max(evt.target.value === "" ? index - 1 : index, 0)}]`);
//             if (el) {
//                 let k = [...code];
//                 k[index] = undefined;
//                 setCode(k);
//                 el.focus();
//             }
//         }
//     }

//     const handleCode = (evt, index) => {
//         const val = Number.parseInt(evt.target.value);
//         if (!isNaN(val)) {
//             let k = [...code];
//             k[index] = val;
//             setCode(k);
//             if (index < 4)
//                 document.querySelector(`input[name=code-${index + 1}]`)?.focus();
//         } else evt.target.value = "";
//     }

//     const clear = () => {
//         setVerified(false);
//         setCodeSent(false);
//         setCode(code.map(_ => null));
//         setEmail("");
//         setPassword("");
//         setConfirmPassword("");
//     }

//     return <>
//         <Helmet title={"Reset Password | Nimbey"}/>
//         <form onSubmit={verified ? changePassword : codeSent ? verifyCode : requestCode}
//               className={"flex flex-col card w-full shadow gap-2"}>
//             <div className={"flex flex-col w-full"}>
//                 {codeSent && <span className={"text-4xl font-extrabold tracking-widest w-full text-left"}>*****</span>}
//                 {/*{!codeSent && <img src={icPassword} alt={"password-illustration"} className={"w-12"}/>}*/}
//                 {!codeSent && <span className={"text-lg font-bold"}>Reset Password</span>}
//                 {
//                     verified ?
//                         <p className={`text-gray-800 w-full text-sm`}>Please enter a new password for <br/>
//                             <button onClick={clear}
//                                     className={"flex items-center gap-2 font-semibold"}>{email}
//                                 <span className={"material-symbols-rounded text-sm"}>edit</span></button>
//                         </p>
//                         :
//                         codeSent ?
//                             <p className={`text-gray-800 w-full text-sm`}>Please enter the one time password sent
//                                 to <br/>
//                                 <button onClick={clear} type={"button"}
//                                         className={"flex items-center gap-2 font-semibold"}>{email}
//                                     <span className={"material-symbols-rounded text-sm"}>edit</span></button>
//                             </p>
//                             :
//                             <p className={"text-gray-800 w-full text-xs"}>Please enter the email address associated with
//                                 your account, and we'll email you a link to reset your password.</p>
//                 }
//             </div>
//             <div className={"flex flex-col w-full gap-3"}>
//                 {
//                     verified && codeSent &&
//                     <>
//                         <FormElement name={"password"} type={"password"} autoComplete={"new-password"}
//                                      minLength={6} maxLength={40} required={true} errors={errors}
//                                      value={password} setValue={setPassword} toggleView={true}/>
//                         <FormElement name={"confirm password"} type={"password"} autoComplete={"new-password"}
//                                      minLength={6} maxLength={40} required={true} errors={errors}
//                                      value={confirmPassword} setValue={setConfirmPassword} toggleView={true}/>
//                     </>
//                 }
//                 {
//                     !verified && codeSent && <div className={"flex items-center w-full gap-2 "}>
//                         {
//                             code.map((c, index) =>
//                                 <div key={`code-${index}`} className={"gap-3 w-10 overflow-hidden"}>
//                                     <input name={`code-${index}`} autoComplete={"one-time-code"}
//                                            type={"text"} onKeyDown={(evt) => handleKeys(evt, index)} value={c}
//                                            onChange={evt => handleCode(evt, index)} disabled={loading}
//                                            className={"p-2 text-center border-2 aspect-square flex items-center text-sm rounded-md outline-accLinks w-full"}
//                                            maxLength={1}
//                                     /></div>
//                             )
//                         }
//                     </div>
//                 }
//                 {
//                     !codeSent &&
//                     <FormElement name={"email"} type={"email"} inputClass={"text-sm"} autoComplete={"email"}
//                                  value={email} errors={errors}
//                                  setValue={setEmail} required={true} autoFocus={true}/>
//                 }
//                 {errors.form && <span className={"text-red-400 font-semibold text-xs"}>{errors.form}</span>}

//                 <button type={"submit"} disabled={loading}
//                         className={"w-full px-4 py-3 bg-accentBack text-gray-200 rounded-xl flex items-center justify-center"}>
//                         <span
//                             className={`text-md animate-pulse material-symbols-rounded ${!loading && 'invisible'} absolute`}>more_horiz</span>
//                     <span
//                         className={`text-sm ${loading && 'invisible'}`}>{verified ? 'Change Password' : codeSent ? 'Verify' : 'Send Request'}</span>
//                 </button>
//                 <div className={"w-full flex justify-center"}>
//                     <Link to={"/staging-app/login"}
//                           className={"text-xs text-gray-600 hover:text-black font-medium"}>Back to Login</Link>
//                 </div>
//             </div>
//         </form>
//     </>
// }


// New code 
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FormElement } from "../../components/Input";
import { requestAuthCode, resetPassword, verifyAuthCode, resetPasswordEmailJs } from "../../api/auth";
import SendMail from "../../gmail_service/OtpVerification";

export default function ResetPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [code, setCode] = useState(Array(5).fill(''));
    const [codeSent, setCodeSent] = useState(false);
    const [verified, setVerified] = useState(false);
    const [generatedOtp, setGeneratedOtp] = useState("");

    const generateOtp = () => (Math.floor(10000 + Math.random() * 90000)).toString();

    const requestCode = async (evt) => {
        evt.preventDefault();
        if (!email.trim()) return;

        setLoading(true);
        const otp = generateOtp();
        setGeneratedOtp(otp);

        try {
            await SendMail({ otp, email, setCodeSent });
            setCodeSent(true);
            setErrors({});
        } catch (error) {
            setErrors({ form: "Failed to send OTP. Try again." });
        }

        setLoading(false);
    };

    const verifyCode = async (evt) => {
        console.log("Inside verifyCode")
        evt.preventDefault();
        console.log(code.join(""))
        console.log(email)

        if (code.join("") === generatedOtp && password && password === confirmPassword) {
            setLoading(true);
            try {
                const [status] = await resetPasswordEmailJs({ email: email, password: password });
                if (status === 200) {
                    navigate("/staging-app/reset-success");
                }
                else if (status === 404) {
                    setErrors({ form: "User not found." });
                }
                else {
                    setErrors({ form: "Something went wrong. Try again." });
                }
            } catch (error) {
                setErrors({ form: "Activation failed. Try again." });
            }
            setLoading(false);
        } else {
            setErrors({ email: "Invalid code. Please try again." });
        }
    };

    const changePassword = (evt) => {
        evt.preventDefault();
        if (evt.target.reportValidity() &&
            code.filter(c => typeof c === "number" && !isNaN(c)).length === 5 &&
            codeSent && email) {
            if (password === confirmPassword) {
                setLoading(true);
                resetPassword({ email, code: code.join(""), password })
                    .then(([status, _]) => {
                        switch (status) {
                            case 200: {
                                navigate("/staging-app/reset-success");
                                break;
                            }
                            case 401: {
                                setErrors({ form: "Invalid code" });
                                break;
                            }
                            case 404: {
                                setErrors({ form: "User doesn't exist" });
                                break;
                            }
                            default: {
                                setErrors({ form: "Request failed, try again in some time" });
                            }
                        }
                    }).finally(() => setLoading(false));
            } else {
                setErrors({ form: "Password don't match" })
            }
        }
    }

    const handleKeys = (evt, index) => {
        if (evt.key === "Backspace") {
            const el = document.querySelector(`input[name=code-${Math.max(evt.target.value === "" ? index - 1 : index, 0)}]`);
            if (el) {
                let k = [...code];
                k[index] = undefined;
                setCode(k);
                el.focus();
            }
        }
    }

    const handleCode = (evt, index) => {
        const val = Number.parseInt(evt.target.value);
        if (!isNaN(val)) {
            let k = [...code];
            k[index] = val;
            setCode(k);
            if (index < 4)
                document.querySelector(`input[name=code-${index + 1}]`)?.focus();
        } else evt.target.value = "";
    }

    const clear = () => {
        setVerified(false);
        setCodeSent(false);
        setCode(code.map(_ => null));
        setEmail("");
        setPassword("");
        setConfirmPassword("");
    }

    return <>
        <Helmet title={"Reset Password | Nimbey"} />
        <form onSubmit={verified ? changePassword : codeSent ? verifyCode : requestCode}
            className={"flex flex-col card w-full shadow gap-2"}>
            <div className={"flex flex-col w-full"}>
                {codeSent && <span className={"text-4xl font-extrabold tracking-widest w-full text-left"}>*****</span>}
                {/*{!codeSent && <img src={icPassword} alt={"password-illustration"} className={"w-12"}/>}*/}
                {!codeSent && <span className={"text-lg font-bold"}>Reset Password</span>}
                {
                    verified ?
                        <p className={`text-gray-800 w-full text-sm`}>Please enter a new password for <br />
                            <button onClick={clear}
                                className={"flex items-center gap-2 font-semibold"}>{email}
                                <span className={"material-symbols-rounded text-sm"}>edit</span></button>
                        </p>
                        :
                        codeSent ?
                            <p className={`text-gray-800 w-full text-sm`}>Please enter the one time password sent
                                to <br />
                                <button onClick={clear} type={"button"}
                                    className={"flex items-center gap-2 font-semibold"}>{email}
                                    <span className={"material-symbols-rounded text-sm"}>edit</span></button>
                            </p>
                            :
                            <p className={"text-gray-800 w-full text-xs"}>Please enter the email address associated with
                                your account, and we'll email you a link to reset your password.</p>
                }
            </div>
            <div className={"flex flex-col w-full gap-3"}>
                {
                    verified && codeSent &&
                    <>
                        <FormElement name={"password"} type={"password"} autoComplete={"new-password"}
                            minLength={6} maxLength={40} required={true} errors={errors}
                            value={password} setValue={setPassword} toggleView={true} />
                        <FormElement name={"confirm password"} type={"password"} autoComplete={"new-password"}
                            minLength={6} maxLength={40} required={true} errors={errors}
                            value={confirmPassword} setValue={setConfirmPassword} toggleView={true} />
                    </>
                }
                {
                    !verified && codeSent && (
                        <div className={"flex flex-col items-center w-full gap-2"}>
                            {/* Code Inputs in a Single Row */}
                            <div className={"flex flex-row gap-3"}>
                                {code.map((c, index) => (
                                    <div key={`code-${index}`} className={"w-10 overflow-hidden"}>
                                        <input
                                            name={`code-${index}`}
                                            autoComplete={"one-time-code"}
                                            type={"text"}
                                            onKeyDown={(evt) => handleKeys(evt, index)}
                                            value={c}
                                            onChange={(evt) => handleCode(evt, index)}
                                            disabled={loading}
                                            className={"p-2 text-center border-2 aspect-square flex items-center text-sm rounded-md outline-accLinks w-full"}
                                            maxLength={1}
                                        />
                                    </div>
                                ))}
                            </div>

                            {/* Password Inputs in Column Layout */}
                            <FormElement
                                name={"password"}
                                type={"password"}
                                autoComplete={"new-password"}
                                minLength={6}
                                maxLength={40}
                                required={true}
                                errors={errors}
                                value={password}
                                setValue={setPassword}
                                toggleView={true}
                            />
                            <FormElement
                                name={"confirm password"}
                                type={"password"}
                                autoComplete={"new-password"}
                                minLength={6}
                                maxLength={40}
                                required={true}
                                errors={errors}
                                value={confirmPassword}
                                setValue={setConfirmPassword}
                                toggleView={true}
                            />
                        </div>
                    )
                }

                {
                    !codeSent &&
                    <FormElement name={"email"} type={"email"} inputClass={"text-sm"} autoComplete={"email"}
                        value={email} errors={errors}
                        setValue={setEmail} required={true} autoFocus={true} />
                }
                {errors.form && <span className={"text-red-400 font-semibold text-xs"}>{errors.form}</span>}

                <button type={"submit"} disabled={loading}
                    className={"w-full px-4 py-3 bg-accentBack text-gray-200 rounded-xl flex items-center justify-center"}>
                    <span
                        className={`text-md animate-pulse material-symbols-rounded ${!loading && 'invisible'} absolute`}>more_horiz</span>
                    <span
                        className={`text-sm ${loading && 'invisible'}`}>{verified ? 'Change Password' : codeSent ? 'Verify' : 'Send Request'}</span>
                </button>
                <div className={"w-full flex justify-center"}>
                    <Link to={"/staging-app/login"}
                        className={"text-xs text-gray-600 hover:text-black font-medium"}>Back to Login</Link>
                </div>
            </div>
        </form>
    </>
}