import React from "react";
import { motion } from "framer-motion";
import combinedImage from "../assets_landingpage/Integrations.png";

const Integrations = ({
  title = "Integrations",
  description = "Integrate your TMS, ERP, portals, email, financial tools with Nimbey to manage your supply chain data from one platform.",
  imageSrc = combinedImage, // Default image path
  titleColor = "text-black",
  descriptionColor = "text-gray-600",
}) => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.7,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.section
      id="integrations"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
      variants={containerVariants}
      className="text-center py-16 px-6 my-20"
    >
      {/* Title Section */}
      <motion.h2
        variants={itemVariants}
        className={`font-poppins text-xl md:text-4xl text-black font-semibold text-center mb-12 ${titleColor}`}
      >
        {title}
      </motion.h2>

      {/* Description Section */}
      <motion.p
        variants={itemVariants}
        className={`font-public-sans font-medium ${descriptionColor} text-sm md:text-xl text-gray-600 text-center mb-16 max-w-3xl mx-auto`}
      >
        {description}
      </motion.p>

      {/* Image Section for All Integrations */}
      <motion.div
        className="flex max-w-10xl justify-center items-center"
        variants={imageVariants}
      >
        <img
          src={imageSrc}
          loading="lazy"
          alt="Integrations"
          className={`w-auto h-auto`} // Dynamic width from props
        />
      </motion.div>
    </motion.section>
  );
};

export default Integrations;