function Brand({data, chat=false}) {
    return <div className={`flex flex-col md:items-stretch w-full ${chat ? 'xl:min-w-[308px] 2xl:max-w-screen-xs' : 'xl:max-w-screen-xs 2xl:max-w-screen-sm'}  card p-0`}>
        <div className={"flex items-center gap-2 p-4 pb-0"}>
            <span className={"material-symbols-rounded text-md"}>drag_indicator</span>
            <span className={"text-sm font-semibold"}>Company Information</span>
        </div>
        <div className={"flex flex-col gap-2 p-4"}>
            <div className={"flex items-center gap-2"}>
                <img src={data.profile.image_url} alt={data.profile.name} className={"max-w-12"}/>
                <div className={"flex flex-1 flex-col whitespace-nowrap"}>
                    <span className={"font-bold text-sm"}>{data.profile.name}</span>
                    <span className={"text-xs"}>{data.profile.country}</span>
                </div>
            </div>
            <p className={"text-xs"}>{data.profile.description}</p>
        </div>
    </div>
}

export default Brand;