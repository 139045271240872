import React from "react";
import { motion } from "framer-motion";
import nimbeyImage from "../assets_landingpage/nimbey_ai_image.svg";
import emailIcon from "../assets_landingpage/icons/emailIcon.svg";
import shieldIcon from "../assets_landingpage/icons/shieldIcon.svg";
import recycleIcon from "../assets_landingpage/icons/recycleIcon.svg";

const DraftClaimsWithAI = ({
  mainHeading = "Draft claims with AI",
  mainDescription = "Nimbey AI leverages can gather evidence and draft emails for deduction claims. Our analytics-based response helps you draft claims so you can win more disputes.",
  feature1Text = "AI automatically attaches relevant documentation and explanation on why the deduction is incorrect.",
  feature2Text = "Draft claim quickly based on deduction code.",
  feature3Text = "Human in the loop, Deduction experts review claims before they are sent out.",
  buttonText = "Learn More",
  imageSrc = nimbeyImage,
  feature1Icon = emailIcon,
  feature2Icon = shieldIcon,
  feature3Icon = recycleIcon,
}) => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
      variants={containerVariants}
      className="w-11/12 flex flex-col items-center justify-center"
    >
      <motion.div className="flex flex-col-reverse md:flex-row w-full items-center md:items-start gap-[3.37%]">
        <motion.div className="w-full md:w-[35.95%] ml-10">
          <motion.img
            src={imageSrc}
            alt="Nimbey AI"
            className="h-auto w-full md:h-[473px] object-cover"
          />
        </motion.div>

        <motion.div className="w-full md:w-[54.68%] flex flex-col items-center md:items-start md:pt-[5%] md:pl-[5px]">
          <motion.h2
            variants={itemVariants}
            className="text-[26px] md:text-[36px] font-semibold text-gray-800 mb-4 text-center md:text-left font-poppins"
          >
            {mainHeading}
          </motion.h2>
          <motion.p
            variants={itemVariants}
            className="text-[16px] md:text-[18px] text-[#131618] mb-6 text-center md:text-left font-publicSans"
          >
            {mainDescription}
          </motion.p>

          {/* Features Section */}
          <motion.div className="flex flex-col items-start mt-5 md:flex-row gap-4 w-full">
            {/* Feature 1 */}
            <motion.div
              variants={itemVariants}
              className="flex flex-col items-start space-y-3"
            >
              <img src={feature1Icon} alt="Feature 1 Icon" className="w-[26px] h-[26px]" />
              <span className="text-left text-[#212B36] text-[14px] font-semibold">
                {feature1Text}
              </span>
            </motion.div>

            {/* Feature 2 */}
            <motion.div
              variants={itemVariants}
              className="flex flex-col items-start space-y-3"
            >
              <img src={feature2Icon} alt="Feature 2 Icon" className="w-[26px] h-[26px]" />
              <span className="text-left text-[#212B36] text-[14px] font-semibold">
                {feature2Text}
              </span>
            </motion.div>

            {/* Feature 3 */}
            <motion.div
              variants={itemVariants}
              className="flex flex-col items-start space-y-3"
            >
              <img src={feature3Icon} alt="Feature 3 Icon" className="w-[26px] h-[26px]" />
              <span className="text-left text-[#212B36] text-[14px] font-semibold">
                {feature3Text}
              </span>
            </motion.div>
          </motion.div>

          {/* Learn More Button */}
          <div className={`w-full mt-4`}>
            <button onClick={() => handleScrollTo("howitworks")} className="inline-flex items-center justify-between text-black bg-transparent border border-[#E4E4E4] px-4 py-2 rounded-md transition-all duration-300 hover:border-green-500 focus:outline-none">
              <span>{buttonText}</span>
              <span className="font-bold text-black">&rarr;</span>
            </button>
          </div>
        </motion.div>
      </motion.div>
    </motion.section>
  );
};

export default DraftClaimsWithAI;
