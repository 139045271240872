import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components_landingpage/Navbar";
import FooterLandingPage from "./components_landingpage/FooterLandingPage";
import Home from "./pages_landingpage/Home";
import AP from "./pages_landingpage/AP"
import './landingpage.css' 


const HowItWorks = () => (
  <h2 className="text-center mt-16">How it works Page</h2>
);
const Pricing = () => <h2 className="text-center mt-16">Pricing Page</h2>;
const FAQs = () => <h2 className="text-center mt-16">FAQs Page</h2>;

const LandingPage = () => {
  return (

      <div className="flex flex-col w-[100vw] min-h-screen overflow-hidden scroll-container">
        {/* Navbar should not push footer */}
        <Navbar />

        {/* Main Content without any extra margins or padding */}
        <div className="flex-grow flex items-center justify-center">
          {/* Wrap Routes with React.Suspense */}
          <React.Suspense
            fallback={<div className="text-center mt-16">Loading...</div>}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/ap" element={<AP />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/faqs" element={<FAQs />} />
            </Routes>
          </React.Suspense>
        </div>

        {/* Footer without any top margin or padding */}
        <FooterLandingPage />
      </div>
  
  );
};

export default LandingPage;
