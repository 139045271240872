import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Input from "../../components/Input";
import {getCategories, newItemOrder} from "../../api/dashboard";
import {connect} from "react-redux";

function NewItemOrder({locations}) {

    const [data, setData] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        getCategories().then(([status, data]) => {
            if (status === 200) {
                setCategoryList(data);
            }
        });
    }, []);

    const handleChange = (evt, name = "") => {
        const state = data;
        if (evt.target.name)
            state[evt.target.name] = evt.target.value;
        else
            state[name] = evt.target.value;
        setData(state);
    }

    const handleSubmit = (evt) => {
        setErrors({});
        evt.preventDefault();
        if (evt.target.reportValidity()) {
            newItemOrder({
                supplier_name: data.supplier_name,
                product_description: data.product_description,
                category_id: data.category,
                location_id: data.location,
                quantity: data.quantity,
                deliver_by: data.deliver_by,
                request_info: data.request_info
            }).then(([status, data]) => {
                if (status === 200) {
                    navigate("/staging-app/dashboard/success", {
                        state: {
                            message: "Request submitted successfully!",
                            ref: window.location.href
                        }
                    });
                } else {
                    setErrors({...errors, form: `Request failed, ${data}`})
                }
            })
        }
    }

    const date = new Date();
    const today = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`

    return (
        <>
            <div className={"flex flex-col w-full gap-4 h-full"}>

                <div className={"flex items-center justify-between"}>
                    <span className={"flex text-xl gap-1 w-full"}><b
                        className={"font-semibold"}>New Item</b> Order</span>
                </div>
                <form
                    className={"flex flex-col gap-4 w-full max-w-[600px] rounded-md border p-4 md:p-6 card items-stretch"}
                    onSubmit={handleSubmit}>

                    {
                        errors.form &&
                        <span className={"block my-2 font-medium text-xs text-red-500"}>{errors.form}</span>
                    }

                    <Input onChange={handleChange} value={data.supplier_name} id={"supplier_name"}
                           inpStyle={"text-xs"} errors={errors.supplier_name}
                           type={"text"} name={"supplier_name"} required={true} label={"Name of Supplier"}/>

                    <Input onChange={handleChange}
                           value={data.product_description} id={"product_description"}
                           errors={errors.product_description}
                           type={"text"} name={"product_description"} required={true} inpStyle={"text-xs"}
                           label={"Product Description"}/>

                    <div className={"flex flex-1 flex-col pb-2"}>
                        <span className={"mb-1 text-xs"} id="location-label">Choose Category</span>
                        <Input type={"select"} required={true} id={"category"} name={"category"}
                               value={data.category} inpStyle={"text-xs"} endProp={<span
                                           className={"material-symbols-rounded text-lg "}>expand_more</span>}
                               onChange={handleChange} errors={errors.category}
                               options={categoryList.map(cl => ({id: cl.category_id, name: cl.cate}))}/>
                    </div>
                    <Input min={1} onChange={handleChange} value={data.quantity} inpStyle={"text-xs"}
                           errors={errors.quantity}
                           id={"quantity"} type={"number"} name={"quantity"} required={true} label={"Quantity"}/>

                    <div className={"flex flex-col flex-1 pb-2"}>
                        <span className={"mb-1 text-xs"}>When do you require this by?</span>
                        <Input type={"date"} required={true} min={today} id={"deliver_by"} name={"deliver_by"}
                               inpStyle={"text-xs"}
                               errors={errors.deliver_by}
                               onChange={handleChange}/>
                    </div>

                    <div className={"flex flex-col pb-2 flex-1"}>
                        <span className={"mb-1 text-xs"} id="location-label">Choose Location</span>
                        <Input type={"select"} required={true} name={"location"} value={data.location}
                               inpStyle={"text-xs"} endProp={<span
                                           className={"material-symbols-rounded text-lg "}>expand_more</span>}
                               onChange={handleChange} id={"location"} errors={errors.location}
                               options={locations.map(l => ({id: l.id, name: `${l.city}, ${l.state}`}))}/>
                    </div>
                    <Input onChange={handleChange} id={"request_info"}
                           value={data.request_info} inpStyle={"text-xs"} errors={errors.request_info}
                           type={"text"} name={"request_info"} required={true} label={"Request Information"}/>
                    <div className={"flex flex-col justify-end items-end w-full"}>
                        <button
                            className={"rounded-md px-2 py-3 h-fit w-[180px] bg-nimbey mt-4 text-black font-semibold text-sm capitalize"}
                            type={'submit'}>
                            Request Approval
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default connect(state => ({locations: state.userData.locations}))(NewItemOrder);