// import { useEffect, useState, useRef } from "react";
// import InvoiceUploadModal from "../../../components/InvoiceUploadModal";
// import { SiQuickbooks } from "react-icons/si";
// import {
//   adminInvoices,
//   analysisDetails,
//   deleteAnalysis,
//   invoiceStatus,
//   // getInvoicesFromEmail,
//   quickbooks,
//   updateInvoiceStatus,
// } from "../../../api/dashboard";
// import Notify from "../../../components/Notification";
// import AnalysisSideBar from "../../../components/AnalysisSideBar";
// import { DocumentDownload } from "../../../components/DropDown";
// import AttachmentSidebar from "../../../components/AttachmentSideBar.jsx";
// import { useNavigate } from 'react-router-dom';
// import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../components/ui/tooltip.jsx";
// import InvoiceWidgets from "./Widgets_Invoice_page.jsx";

// function AdminInvoiceAnalysis() {
//   // const { chat } = useOutletContext();
//   const [invoices, setInvoices] = useState([]);
//   const [loader, setLoader] = useState(false);
//   const [describeAnalysis, setDescribeAnalysis] = useState(null);
//   const initialLoadDone = useRef(false);

//   // Pratham's code
//   // const email = localStorage.getItem("email");
//   const [attachments, setAttachments] = useState([]);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const navigate = useNavigate();

//   const [openDropdown, setOpenDropdown] = useState(null);
//   const [showModal, setShowModal] = useState(false);

//   // Add these helper functions to manage dropdown states
//   const closeDropdown = (invoiceId) => {
//     setOpenDropdown(prev => ({
//       ...prev,
//       [invoiceId]: false
//     }));
//   };

//   const toggleDropdown = (invoiceId) => {
//     // If the clicked dropdown is already open, close it; otherwise, open it
//     setOpenDropdown(prev => (prev === invoiceId ? null : invoiceId));
//   };

//   const statusOptions = [
//     { value: 'Pending', color: 'bg-yellow-100 text-yellow-600' },
//     { value: 'Reviewed', color: 'bg-purple-100 text-purple-600' },
//     { value: 'Accepted', color: 'bg-lime-100 text-lime-600' },
//     { value: 'Paid', color: 'bg-blue-100 text-blue-600' },
//     { value: 'Rejected', color: 'bg-red-100 text-red-600' }
//   ];

//   // Update the handleStatusChange function
//   const handleStatusChange = async (invoiceId, newStatus) => {
//     setInvoices(prevInvoices =>
//       prevInvoices.map(invoice =>
//         invoice.invoice_number === invoiceId
//           ? { ...invoice, status: newStatus }
//           : invoice
//       )
//     );
//     try {
//       const type = 'inv';
//       const payload = {invoiceId, newStatus, type};
//       updateInvoiceStatus(payload);  // nakshatra - this is the new status change function
//       // invoiceStatus(payload);
//       // setStatus(newStatus);
//       // After successful update, update the invoice status in the local state
//       // setInvoices(prevInvoices =>
//       //   prevInvoices.map(invoice =>
//       //     invoice.invoice_id === invoiceId
//       //       ? { ...invoice, status: newStatus }
//       //       : invoice
//       //   )
//       // );
//       // getInvoices();
//       closeDropdown(invoiceId);
//     } catch (error) {
//       console.error('Error updating status:', error);
//     }
//   };

//   useEffect(() => {
//     const queryParams = new URLSearchParams(window.location.search);
//     const code = queryParams.get("code");
//     const state = queryParams.get("state");

//     if (code && state) {
//       console.log("Google OAuth callback:", code, "\nstate:", state);

//       // Call the /google-callback endpoint
//       fetch(`${process.env.REACT_APP_API_URL}/api/google-callback?code=${code}&state=${state}`)
//         .then(response => {
//           if (!response.ok) {
//             // Handle error responses
//             return response.text(); // In case the response is not JSON
//           }
//           return response.json();  // Parse as JSON if the response is okay
//         })
//         .then(data => {
//           if (typeof data === 'string') {
//             console.error("Server error:", data);
//             Notify("Authentication failed", "error");
//             return;
//           }

//           if (data.success) {
//             Notify("Authentication successful", "success");
//           } else {
//             Notify("Authentication failed", "error");
//           }
//         })
//         .catch(error => {
//           console.error("Error during callback:", error);
//           Notify("Authentication failed", "error");
//         });
//     }
//   }, []);

//   const getInvoices = (isInitialLoad = false) => {
//     setLoader(true);
//     adminInvoices()
//       .then(([status, response]) => {
//         if (status === 200 && Array.isArray(response)) {
//           const uniqueInvoices = new Map();
//           response.forEach((invoice) => {
//             const currentInvoice = uniqueInvoices.get(invoice.invoice_number);
//             const currentDate = new Date(
//               invoice.created_at || invoice.invoice_date || new Date()
//             );
//             if (
//               !currentInvoice ||
//               new Date(currentInvoice.created_at) < currentDate
//             ) {
//               // Ensure docs array is properly formatted
//               const formattedDocs =
//                 invoice.documents?.map((doc) => ({
//                   id: doc.id,
//                   s3_key: doc.s3_key,
//                   name: doc.s3_key.split("/").pop(), // Get filename from s3_key
//                 })) || [];
//               uniqueInvoices.set(invoice.invoice_number, {
//                 ...invoice,
//                 created_at:
//                   invoice.created_at ||
//                   invoice.invoice_date ||
//                   new Date().toISOString(),
//                 docs: formattedDocs, // Add formatted docs
//               });
//             }
//           });
//           const formattedInvoices = Array.from(uniqueInvoices.values()).map(
//             (invoice) => ({
//               invoice_id: invoice.id || "N/A",
//               invoice_number: invoice.invoice_number || "N/A",
//               total_amount: invoice.total_amount || "$0.00",
//               payment_due_date: invoice.payment_due_date || "N/A",
//               created_at: invoice.created_at,
//               status: invoice.status || "N/A",
//               billed_to_name: invoice.billed_to_name || "N/A",
//               summary: invoice.summary || null,
//               recoverable_amount: invoice.recoverable_amount || "N/A",
//               doc_id: invoice.summary_id || "",
//               table_data: invoice.table_data || [{}],
//             })
//           );
//           console.log("formattedInvoices: ", formattedInvoices);
//           setInvoices(formattedInvoices);

//           if (!initialLoadDone.current && isInitialLoad) {
//             initialLoadDone.current = true;
//           }
//         } else {
//           console.error("Invalid response format:", response);

//           setInvoices([]);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching invoices:", error);

//         setInvoices([]);
//       })
//       .finally(() => setLoader(false));
//   };

//   const handleDelete = (invoice_id) => {
//     if (!invoice_id) {
//       Notify("Invalid invoice ID", "error");
//       return;
//     }
//     deleteAnalysis(invoice_id)
//       .then(([status, data]) => {
//         if (status === 200) {
//           getInvoices(false);
//           Notify("Invoice deleted successfully", "success");
//         } else {
//           Notify("Failed to delete invoice", "error");
//         }
//       })
//       .catch((error) => {
//         console.error("Error deleting invoice:", error);
//         Notify("Error deleting invoice", "error");
//       });
//   };

//   const handleMarkResolved = async (event, invoice) => {
//     event.stopPropagation();
//     try {
//       Notify("Uploading to Quickbooks", "info");
//       const response = await quickbooks({ invoice_id: invoice.invoice_id });
//       console.log("Processed response:", response); // Debug log

//       if (response.status === 200) {
//         Notify(
//           `Invoice No.${invoice.invoice_number} Uploaded to Quickbooks`,
//           "success"
//         );
//       } else if (response.status === 404) {
//         Notify("Invoice already exists in QuickBooks", "success");
//       } else {
//         Notify("Failed to upload to QuickBooks", "error");
//       }
//     } catch (error) {
//       console.error("QuickBooks Error:", error);
//       Notify("Failed to upload to QuickBooks", "error");
//     }
//   };

//   useEffect(() => {
//     getInvoices(true);
//     return () => {
//       initialLoadDone.current = false;
//     };
//   }, []);

//   if (loader) {
//     return (
//       <div className="flex w-full items-center justify-center flex-1">
//         <span className="w-full text-center block">
//           <span className="material-symbols-rounded animate-spin">
//             progress_activity
//           </span>
//         </span>
//       </div>
//     );
//   }

//   const renderTableHeader = () => (
//     <div className="rounded-md mt-4 w-full hidden lg:flex items-center justify-between bg-gray-100 border mb-4">
//       <div className="flex w-full p-4 space-x-2">
//         <span className="w-1/4 font-semibold text-sm text-gray-600">
//           Invoice Number
//         </span>
//         <span className="w-1/4 font-semibold text-sm text-gray-600">
//           Billed To
//         </span>
//         <span className="w-1/6 font-semibold text-sm text-gray-600">
//           Total Amount
//         </span>
//         <span className="w-1/6 font-semibold text-sm text-gray-600">
//           Due Date
//         </span>
//         <span className="w-1/4 font-semibold text-sm text-gray-600">
//           Status
//         </span>
//         <span className="w-1/6 text-right font-semibold text-sm text-gray-600">
//           Actions
//         </span>
//       </div>

//     </div>
//   );

//   const renderMobileCard = (invoice) => (
//     <div className="flex flex-col p-4 border rounded-md mb-4 bg-white shadow-sm">
//       <div className="flex justify-between items-start mb-3">
//         <div className="flex items-center gap-2">
//           <span className="material-symbols-rounded text-gray-200">
//             receipt_long
//           </span>
//           <span
//             onClick={() => setDescribeAnalysis(invoice.invoice_number)}
//             className="font-medium cursor-pointer hover:text-blue-600 hover:underline"
//           >
//             Invoice: {invoice.invoice_number}
//           </span>
//         </div>
//         <div className="flex gap-2">
//           <button
//             title="Mark as resolved"
//             onClick={(e) => handleMarkResolved(e, invoice)}
//             className="material-symbols-rounded text-green-500 hover:text-green-600 text-xl"
//           >
//             check
//           </button>
//           <button
//             onClick={() => handleDelete(invoice.invoice_id)}
//             className="material-symbols-rounded text-gray-600 hover:text-gray-700 text-xl"
//             title="Delete"
//           >
//             delete
//           </button>
//           <DocumentDownload invoice={invoice} />
//         </div>
//       </div>

//       <div className="grid grid-cols-2 gap-y-2 text-sm">
//         <div className="text-gray-600">Billed To:</div>
//         <div className="font-medium">{invoice.billed_to_name}</div>

//         <div className="text-gray-600">Total Amount:</div>
//         <div className="font-semibold">{invoice.total_amount}</div>

//         <div className="text-gray-600">Due Date:</div>
//         <div className="font-medium">{invoice.payment_due_date}</div>

//         <div className="text-gray-600">Status:</div>
//         <div className="font-medium">{invoice.status}</div>
//       </div>
//     </div>
//   );

//   // COMMENT THIS BEFORE PUSHING
//   // const handleViewAttachments = async () => {
//   //   // Mock attachment data
//   //   const mockAttachments = [
//   //     {
//   //       s3_key: "attachments/invoice1.pdf",
//   //       filename: "invoice1.pdf",
//   //       created_at: new Date("2025-01-23").toLocaleDateString("en-US", {
//   //         year: 'numeric',
//   //         month: 'long',
//   //         day: 'numeric'
//   //       }),
//   //       size: "1.2 MB"
//   //     },
//   //     {
//   //       s3_key: "attachments/invoice2.pdf",
//   //       filename: "invoice2.pdf",
//   //       created_at: new Date("2025-01-22").toLocaleDateString("en-US", {
//   //         year: 'numeric',
//   //         month: 'long',
//   //         day: 'numeric'
//   //       }),
//   //       size: "850 KB"
//   //     },
//   //     {
//   //       s3_key: "attachments/receipt1.pdf",
//   //       filename: "receipt1.pdf",
//   //       created_at: new Date("2025-01-21").toLocaleDateString("en-US", {
//   //         year: 'numeric',
//   //         month: 'long',
//   //         day: 'numeric'
//   //       }),
//   //       size: "500 KB"
//   //     }
//   //   ];    

//   //   setAttachments(mockAttachments);
//   //   setIsSidebarOpen(true);
//   // };

//   //  UNCOMMENT THIS BEFORE PUSHING
//   const handleViewAttachments = async () => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/connect-gmail`);

//       if (!response.ok) {
//         // Handle non-OK responses (e.g., 500 error)
//         const errorText = await response.text();  // Read the plain text in case of error
//         console.error("Server error:", errorText);
//         Notify("Error initiating authentication", "error");
//         return;
//       }

//       const data = await response.json();

//       if (data.success) {
//         if (data.auth_url) window.location.href = data.auth_url;
//         else {
//           handleFetchAttachments();
//         }
//         // Redirect user to the Google authentication URL
//       } else {
//         Notify(data.message || "Failed to initiate authentication", "error");
//       }
//     } catch (error) {
//       console.error("Error initiating authentication:", error);
//       Notify("Error initiating authentication", "error");
//     }
//   };

//   // gmail api call
//   const handleFetchAttachments = async () => {
//     try {
//       Notify("Fetching email attachments...");
//       setIsSidebarOpen(true); // Open the sidebar
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/fetch-and-upload-pdfs`); // Adjust the URL as needed
//       if (!response.ok) {
//         Notify("Failed to fetch attachments", "error");
//         return;
//       }

//       const data = await response.json(); // Parse the JSON response
//       // console.log("Attachments response:", data);

//       if (data.success) {
//         if (!data.data || data.data.length === 0) {
//           Notify("No PDF attachments found", "error");
//           setIsSidebarOpen(false); // Open the sidebar
//         } else {
//           const attachmentsWithUrls = data.data.map((item) => ({
//             s3_key: item.s3_key,
//             filename: item.s3_key.split("/").pop(),
//             email_date: item.email_date,  // Include email_date
//           }));
//           setAttachments(attachmentsWithUrls); // Update state with the processed s3_keys
//           Notify("Email attachments fetched successfully", "success");
//         }
//       } else {
//         setIsSidebarOpen(false); // Open the sidebar
//         Notify(data.error || "Failed to fetch attachments", "error");
//       }
//     } catch (error) {
//       console.error("Error fetching attachments:", error);
//       Notify("Error fetching attachments", "error");
//     }
//   };

//   const renderAttachmentsSidebar = () => (
//     <div className="attachments-sidebar">
//       {attachments.length > 0 ? (
//         attachments.map((attachment) => (
//           <div
//             key={attachment.id}
//             className="attachment-item flex items-center justify-between p-2 border-b"
//           >
//             <div>
//               <p className="text-sm font-medium">{attachment.filename}</p>
//               <p className="text-xs text-gray-500">
//                 From: {attachment.sender_email} <br />
//                 Date: {new Date(attachment.email_date).toLocaleString()}
//               </p>
//             </div>
//             <a
//               href={attachment.s3_url}
//               target="_blank"
//               rel="noopener noreferrer"
//               className="text-blue-500 text-sm"
//             >
//               View
//             </a>
//           </div>
//         ))
//       ) : (
//         <p className="text-gray-500 text-sm">No attachments available</p>
//       )}
//     </div>
//   );

//   return (
//     <div className="flex flex-col items-start w-full gap-2 rounded-md relative flex-1 px-4 py-0">
//       <div className="px-10 flex justify-end gap-4 w-full">
//         <TooltipProvider>
//           <Tooltip>
//             <TooltipTrigger>
//               <button className="material-symbols-rounded w-10 h-10 bg-accentBack flex-shrink-0 rounded-xl border font-normal text-white transition-all duration-100 ease-in-out hover:bg-[#A3E635] focus:outline-none focus:ring-2 focus:ring-[#A3E635] focus:ring-offset-2"
//                 onClick={() => navigate('/staging-app/dashboard/admin/anaylsis_dashboard')}
//               >
//                 space_dashboard
//               </button>
//             </TooltipTrigger>
//             <TooltipContent>
//               <p className="bg-accentBack text-white rounded-full px-2 py-1">Dashboard</p>
//             </TooltipContent>
//           </Tooltip>
//         </TooltipProvider>

//         <TooltipProvider>
//           <Tooltip>
//             <TooltipTrigger>
//               <button className="material-symbols-rounded w-10 h-10 flex-shrink-0 rounded-xl bg-accentBack font-normal text-white transition-all duration-100 ease-in-out hover:bg-[#A3E635] focus:outline-none focus:ring-2 focus:ring-[#A3E635] focus:ring-offset-2"
//                 onClick={handleViewAttachments}
//               >
//                 attach_file_add
//               </button>
//             </TooltipTrigger>
//             <TooltipContent>
//               <p className="bg-accentBack text-white rounded-full px-2 py-1">Fetch attachments from mail</p>
//             </TooltipContent>
//           </Tooltip>
//         </TooltipProvider>

//         <TooltipProvider>
//           <Tooltip>
//             <TooltipTrigger>
//               <button className="material-symbols-rounded w-10 h-10 flex-shrink-0 rounded-xl bg-accentBack font-normal text-white transition-all duration-100 ease-in-out hover:bg-[#A3E635] focus:outline-none focus:ring-2 focus:ring-[#A3E635] focus:ring-offset-2"
//                 onClick={() => setShowModal(!showModal)}
//               >
//                 upload_file
//               </button>
//             </TooltipTrigger>
//             <TooltipContent>
//               <p className="bg-accentBack text-white rounded-full px-2 py-1">Upload files</p>
//             </TooltipContent>
//           </Tooltip>
//         </TooltipProvider>
//       </div>

//       {showModal && (
//         <InvoiceUploadModal
//           getInvoices={() => getInvoices(false)}
//           onUploadStart={() => Notify("Uploading...", "info")}
//           onClose={() => setShowModal(false)} // Close modal when needed
//         />
//       )}

//       <div className="w-full h-[400px] rounded-lg hidden md:block py-4 overflow-hidden -my-2">
//         <InvoiceWidgets />
//       </div>

//       <div className="card flex flex-col w-full h-full -my-2">


//         <div className="w-full flex flex-col">
//           {/* Desktop Table Header */}
//           {renderTableHeader()}

//           {/* Responsive Content */}
//           <div className="w-full">
//             {invoices.length > 0 ? (
//               <>
//                 {/* Desktop View */}
//                 <div className="hidden lg:flex flex-col w-full gap-2">
//                   {invoices
//                     .sort((a, b) => {
//                       try {
//                         return new Date(b.created_at) - new Date(a.created_at);
//                       } catch (e) {
//                         return 0;
//                       }
//                     })
//                     .map((invoice) => {
//                       return (
//                         <div
//                           key={`desktop-${invoice.invoice_number}`}
//                           className="flex items-center w-full border rounded-md hover:bg-gray-50"
//                         >
//                           <div className="flex items-center flex-[4] p-4 space-x-3">
//                             <div className="w-1/4 flex items-center gap-1">
//                               <span className="material-symbols-rounded text-gray-600">receipt_long</span>
//                               <span
//                                 onClick={() => setDescribeAnalysis(invoice.invoice_number)}
//                                 className="font-medium cursor-pointer hover:text-blue-600 hover:underline"
//                               >
//                                 Invoice: {invoice.invoice_number}
//                               </span>
//                             </div>
//                             <span className="w-1/4 text-left pr-2 font-medium">
//                               {invoice.billed_to_name}
//                             </span>
//                             <span className="w-1/6 font-semibold text-left">$ {invoice.total_amount}</span>
//                             <span className="w-1/6 text-left text-gray-600 font-medium">{invoice.payment_due_date}</span>

//                             {/* Editable Dropdown for Status */}
//                             <div className="relative w-1/4">
//                               <div className="relative">
//                                 <button
//                                   onClick={() => toggleDropdown(invoice.invoice_id)}
//                                   className={`w-full border flex items-center justify-between px-2 py-1.5 rounded-t-lg bg-white text-gray-700`} // Default styles for button
//                                 >
//                                   <span className={`text-sm font-medium w-fit flex items-center px-2 py-0.5 rounded-full ${statusOptions.find(opt => opt.value === invoice.status)?.color || 'text-gray-700'}`}>
//                                     ○ {invoice.status}
//                                   </span>
//                                   <span className="material-symbols-rounded text-current text-sm">
//                                     expand_more
//                                   </span>
//                                 </button>

//                                 {openDropdown === invoice.invoice_id && (
//                                   <div className="absolute flex flex-col space-y-2 z-10 mt-1 w-full bg-white border rounded-b-lg shadow-md py-1">
//                                     {statusOptions.map((option) => (
//                                       <button
//                                         key={option.value}
//                                         onClick={() => {
//                                           handleStatusChange(invoice.invoice_number, option.value);
//                                           // setOpenDropdown(null); // close dropdown after selection
//                                         }}
//                                         className={`w-fit text-left px-2 py-0 hover:bg-gray-200 ${option.color} mx-2 rounded-full text-sm font-medium`}
//                                       >
//                                         ● {option.value}
//                                       </button>
//                                     ))}
//                                   </div>
//                                 )}
//                               </div>
//                             </div>
//                             <div className="w-1/6 flex items-center gap-2 justify-end">
//                               <button
//                                 title="Upload to QuickBooks"
//                                 onClick={(e) => handleMarkResolved(e, invoice)}
//                                 className="transition-all duration-200 ease-in-out p-2 rounded-full hover:bg-green-100 group flex items-center justify-center active:scale-75"
//                               >
//                                 <SiQuickbooks className="text-xl text-[#2CA01C] group-hover:scale-105 transition-transform duration-200" />
//                               </button>
//                               <button
//                                 onClick={() => handleDelete(invoice.invoice_id)}
//                                 className="material-symbols-rounded text-gray-600 hover:text-gray-700 text-xl"
//                                 title="Delete"
//                               >
//                                 delete
//                               </button>
//                               <DocumentDownload invoice={invoice} />
//                             </div>
//                           </div>
//                         </div>
//                       );
//                     })}
//                 </div>

//                 {/* Mobile View */}
//                 <div className="lg:hidden">
//                   {invoices
//                     .sort((a, b) => {
//                       try {
//                         return new Date(b.created_at) - new Date(a.created_at);
//                       } catch (e) {
//                         return 0;
//                       }
//                     })
//                     .map((invoice) => renderMobileCard(invoice))}
//                 </div>
//               </>
//             ) : (
//               <div className="w-full flex items-center justify-center p-3 text-sm text-gray-600">
//                 <span>No invoices available</span>
//               </div>
//             )}
//           </div>
//         </div>

//         {describeAnalysis && (
//           <AnalysisSideBar
//             record_id={describeAnalysis}
//             invoiceData={
//               invoices.find((inv) => inv.invoice_number === describeAnalysis)
//             }
//             setDescribeAnalysis={setDescribeAnalysis}
//           />
//         )}
//         {isSidebarOpen && (
//           <AttachmentSidebar
//             isSidebarOpen={isSidebarOpen}
//             onClose={() => setIsSidebarOpen(false)}
//             attachments={attachments}
//             setAttachments={setAttachments}
//             setIsSidebarOpen={setIsSidebarOpen}
//           >
//             {renderAttachmentsSidebar()}
//           </AttachmentSidebar>
//         )}

//       </div>
//     </div>
//   );
// }

// export default AdminInvoiceAnalysis;


import { useEffect, useState, useRef } from "react";
import InvoiceUploadModal from "../../../components/InvoiceUploadModal";
import { SiQuickbooks } from "react-icons/si";
import {
  adminInvoices,
  analysisDetails,
  deleteAnalysis,
  invoiceStatus,
  // getInvoicesFromEmail,
  quickbooks,
  updateInvoiceStatus,
} from "../../../api/dashboard";
import Notify from "../../../components/Notification";
import AnalysisSideBar from "../../../components/AnalysisSideBar";
import { DocumentDownload } from "../../../components/DropDown";
import AttachmentSidebar from "../../../components/AttachmentSideBar.jsx";
import { useNavigate } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../components/ui/tooltip.jsx";
import InvoiceWidgets from "./Widgets_Invoice_page.jsx";
import { RiLoader4Fill } from "react-icons/ri";
import { BiLoaderCircle } from "react-icons/bi";
import { ChevronDown, Search } from 'lucide-react';
import { LuChevronsUpDown } from "react-icons/lu";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";


function AdminInvoiceAnalysis() {
  // const { chat } = useOutletContext();
  const [invoices, setInvoices] = useState([]);
  const [loader, setLoader] = useState(false);
  const [describeAnalysis, setDescribeAnalysis] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [dueDateFilter, setDueDateFilter] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState("asc");  // Tracks sorting order (asc/desc)
  const [showAmountFilter, setShowAmountFilter] = useState(false);
  const [amountRange, setAmountRange] = useState({ from: '', to: '' });

  const initialLoadDone = useRef(false);

  // Pratham's code
  // const email = localStorage.getItem("email");
  const [attachments, setAttachments] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const [openDropdown, setOpenDropdown] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Add these helper functions to manage dropdown states
  const closeDropdown = (invoiceId) => {
    setOpenDropdown(prev => ({
      ...prev,
      [invoiceId]: false
    }));
  };

  const toggleDropdown = (invoiceId) => {
    // If the clicked dropdown is already open, close it; otherwise, open it
    setOpenDropdown(prev => (prev === invoiceId ? null : invoiceId));
  };

  const statusOptions = [
    { value: 'Pending', color: 'bg-yellow-100 text-yellow-600' },
    // { value: 'Reviewed', color: 'bg-purple-100 text-purple-600' },
    { value: 'Accepted', color: 'bg-lime-100 text-lime-600' },
    { value: 'Paid', color: 'bg-blue-100 text-blue-600' },
    { value: 'Rejected', color: 'bg-red-100 text-red-600' }
  ];

  // Update the handleStatusChange function
  const handleStatusChange = async (invoiceId, newStatus) => {
    setInvoices(prevInvoices =>
      prevInvoices.map(invoice =>
        invoice.invoice_number === invoiceId
          ? { ...invoice, status: newStatus }
          : invoice
      )
    );
    try {
      const type = 'inv';
      const payload = {invoiceId, newStatus, type};
      console.log(payload);
      updateInvoiceStatus(payload);  // nakshatra - this is the new status change function
      // invoiceStatus(payload);
      // setStatus(newStatus);
      // After successful update, update the invoice status in the local state
      // setInvoices(prevInvoices =>
      //   prevInvoices.map(invoice =>
      //     invoice.invoice_id === invoiceId
      //       ? { ...invoice, status: newStatus }
      //       : invoice
      //   )
      // );
      // getInvoices();
      closeDropdown(invoiceId);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");

    if (code && state) {
      console.log("Google OAuth callback:", code, "\nstate:", state);

      // Call the /google-callback endpoint
      fetch(`${process.env.REACT_APP_API_URL}/api/google-callback?code=${code}&state=${state}`)
        .then(response => {
          if (!response.ok) {
            // Handle error responses
            return response.text(); // In case the response is not JSON
          }
          return response.json();  // Parse as JSON if the response is okay
        })
        .then(data => {
          if (typeof data === 'string') {
            console.error("Server error:", data);
            Notify("Authentication failed", "error");
            return;
          }

          if (data.success) {
            Notify("Authentication successful", "success");
          } else {
            Notify("Authentication failed", "error");
          }
        })
        .catch(error => {
          console.error("Error during callback:", error);
          Notify("Authentication failed", "error");
        });
    }
  }, []);

  const getInvoices = (isInitialLoad = false) => {
    setLoader(true);
    adminInvoices()
      .then(([status, response]) => {
        if (status === 200 && Array.isArray(response)) {
          const uniqueInvoices = new Map();
          response.forEach((invoice) => {
            const currentInvoice = uniqueInvoices.get(invoice.invoice_number);
            const currentDate = new Date(
              invoice.created_at || invoice.invoice_date || new Date()
            );
            if (
              !currentInvoice ||
              new Date(currentInvoice.created_at) < currentDate
            ) {
              // Ensure docs array is properly formatted
              const formattedDocs =
                invoice.documents?.map((doc) => ({
                  id: doc.id,
                  s3_key: doc.s3_key,
                  name: doc.s3_key.split("/").pop(), // Get filename from s3_key
                })) || [];
              uniqueInvoices.set(invoice.invoice_number, {
                ...invoice,
                created_at:
                  invoice.created_at ||
                  invoice.invoice_date ||
                  new Date().toISOString(),
                docs: formattedDocs, // Add formatted docs
              });
            }
          });
          const formattedInvoices = Array.from(uniqueInvoices.values()).map(
            (invoice) => ({
              invoice_id: invoice.id || "N/A",
              invoice_number: invoice.invoice_number || "N/A",
              total_amount: invoice.total_amount || "$0.00",
              payment_due_date: invoice.payment_due_date || "N/A",
              created_at: invoice.created_at,
              status: invoice.status || "Pending",
              billed_to_name: invoice.billed_to_name || "N/A",
              summary: invoice.summary || null,
              recoverable_amount: invoice.recoverable_amount || "N/A",
              doc_id: invoice.summary_id || "",
              table_data: invoice.table_data || [{}],
            })
          );
          console.log("formattedInvoices: ", formattedInvoices);
          setInvoices(formattedInvoices);

          if (!initialLoadDone.current && isInitialLoad) {
            initialLoadDone.current = true;
          }
        } else {
          console.error("Invalid response format:", response);

          setInvoices([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);

        setInvoices([]);
      })
      .finally(() => setLoader(false));
  };

  const handleDelete = (invoice_id) => {
    if (!invoice_id) {
      Notify("Invalid invoice ID", "error");
      return;
    }
    deleteAnalysis(invoice_id)
      .then(([status, data]) => {
        if (status === 200) {
          getInvoices(false);
          Notify("Invoice deleted successfully", "success");
        } else {
          Notify("Failed to delete invoice", "error");
        }
      })
      .catch((error) => {
        console.error("Error deleting invoice:", error);
        Notify("Error deleting invoice", "error");
      });
  };

  const handleMarkResolved = async (event, invoice) => {
    event.stopPropagation();
    try {
      Notify("Uploading to Quickbooks", "info");
      const response = await quickbooks({ invoice_id: invoice.invoice_id });
      console.log("Processed response:", response); // Debug log

      if (response.status === 200) {
        Notify(
          `Invoice No.${invoice.invoice_number} Uploaded to Quickbooks`,
          "success"
        );
      } else if (response.status === 404) {
        Notify("Invoice already exists in QuickBooks", "success");
      } else {
        Notify("Failed to upload to QuickBooks", "error");
      }
    } catch (error) {
      console.error("QuickBooks Error:", error);
      Notify("Failed to upload to QuickBooks", "error");
    }
  };

  useEffect(() => {
    getInvoices(true);
    return () => {
      initialLoadDone.current = false;
    };
  }, []);

  // use effect for searching and filtering
  // useEffect(() => {
  //   if (!searchQuery.trim()) {
  //     setFilteredInvoices(invoices);
  //     return;
  //   }
  
  //   const query = searchQuery.toLowerCase();
  //   const filtered = invoices.filter(invoice => 
  //     invoice.invoice_number.toLowerCase().includes(query) ||
  //     invoice.billed_to_name.toLowerCase().includes(query) ||
  //     invoice.status.toLowerCase().includes(query)
  //   );
  //   setFilteredInvoices(filtered);
  // }, [searchQuery, invoices]);

  useEffect(() => {
    let filtered = [...invoices]; 

    // Text search filter
    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(invoice =>
        invoice.invoice_number.toLowerCase().includes(query) ||
        invoice.billed_to_name.toLowerCase().includes(query) ||
        invoice.status.toLowerCase().includes(query)
      );
    }

    // Status filter
    if (statusFilter) {
      filtered = filtered.filter(invoice => invoice.status === statusFilter);
    }

    // Due date filter
    if (dueDateFilter) {
      const today = new Date();
      const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 30));

      switch (dueDateFilter) {
        case 'overdue':
          filtered = filtered.filter(invoice => new Date(invoice.payment_due_date) < new Date());
          break;
        case 'next30days':
          filtered = filtered.filter(invoice => {
            const dueDate = new Date(invoice.payment_due_date);
            return dueDate > new Date() && dueDate <= thirtyDaysFromNow;
          });
          break;
        case 'future':
          filtered = filtered.filter(invoice => new Date(invoice.payment_due_date) > thirtyDaysFromNow);
          break;
      }
    }

    // Amount filter
    if (amountRange.from || amountRange.to) {
      filtered = filtered.filter(invoice => {
        const amount = parseFloat(invoice.total_amount.replace(/[^0-9.-]+/g, ""));
        const from = amountRange.from ? parseFloat(amountRange.from) : -Infinity;
        const to = amountRange.to ? parseFloat(amountRange.to) : Infinity;
        
        return amount >= from && amount <= to;
      });
    }

    setFilteredInvoices(filtered);
  }, [searchQuery, statusFilter, dueDateFilter, amountRange, invoices]);

  
  // Separate useEffect for Sorting to prevent resetting filters
  useEffect(() => {
    if (!sortBy) return;
  
    console.log("Sorting ", sortBy, "in", sortOrder, "order");
    
    setFilteredInvoices(prevFiltered => {
      return [...prevFiltered].sort((a, b) => {
        let compareValue = 0;
  
        switch (sortBy) {
          case "date":
            compareValue = new Date(a.created_at) - new Date(b.created_at);
            break;
          case "name":
            compareValue = a.billed_to_name.localeCompare(b.billed_to_name);
            break;
          case "amount":
            const amountA = parseFloat(String(a.total_amount).replace(/[^0-9.-]+/g, ""));
            const amountB = parseFloat(String(b.total_amount).replace(/[^0-9.-]+/g, ""));
            compareValue = amountA - amountB;
            break;
          case "status":
            compareValue = a.status.localeCompare(b.status);
            break;
          default:
            break;
        }
  
        return sortOrder === "asc" ? compareValue : -compareValue;
      });
    });
  }, [sortBy, sortOrder]);   

  // const handleAmountFilter = () => {
  //   if (!amountRange.from && !amountRange.to) {
  //     setShowAmountFilter(false);
  //     return;
  //   }
  
  //   let filtered = [...invoices];
  //   filtered = filtered.filter(invoice => {
  //     const amount = parseFloat(invoice.total_amount.replace(/[^0-9.-]+/g, ""));
  //     const from = amountRange.from ? parseFloat(amountRange.from) : -Infinity;
  //     const to = amountRange.to ? parseFloat(amountRange.to) : Infinity;
      
  //     return amount >= from && amount <= to;
  //   });
  
  //   setFilteredInvoices(filtered);
  //   setShowAmountFilter(false);
  // };  

  {/* Add click outside handler */}
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     const filterButton = event.target.closest('.w-40');
  //     if (!filterButton && showAmountFilter) {
  //       setShowAmountFilter(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => document.removeEventListener('mousedown', handleClickOutside);
  // }, [showAmountFilter]);

  if (loader) {
    return (
      <div className="flex w-full items-center justify-center flex-1">
          <BiLoaderCircle className={"text-2xl animate-spin text-gray-700"} style={{ animationDuration: "2s" }}/>
      </div>
    );
  }

  const statusFilterOptions = [
    { value: "Paid", label: "Paid" },
    { value: "Accepted", label: "Accepted" },
    { value: "Pending", label: "Pending" },
    { value: "Rejected", label: "Rejected" }
  ];
  
  const dueDateOptions = [
    { value: "overdue", label: "Overdue" },
    { value: "next30days", label: "Due in 30 Days" },
    { value: "future", label: "Due in 30+days" }
  ];
  
  const sortOptions = [
    { value: "date", label: "Sort by Date" },
    { value: "name", label: "Sort by Name" },
    { value: "amount", label: "Sort by Amount" },
    { value: "status", label: "Sort by Status" }
  ];

  const CustomDropdownForFilters = ({ value, onChange, options, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);

    const selectedOption = options.find(option => option.value === value);
    const displayText = selectedOption ? selectedOption.label : placeholder;

    return ( 
      <div className="relative w-full sm:w-auto max-w-xs">
        <button
          type="button"
          className="w-full sm:w-auto flex items-center justify-between text-sm px-4 py-2.5 bg-white rounded-lg shadow-sm ring-1 ring-gray-200 focus:outline-none focus:ring-2 focus:ring-[#A8E45B] transition-all"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="text-gray-700 truncate">{displayText}</span>
          <ChevronDown className="h-4 w-4 text-gray-400" />
        </button>

        {isOpen && (
          <div className="absolute z-10 mt-1 w-full sm:w-auto min-w-[150px] md:min-w-[200px] bg-white rounded-lg shadow-lg py-1 ring-1 ring-gray-200">
            {placeholder && (
              <div
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm"
                onClick={() => {
                  onChange("");
                  setIsOpen(false);
                }}
              >
                {placeholder}
              </div>
            )}
            {options.map(option => (
              <div
                key={option.value}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm"
                onClick={() => {
                  onChange(option.value);
                  setIsOpen(false);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };


  const RenderTableHeader = ({ sortBy, sortOrder, setSortOrder, setSortBy }) => {
    const handleSortToggle = (column) => {
      if (sortBy === column) {
        // Toggle sort order if the same column is clicked
        setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
      } else {
        // Set new column and default to ascending order
        setSortBy(column);
        setSortOrder("asc");
      }
    };
  
    return (
      <div className="rounded-md mt-4 w-full hidden xl:flex items-center justify-between bg-gray-100 border mb-4">
        <div className="flex w-full p-4">
          {[
            { key: "invoice_number", label: "Invoice Number", width: "w-1/4" },
            { key: "name", label: "Billed To", width: "w-1/4" },
            { key: "amount", label: "Total Amount", width: "w-1/6" },
            { key: "date", label: "Due Date", width: "w-1/6" },
            { key: "status", label: "Status", width: "w-1/4" },
          ].map(({ key, label, width }) => (
            <div
              key={key}
              className={`relative ${width} cursor-pointer font-semibold text-sm text-gray-600 flex items-center gap-1`}
            >
              <span onClick={() => handleSortToggle(key)}>{label}</span>
              <div className="h-6 flex flex-col items-center">
                <button
                  onClick={() => handleSortToggle(key)}
                >
                  <FaAngleUp className={`text-xs ${
                    sortBy === key && sortOrder === "desc"
                      ? "text-[#5e921f]"
                      : "text-gray-400"
                  }`}/>
                </button>
                <button
                >
                  <FaAngleDown className={`text-xs ${
                    sortBy === key && sortOrder === "asc"
                      ? "text-[#5e921f]"
                      : "text-gray-400"
                  }`}/>
                </button>
              </div>
            </div>
          ))}
          <span className="w-1/6 text-right font-semibold text-sm text-gray-600">
            Actions
          </span>
        </div>
      </div>
    );
  };  
  
  const renderMobileCard = (invoice) => (
    <div className="flex flex-col p-4 border rounded-md mb-4 bg-white shadow-sm">
      <div className="flex justify-between items-start mb-3">
        <div className="flex items-center gap-2">
          <span className="material-symbols-rounded text-gray-200">
            receipt_long
          </span>
          <span
            onClick={() => setDescribeAnalysis(invoice.invoice_number)}
            className="font-medium cursor-pointer hover:text-blue-600 hover:underline"
          >
            Invoice: {invoice.invoice_number}
          </span>
        </div>
        <div className="flex gap-2">
          <button
            title="Mark as resolved"
            onClick={(e) => handleMarkResolved(e, invoice)}
            className="material-symbols-rounded text-green-500 hover:text-green-600 text-xl"
          >
            check
          </button>
          <button
            onClick={() => handleDelete(invoice.invoice_id)}
            className="material-symbols-rounded text-gray-600 hover:text-gray-700 text-xl"
            title="Delete"
          >
            delete
          </button>
          <DocumentDownload invoice={invoice} />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-y-2 text-sm">
        <div className="text-gray-600">Billed To:</div>
        <div className="font-medium">{invoice.billed_to_name}</div>

        <div className="text-gray-600">Total Amount:</div>
        <div className="font-semibold">{invoice.total_amount}</div>

        <div className="text-gray-600">Due Date:</div>
        <div className="font-medium">{invoice.payment_due_date}</div>

        <div className="text-gray-600">Status:</div>
        <div className="font-medium">{invoice.status}</div>
      </div>
    </div>
  );

  // COMMENT THIS BEFORE PUSHING
  // const handleViewAttachments = async () => {
  //   // Mock attachment data
  //   const mockAttachments = [
  //     {
  //       s3_key: "attachments/invoice1.pdf",
  //       filename: "invoice1.pdf",
  //       created_at: new Date("2025-01-23").toLocaleDateString("en-US", {
  //         year: 'numeric',
  //         month: 'long',
  //         day: 'numeric'
  //       }),
  //       size: "1.2 MB"
  //     },
  //     {
  //       s3_key: "attachments/invoice2.pdf",
  //       filename: "invoice2.pdf",
  //       created_at: new Date("2025-01-22").toLocaleDateString("en-US", {
  //         year: 'numeric',
  //         month: 'long',
  //         day: 'numeric'
  //       }),
  //       size: "850 KB"
  //     },
  //     {
  //       s3_key: "attachments/receipt1.pdf",
  //       filename: "receipt1.pdf",
  //       created_at: new Date("2025-01-21").toLocaleDateString("en-US", {
  //         year: 'numeric',
  //         month: 'long',
  //         day: 'numeric'
  //       }),
  //       size: "500 KB"
  //     }
  //   ];    

  //   setAttachments(mockAttachments);
  //   setIsSidebarOpen(true);
  // };

  //  UNCOMMENT THIS BEFORE PUSHING
  const handleViewAttachments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/connect-gmail`);

      if (!response.ok) {
        // Handle non-OK responses (e.g., 500 error)
        const errorText = await response.text();  // Read the plain text in case of error
        console.error("Server error:", errorText);
        Notify("Error initiating authentication", "error");
        return;
      }

      const data = await response.json();

      if (data.success) {
        if (data.auth_url) window.location.href = data.auth_url;
        else {
          handleFetchAttachments();
        }
        // Redirect user to the Google authentication URL
      } else {
        Notify(data.message || "Failed to initiate authentication", "error");
      }
    } catch (error) {
      console.error("Error initiating authentication:", error);
      Notify("Error initiating authentication", "error");
    }
  };

  // gmail api call
  const handleFetchAttachments = async () => {
    try {
      Notify("Fetching email attachments...");
      setIsSidebarOpen(true); // Open the sidebar
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/fetch-and-upload-pdfs`); // Adjust the URL as needed
      if (!response.ok) {
        Notify("Failed to fetch attachments", "error");
        return;
      }

      const data = await response.json(); // Parse the JSON response
      console.log("Attachments response:", data);

      if (data.success) {
        if (!data.data || data.data.length === 0) {
          Notify("No PDF attachments found", "error");
          setIsSidebarOpen(false); // Open the sidebar
        } else {
          const attachmentsWithUrls = data.data.map((item) => ({
            s3_key: item.s3_key,
            filename: item.s3_key.split("/").pop(),
            email_date: item.email_date,  // Include email_date
          }));
          setAttachments(attachmentsWithUrls); // Update state with the processed s3_keys
          Notify("Email attachments fetched successfully", "success");
        }
      } else {
        setIsSidebarOpen(false); // Open the sidebar
        Notify(data.error || "Failed to fetch attachments", "error");
      }
    } catch (error) {
      console.error("Error fetching attachments:", error);
      Notify("Error fetching attachments", "error");
    }
  };

  const renderAttachmentsSidebar = () => (
    <div className="attachments-sidebar">
      {attachments.length > 0 ? (
        attachments.map((attachment) => (
          <div
            key={attachment.id}
            className="attachment-item flex items-center justify-between p-2 border-b"
          >
            <div>
              <p className="text-sm font-medium">{attachment.filename}</p>
              <p className="text-xs text-gray-500">
                From: {attachment.sender_email} <br />
                Date: {new Date(attachment.email_date).toLocaleString()}
              </p>
            </div>
            <a
              href={attachment.s3_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 text-sm"
            >
              View
            </a>
          </div>
        ))
      ) : (
        <p className="text-gray-500 text-sm">No attachments available</p>
      )}
    </div>
  );

  return (
    <div className="flex flex-col items-start w-full space-y-4 rounded-md relative flex-1 px-4 py-0">
      <div className="px-10 flex justify-end gap-4 w-full">
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <button className="material-symbols-rounded w-10 h-10 bg-accentBack flex-shrink-0 rounded-xl border font-normal text-white transition-all duration-100 ease-in-out hover:bg-[#A3E635] focus:outline-none focus:ring-2 focus:ring-[#A3E635] focus:ring-offset-2"
                onClick={() => navigate('/staging-app/dashboard/admin/anaylsis_dashboard')}
              >
                space_dashboard
              </button>
            </TooltipTrigger>
            <TooltipContent>
              <p className="bg-accentBack text-white rounded-full px-2 py-1">Dashboard</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <button className="material-symbols-rounded w-10 h-10 flex-shrink-0 rounded-xl bg-accentBack font-normal text-white transition-all duration-100 ease-in-out hover:bg-[#A3E635] focus:outline-none focus:ring-2 focus:ring-[#A3E635] focus:ring-offset-2"
                onClick={handleViewAttachments}
              >
                attach_file_add
              </button>
            </TooltipTrigger>
            <TooltipContent>
              <p className="bg-accentBack text-white rounded-full px-2 py-1">Fetch attachments from mail</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <button className="material-symbols-rounded w-10 h-10 flex-shrink-0 rounded-xl bg-accentBack font-normal text-white transition-all duration-100 ease-in-out hover:bg-[#A3E635] focus:outline-none focus:ring-2 focus:ring-[#A3E635] focus:ring-offset-2"
                onClick={() => setShowModal(!showModal)}
              >
                upload_file
              </button>
            </TooltipTrigger>
            <TooltipContent>
              <p className="bg-accentBack text-white rounded-full px-2 py-1">Upload files</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>

      {showModal && (
        <InvoiceUploadModal
          getInvoices={() => getInvoices(false)}
          onUploadStart={() => Notify("Uploading...", "info")}
          onClose={() => setShowModal(false)} // Close modal when needed
        />
      )}

      {/* This has the top 4 widgets */}
      <InvoiceWidgets />

      <div className="card flex flex-col w-full h-full -my-2">


        <div className="w-full flex flex-col">

          {/* Top div with searchbar and filters */}
          <div className="w-full flex flex-col lg:flex-row items-center py-1">
            {/* Search Input */}
            <div className="w-full lg:w-3/12">
              <div className="relative">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                  <svg className="h-4 w-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </span>
                <input
                  type="text"
                  value={searchQuery}
                  placeholder="Search"
                  className="w-full text-sm pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#A8E45B] focus:border-transparent"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-end w-full lg:w-9/12 space-x-1 lg:space-x-4 my-2 lg:my-0">
              {/* Status Filter */}
              <div className="w-auto">
                <CustomDropdownForFilters
                  value={statusFilter}
                  onChange={setStatusFilter}
                  options={statusFilterOptions}
                  placeholder="Status (All)"
                />
              </div>

              {/* Due Date Filter */}
              <div className="w-auto">
                <CustomDropdownForFilters
                  value={dueDateFilter}
                  onChange={setDueDateFilter}
                  options={dueDateOptions}
                  placeholder="Due Date (All)"
                />
              </div>

              {/* Sort By */}
              {/* <div className="w-40">
                <CustomDropdownForFilters
                  value={sortBy}
                  onChange={setSortBy}
                  options={sortOptions}
                  placeholder="Sort by Date"
                />
              </div> */}

              {/* Amount Range Filter */}
              <div className="w-auto relative">
                <button
                  onClick={() => setShowAmountFilter(true)}
                  className="w-full flex items-center justify-between text-sm px-4 py-2.5 bg-white rounded-lg shadow-sm ring-1 ring-gray-200 focus:outline-none focus:ring-2 focus:ring-[#A8E45B]"
                >
                  <span className="text-gray-700 truncate">Total Amount</span>
                  <ChevronDown className="h-4 w-4 text-gray-400" />
                </button>
              </div>

              {/* Amount Range Filter Popup */}
              <AnimatePresence>
              {showAmountFilter && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.20, y: -10 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.20, y: -10 }}
                  transition={{ duration: 0.15, ease: "easeOut" }}
                  className="absolute right-0 mt-1 w-72 bg-white rounded-lg shadow-lg border border-gray-200 z-50"
                >
                  <div className="p-4">
                    <div className="flex justify-between items-center mb-3">
                      <h3 className="text-sm font-semibold">Filter by Amount</h3>
                      <button
                        onClick={() => setShowAmountFilter(false)}
                        className="text-gray-400 hover:text-gray-600 transition-colors"
                      >
                        <span className="material-symbols-rounded text-lg">close</span>
                      </button>
                    </div>
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.1, duration: 0.2 }}
                      className="space-y-3"
                    >
                      <div>
                        <label className="block text-xs font-medium text-gray-700 mb-1">
                          From ($)
                        </label>
                        <input
                          type="number"
                          value={amountRange.from}
                          onChange={(e) => setAmountRange(prev => ({ ...prev, from: e.target.value }))}
                          className="w-full px-3 py-1.5 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-[#A8E45B]"
                          placeholder="Min amount"
                        />
                      </div>
                      <div>
                        <label className="block text-xs font-medium text-gray-700 mb-1">
                          To ($)
                        </label>
                        <input
                          type="number"
                          value={amountRange.to}
                          onChange={(e) => setAmountRange(prev => ({ ...prev, to: e.target.value }))}
                          className="w-full px-3 py-1.5 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-[#A8E45B]"
                          placeholder="Max amount"
                        />
                      </div>
                      <div className="flex justify-end space-x-2 pt-2">
                        <button
                          onClick={() => {
                            setAmountRange({ from: '', to: '' });
                            setShowAmountFilter(false);
                            setFilteredInvoices(invoices);
                          }}
                          className="px-3 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-100 rounded-md"
                        >
                          Clear
                        </button>
                        <button
                          onClick={() => {
                            setShowAmountFilter(false);
                          }}
                          className="px-3 py-1.5 text-xs font-medium text-white bg-[#A8E45B] hover:bg-[#95cc4a] rounded-md"
                        >
                          Apply
                        </button>
                      </div>
                    </motion.div>
                  </div>
                </motion.div>
              )}
              </AnimatePresence>
            </div>

          </div>

          {/* Desktop Table Header */}
          <RenderTableHeader sortBy={sortBy} sortOrder={sortOrder} setSortBy={setSortBy} setSortOrder={setSortOrder} />

          {/* Responsive Content */}
          <div className="w-full">
            {filteredInvoices.length > 0 ? (
              <>
                {/* Desktop View */}
                <div className="hidden xl:flex flex-col w-full gap-2 max-xl:hidden">
                  {filteredInvoices
                    .map((invoice) => {
                      return (
                        <div
                          key={`desktop-${invoice.invoice_number}`}
                          className="flex items-center w-full border rounded-md hover:bg-gray-50"
                        >
                          <div className="flex items-center flex-[4] p-4 space-x-3">
                            <div className="w-1/4 flex items-center gap-1">
                              <span className="material-symbols-rounded text-gray-600">receipt_long</span>
                              <span
                                onClick={() => setDescribeAnalysis(invoice.invoice_number)}
                                className="font-medium cursor-pointer hover:text-blue-600 hover:underline"
                              >
                                Invoice: {invoice.invoice_number}
                              </span>
                            </div>
                            <span className="w-1/4 text-left pr-2 font-medium">
                              {invoice.billed_to_name}
                            </span>
                            <span className="w-1/6 font-semibold text-left">{invoice.total_amount}</span>
                            <span className="w-1/6 text-left text-gray-600 font-medium">{invoice.payment_due_date}</span>

                            {/* Editable Dropdown for Status */}
                            <div className="relative w-1/4">
                              <div className="relative">
                                <button
                                  onClick={() => toggleDropdown(invoice.invoice_id)}
                                  className={`w-full border flex items-center justify-between px-2 py-1.5 rounded-t-lg bg-white text-gray-700`} // Default styles for button
                                >
                                  <span className={`text-sm font-medium w-fit flex items-center px-2 py-0.5 rounded-full ${statusOptions.find(opt => opt.value === invoice.status)?.color || 'text-gray-700'}`}>
                                    ○ {invoice.status}
                                  </span>
                                  <span className="material-symbols-rounded text-current text-sm">
                                    expand_more
                                  </span>
                                </button>

                                {openDropdown === invoice.invoice_id && (
                                  <div className="absolute flex flex-col space-y-2 z-10 mt-1 w-full bg-white border rounded-b-lg shadow-md py-1">
                                    {statusOptions.map((option) => (
                                      <button
                                        key={option.value}
                                        onClick={() => {
                                          handleStatusChange(invoice.invoice_number, option.value);
                                          // setOpenDropdown(null); // close dropdown after selection
                                        }}
                                        className={`w-fit text-left px-2 py-0 hover:bg-gray-200 ${option.color} mx-2 rounded-full text-sm font-medium`}
                                      >
                                        ● {option.value}
                                      </button>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="w-1/6 flex items-center gap-2 justify-end">
                              <button
                                title="Upload to QuickBooks"
                                onClick={(e) => handleMarkResolved(e, invoice)}
                                className="transition-all duration-200 ease-in-out p-2 rounded-full hover:bg-green-100 group flex items-center justify-center active:scale-75"
                              >
                                <SiQuickbooks className="text-xl text-[#2CA01C] group-hover:scale-105 transition-transform duration-200" />
                              </button>
                              <button
                                onClick={() => handleDelete(invoice.invoice_id)}
                                className="material-symbols-rounded text-gray-600 hover:text-gray-700 text-xl"
                                title="Delete"
                              >
                                delete
                              </button>
                              <DocumentDownload invoice={invoice} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                {/* Mobile View */}
                <div className="lg:hidden">
                  {invoices
                    .sort((a, b) => {
                      try {
                        return new Date(b.created_at) - new Date(a.created_at);
                      } catch (e) {
                        return 0;
                      }
                    })
                    .map((invoice) => renderMobileCard(invoice))}
                </div>
              </>
            ) : (
              <div className="w-full flex items-center justify-center p-3 text-sm text-gray-600">
                <span>No invoices available</span>
              </div>
            )}
          </div>
        </div>

        {describeAnalysis && (
          <AnalysisSideBar
            record_id={describeAnalysis}
            invoiceData={
              invoices.find((inv) => inv.invoice_number === describeAnalysis)
            }
            setDescribeAnalysis={setDescribeAnalysis}
          />
        )}
        {isSidebarOpen && (
          <AttachmentSidebar
            isSidebarOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            attachments={attachments}
            setAttachments={setAttachments}
            setIsSidebarOpen={setIsSidebarOpen}
          >
            {renderAttachmentsSidebar()}
          </AttachmentSidebar>
        )}

      </div>
    </div>
  );
}

export default AdminInvoiceAnalysis;