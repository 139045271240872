// Get current local time
const now = new Date();
const currentHour = now.getHours();

let greeting;
if (currentHour < 12) {
  greeting = "Morning";
} else if (currentHour < 18) {
  greeting = "Afternoon";
} else {
  greeting = "Evening";
}

// Update dashboardData with the appropriate greeting
export const dashboardData = {
  userName: "Aria ",
  metrics: {
    outstandingInvoices: {
      value: 234,
      trend: '+2.5%',
      data: [120, 170, 90, 160, 100, 190, 168, 180],
    },
    amountDue: {
      value: 400234,
      trend: '-2.5%',
      prefix: '$',
      data: [380000, 360000, 390000, 350000, 420000, 370000, 395000, 380000],
    },
    totalSpend: {
      value: 4230567,
      trend: '+6.3%',
      prefix: '$',
      data: [380000, 360000, 390000, 350000, 420000, 370000, 395000, 380000],
    },
  },

  invoiceStatus: {
    pendingApproval: 35,
    approved: 25,
    paid: 15,
    overdue: 25,
  },

  topVendors: {
    currentYear: [
      { name: 'LTL', value: 28000 },
      { name: 'FedEx', value: 12000 },
      { name: 'Freight', value: 22000 },
      { name: 'Fresh', value: 8000 },
      { name: 'Express', value: 18000 },
    ],
    previousYear: [
      { name: 'LTL', value: 25000 },
      { name: 'FedEx', value: 15000 },
      { name: 'Freight', value: 35000 },
      { name: 'Fresh', value: 10000 },
      { name: 'Express', value: 20000 },
    ],

  },
  spendByYear: {
    value: '4.23M',
    currentYear: [
      { month: 'Jan', amount: 280000 },
      { month: 'Feb', amount: 320000 },
      { month: 'Mar', amount: 310000 },
      { month: 'Apr', amount: 350000 },
      { month: 'May', amount: 380000 },
      { month: 'Jun', amount: 420000 },
      { month: 'Jul', amount: 390000 },
      { month: 'Aug', amount: 430000 },
      { month: 'Sep', amount: 460000 },
      { month: 'Oct', amount: 440000 },
      { month: 'Nov', amount: 480000 },
      { month: 'Dec', amount: 500000 }
    ],
    previousYear: [
      { month: 'Jan', amount: 250000 },
      { month: 'Feb', amount: 290000 },
      { month: 'Mar', amount: 285000 },
      { month: 'Apr', amount: 320000 },
      { month: 'May', amount: 340000 },
      { month: 'Jun', amount: 380000 },
      { month: 'Jul', amount: 360000 },
      { month: 'Aug', amount: 390000 },
      { month: 'Sep', amount: 420000 },
      { month: 'Oct', amount: 400000 },
      { month: 'Nov', amount: 440000 },
      { month: 'Dec', amount: 460000 }
    ]
  },

  spendByCategory: {
    currentYear: [
      { name: 'Carrier', value: 28000 },
      { name: 'Driver', value: 8000 },
      { name: 'Supplier', value: 18000 },
      { name: 'MRO', value: 12000 },
      { name: 'Other', value: 32000 },
    ],
    previousYear: [
      { name: 'Carrier', value: 25000 },
      { name: 'Driver', value: 10000 },
      { name: 'Supplier', value: 22000 },
      { name: 'MRO', value: 15000 },
      { name: 'Other', value: 28000 },
    ],
  },

  paymentMethods: {
    'Debit/Credit Card': 4500,
    'ACH': 3500,
    'Wire': 2000,
  },

  outstandingInvoicesByCustomer: {
    currentYear: [
      { name: 'Omega', value: 28000 },
      { name: 'Sigma', value: 22000 },
      { name: 'Delta', value: 18000 },
      { name: 'Beta', value: 15000 },
      { name: 'Alpha', value: 12000 },
    ],
    previousYear: [
      { name: 'Omega', value: 25000 },
      { name: 'Sigma', value: 20000 },
      { name: 'Delta', value: 16000 },
      { name: 'Beta', value: 14000 },
      { name: 'Alpha', value: 10000 },
    ],
  },

  invoiceAgeBreakdown: {
    '0-30 Days': 35,
    '30-60 Days': 40,
    '90+ Days': 25,
  },
};