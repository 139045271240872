import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Input from "../../components/Input";
import Img from "../../components/Img";
import {placeOrder, productInfo, updateCart} from "../../api/dashboard";
import cartSlice from "../../redux/cartSlice";
import {useDispatch, connect} from "react-redux";

function ProductDetails({locations, cart}) {
    const dispatch = useDispatch();
    const {productID} = useParams();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [product, setProduct] = useState(null);

    const [locationId, setLocationId] = useState("");
    const [requestInfo, setRequestInfo] = useState("");
    const [deliverBy, setDeliverBy] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [errors, setErrors] = useState({});

    // fetching product
    useEffect(() => {
        setLoader(true);
        productInfo(productID).then(([status, data]) => {
            if (status === 200) {
                setProduct(data);
            }
        }).finally(() => setLoader(false));
    }, [productID]);

    useEffect(() => {
        if (cart.products && locations.length) {
            const prod = (cart.products || [])[cart.products?.length - 1];
            if (prod) {
                setLocationId(prod.location.location_id || "");
                setRequestInfo(prod.request_info || "");
                setDeliverBy(prod.expected_delivery_date?.split("T")[0] || "");
            }
        }
    }, [cart, locations]);

    const [loadingCart, setLoadingCart] = useState(false);

    const handleCart = () => {
        const payload = {
            deliver_by: deliverBy || "", location_id: (locationId || "0").toString(),
            request_info: requestInfo, quantity, product_id: productID
        };
        if (inCart) {
            return navigate("/staging-app/dashboard/orders/cart");
        } else {
            if (cart.products) {
                const product = cart.products[cart.products.length - 1];
                if (product) {
                    if (payload.request_info !== product.request_info ||
                        payload.location_id.toString() !== product.location.location_id.toString() ||
                        payload.deliver_by !== product.expected_delivery_date?.split("T")[0]) {
                        if (!window.confirm("Entered date, location, and other details will be " +
                            "applied to the whole cart. Click OK to proceed."))
                            return ;
                    }
                }
            }

            setLoadingCart(true);
            updateCart({...payload, action: "add"})
                .then(([status, data]) => {
                    if (status === 200) {
                        dispatch(cartSlice.actions.addProduct(data));
                        navigate("/staging-app/dashboard/orders/cart");
                    }
                }).catch(console.log).finally(() => {
                setLoadingCart(false);
            });
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (e.target.reportValidity()) {
            const payload = {
                deliver_by: deliverBy, location_id: locationId,
                request_info: requestInfo, quantity, product_id: productID
            };
            setLoader(true);
            placeOrder(payload)
                .then(([status, _]) => {
                    if (status === 200) {
                        navigate("/staging-app/dashboard/success", {
                            state: {
                                message: "Order placed successfully!",
                                details: `${product.product_description}${product.product_description && product.product_name ? " | " : ""}${product.product_name}`,
                                ref: window.location.href
                            }
                        });
                    } else {
                        setErrors({form: "Something went wrong"});
                    }
                })
                .finally(() => setLoader(false));
        }
    }

    const inCart = cart.products.find(x => x.product_id.toString() === productID);


    if (loader)
        return <div className={"flex w-full flex-1 items-center justify-center"}>
            <span className={"material-symbols-rounded animate-spin"}>progress_activity</span></div>

    if (!product)
        return <div className={"flex w-full flex-1 items-center justify-center"}>
            <span>Couldn't find the product.</span>
        </div>

    const product_name = `${product.product_description || ""}${product.product_description && product.product_name ? " | " : ""}${product.product_name}`;
    const product_price = Number.parseFloat(((product.discounted_unit_price || product.unit_price) * quantity).toString()).toFixed(2);

    return (
        <div className={"flex flex-col w-full flex-1 rounded-md gap-2"}>
            <div className={"p-2"}>
                    <span className={"flex text-xl gap-1 w-full"}><b
                        className={"font-semibold"}>Product</b> Details</span>
            </div>
            <div className={"flex flex-col lg:flex-row lg:items-start w-full gap-4"}>
                <div className={"flex flex-col flex-[2] gap-2"}>
                    <div className={"flex items-center justify-center p-8 bg-green-100 rounded-3xl mb-4"}>
                        <Img src={product.product_image || ""} alt={product_name}
                             className={"object-cover aspect-video lg:max-w-96"}/>
                    </div>
                    <div className={"flex flex-col items-start"}>
                        <span className={"font-bold text-lg px-2"}>{product_name}</span>
                        <span
                            className={"font-bold text-green-800 px-2 text-sm"}>Supplier: {product.supplier_name}</span>
                    </div>
                    <div className={"flex items-center justify-start gap-2 px-1"}>
                        <div className={"flex items-center"}>
                            {
                                Array(5).fill(0).map((_, index) =>
                                    <span key={`star-${index}`}
                                          className={`material-symbols-rounded mso-fill text-2xl ${index <= 3 ? "text-amber-400" : "text-gray-400"}`}>star</span>
                                )
                            }
                        </div>
                        <span className={"text-xs"}>(11.8K reviews)</span>
                    </div>
                    <div className={"flex items-center gap-4 text-xl font-bold px-2"}>
                        {
                            product.discounted_unit_price && product.discounted_unit_price !== product.unit_price &&
                            <span className={"text-gray-600 line-through"}>${product.unit_price}</span>
                        }
                        <span
                            className={""}>${product_price}</span>
                    </div>
                    <div className={"flex flex-col items-start p-2 gap-2"}>
                        <span className={"text-sm text-cyan-500 uppercase font-bold"}>About this item</span>
                        <span className={"text-sm text-ellipsis break-words"}>
                            {product.product_description || product.product_specifications || "-"}
                        </span>
                        <div className={"flex flex-col items-start gap-4 w-full my-2"}>
                            <div className={"flex items-center gap-2"}>
                                <span className={"text-gray-600 text-sm font-bold"}>Specification: </span>
                                <span
                                    className={"text-black text-ellipsis break-words"}>{product.product_specifications || "-"}</span>
                            </div>
                            <div className={"flex items-center gap-2"}>
                                <span className={"text-gray-600 text-sm  font-bold"}>Category: </span>
                                <span
                                    className={"text-black"}>{typeof product.category !== "string" ? product.category.join(" > ") : product.category || "-"}</span>
                            </div>
                            <div className={"flex items-center gap-2"}>
                                <span className={"text-gray-600 text-sm  font-bold"}>Approval Required: </span>
                                <span className={"text-black "}>{product_price > 1000 ? "Yes" : "No"}</span>
                            </div>
                        </div>

                    </div>


                </div>
                <div className={"flex flex-col flex-1 card gap-4"}>
                    <span className={"text-sm text-cyan-500 uppercase font-bold"}>Order Information</span>
                    <form onSubmit={handleSubmit} className={"flex flex-col items-end w-full"}>
                        <div className={"flex flex-col flex-1 gap-8 rounded-md w-full"}>
                            {/* deliver by date */}
                            <div className={"flex flex-col w-full gap-1"}>
                                <span className={"text-xs text-gray-600"}>When do you require this by?</span>
                                <Input id={"deliverBy"} name={"deliverBy"} value={deliverBy}
                                       className={"border-gray-200"}
                                       min={new Date().getFullYear() + "-" + new Date().toLocaleDateString().split("/").slice(0, 2).map(x => x.toString().padStart(2, '0')).join("-")}
                                       required={true} errors={errors.deliverBy} inpStyle={"text-xs w-full"}
                                       type={"date"} onChange={(e) => setDeliverBy(e.target.value)}/>
                            </div>

                            {/* location */}
                            <div className={"flex flex-col w-full gap-1"}>
                                <span className={"text-xs text-gray-600"}>Choose Location</span>
                                <Input id={"location"} name={"location"} value={locationId}
                                       className={"border-gray-200"}
                                       options={locations?.map(l => ({
                                           id: l.id,
                                           name: `${l.city}, ${l.state}`
                                       }))}
                                       required={true} errors={errors.location} inpStyle={"text-xs"}
                                       endProp={<span
                                           className={"material-symbols-rounded text-lg "}>expand_more</span>}
                                       type={"select"} onChange={(e) => setLocationId(e.target.value)}/>
                            </div>

                            {/* request info */}
                            <div className={"flex flex-col w-full gap-1"}>
                                <span className={"text-xs text-gray-600"}>Details about request</span>
                                <Input id={"requestInfo"} name={"requestInfo"} value={requestInfo}
                                       placeholder={"Enter details"} className={"border-gray-200"}
                                       errors={errors.requestInfo} inpStyle={"text-xs w-full"} maxLength={240}
                                       required={false}
                                       type={"text"} onChange={(e) => setRequestInfo(e.target.value)}/>
                            </div>

                            {/* quantity */}
                            <div className={"flex flex-col w-full gap-1"}>
                                <span className={"text-xs text-gray-600"}>Quantity</span>
                                <div
                                    className={"card p-0 bg-transparent border-slate-300 flex items-center overflow-hidden w-fit"}>
                                    <button onClick={() => setQuantity(Math.max(1, quantity - 1))} type={"button"}
                                            className={"hover:bg-[rgba(0,0,0,0.1)] material-symbols-rounded p-2 w-fit text-md"}>remove
                                    </button>
                                    <span className={"text-xs font-medium p-2 px-4 border-x"}>{quantity}</span>
                                    <button onClick={() => setQuantity(quantity + 1)} type={"button"}
                                            className={"hover:bg-[rgba(0,0,0,0.1)] material-symbols-rounded p-2 w-fit text-md"}>add
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col w-full items-center gap-4 mt-4"}>
                            {
                                errors.form &&
                                <span className={"block my-2 font-medium text-xs text-red-500"}>{errors.form}</span>
                            }
                            <button id={"cart-button"} type={"button"} onClick={handleCart}
                                    disabled={loadingCart}
                                    className={"p-3 rounded-md px-12 hover:bg-green-700 bg-green-600 text-white gap-2 w-full font-bold text-sm"}>
                                {loadingCart ? <span
                                    className={"material-symbols-rounded text-md animate-pulse"}>more_horiz</span> : inCart ? "Go to cart" : "Add to Cart"}
                            </button>
                            <button type={"submit"} id={"submitOrder"}
                                    className={"p-3 rounded-md px-12 bg-nimbey hover:bg-lime-400 w-full font-bold text-sm"}>Place
                                Order
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect((state) => ({locations: state.userData.locations, cart: state.cart}))(ProductDetails);