import React from "react";
import { motion } from "framer-motion";
import clipboardImage from "../assets_lp2/icons/clipboardImage.svg";
import analysisImage from "../assets_lp2/icons/analysis.svg";
import reconciliationImage from "../assets_lp2/icons/reconciliation.svg";

const cards = [
    {
        title: "Extract  data from shipping documents.",
        description:
          "Seamlessly extractsdata from BOL, POD, invoices, carrier contracts, freight rates, lumper receipts, etc.",
        imgSrc: clipboardImage,
        size: "medium",
      },
      {
        title: "Validate Charges on Carrier Invoices",
        description:
          "Nimbey AI analyzes carrier invoice and flags incorrect charges including surcharges, accessorial charges charges, and additional services. ",
        imgSrc: analysisImage,
        size: "large",
      },
      {
        title: "Human Review and Payment Reconciliation",
        description:
          "Automatically record payments with integration to financial platfroms, including Quickbooks and Netsuite.",
        imgSrc: reconciliationImage,
        size: "medium",
      },
];

const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: { 
            duration: 0.6, 
            staggerChildren: 0.2 
        },
    },
};

const cardVariants = {
    hidden: (index) => ({
        opacity: 0,
        y: index === 0 ? 100 : 20,
    }),
    visible: {
        opacity: 0.8,
        y: 0,
        transition: { 
            duration: 0.5,
            ease: "easeOut",
        }
    },
    highlight: (index) => ({
        y: [0, -15, -15, 0],
        opacity: [0.5, 1, 1, 0.5],
        boxShadow: [
            "none",
            "0 25px 30px -12px rgb(0 0 0 / 0.15)",
            "0 25px 30px -12px rgb(0 0 0 / 0.15)",
            "none"
        ],
        transition: {
            delay: index * 2,
            duration: 1.2,
            ease: "anticipate",
            times: [0, 0.2, 0.8, 1],
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 4
        }
    }),
};

const contentVariants = {
    hover: {
        scale: 1.05,
        transition: {
            duration: 0.3,
            ease: "easeOut"
        }
    }
};

const BentoGrid = () => {
    return (
        <motion.div
            id="how-it-works"
            className="py-16 px-6 my-20"
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.3, once: true }}
            variants={containerVariants}
        >
            {/* Title Section */}
            <h2 className="text-4xl text-black font-semibold text-center mb-12">
                How It Works?
            </h2>
            {/* <p className="text-xl md:text-[20px] text-gray-600 text-center mb-8">
                Maximize Your Recovery with Expert Audits, AI Insights, and Success-Based Results
            </p> */}

            {/* Bento Grid */}
            <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
                {cards.map((card, index) => (
                    <motion.div
                        key={index}
                        custom={index}
                        variants={cardVariants}
                        whileHover="hover"
                        viewport={{ once: true }}
                        className={`p-12 h-[400px] rounded-2xl bg-[#f4f1ef] flex flex-col items-start shadow-none transition-all hover:opacity-100
                        ${index === 0 ? "md:col-span-2" : ""} ${index === 2 ? "md:col-span-4" : ""}`}
                    >
                        <motion.div
                            className="flex flex-col items-start w-full h-full"
                            variants={contentVariants}
                        >
                            <img src={card.imgSrc} alt={card.title} className="w-8 h-8 mb-4" />
                            <h3 className="text-lg font-semibold text-gray-800 mb-2">{card.title}</h3>
                            <p className="text-gray-600 text-sm w-auto md:w-80">{card.description}</p>
                        </motion.div>
                    </motion.div>
                ))}
            </div>
        </motion.div>
    );
};

export default BentoGrid;
