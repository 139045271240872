import React, { useState, useEffect } from 'react';
import { dashboardData } from '../data/dashboardData';
import MetricCard from '../new/MetricCard';
import InvoiceStatus from '../new/InvoiceStatus';
import { adminStats } from '../../../api/dashboard';

const InvoiceWidgets = () => {
  // Uncomment this befor deployment
  const [dashboard_data, setdashboard_data] = useState('');
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await adminStats();
        if (response[0] !== 200) throw new Error('Failed to fetch dashboard data');

        // uncomment this this saves the actual data
        // const data = response[1];
        // Comment this before deployment - uses mockdata
        const data = dashboardData;
        console.log("data : ", data);

        setdashboard_data(data);
        // Store data in local storage
        localStorage.setItem('dashboardData', JSON.stringify(data));

      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setdashboard_data({}); // Fallback to an empty object if the API fails
      }
    };

    fetchDashboardData();

  }, []);


  return (
    <div className="w-full h-auto rounded-lg hidden pb-20 overflow-hidden md:grid grid-cols-1 md:grid-cols-4 auto-rows-fr gap-6 ">
      {/* Row 1 */}
      <MetricCard
        title="Outstanding Invoices"
        // value={dashboard_data.outstandingInvoices}
        value={dashboardData.metrics.outstandingInvoices.value}
        trend={dashboardData.metrics.outstandingInvoices.trend}
        data={dashboardData.metrics.outstandingInvoices.data}
      />
      <MetricCard
        title="Amount Due"
        // value={dashboard_data.totalAmountDue}
        value={dashboardData.metrics.amountDue.value}
        trend={dashboardData.metrics.amountDue.trend}
        prefix={dashboardData.metrics.amountDue.prefix}
        data={dashboardData.metrics.amountDue.data}
      />
      <MetricCard
        title="Total Spend"
        // value={dashboard_data.totalSpend}
        value={dashboardData.metrics.totalSpend.value}
        trend={dashboardData.metrics.totalSpend.trend}
        prefix={dashboardData.metrics.totalSpend.prefix}
        data={dashboardData.metrics.totalSpend.data}
      />
      <InvoiceStatus data={dashboardData.invoiceStatus} />
    </div>
  );
};

export default InvoiceWidgets;