import React from "react";
import { motion } from "framer-motion";
import clipboardImage from "../assets_lp2/icons/clipboardImage.svg";
import dollarImage from "../assets_lp2/icons/dollarImage.svg";
import lightbulbImage from "../assets_lp2/icons/lightbulbImage.svg";
import shieldImage from "../assets_lp2/icons/shieldIcon.svg";

const cards = [
    {
        title: "Substantial Cost Savings",
        description: "Ditch manual processes to drastically reduce costs and speed up accounts payable.",
        imgSrc: clipboardImage,
    },
    {
        title: "Higher Accuracy = Savings",
        description: "Human errors can result in discrepancies and duplicates. Nimbey AI delivers higher accuracy, meaning more savings.",
        imgSrc: dollarImage,
    },
    {
        title: "End-to-End Visibility",
        description: "Track all your invoices and payments for improved transparency and cash flow.",
        imgSrc: lightbulbImage,
    },
];

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: { 
            duration: 0.6, 
            staggerChildren: 0.2 
        },
    },
};

const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: { 
            duration: 0.8,
            type: "spring",
            bounce: 0.4
        }
    },
    hover: {
        y: -8,
        transition: {
            duration: 0.3,
            ease: "easeOut"
        }
    }
};

const Reasons = () => {
    return (
        <motion.section 
            id="why-choose-nimbey"
            className="py-40 px-4 rounded-b-3xl"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={containerVariants}
        >
            <div className="max-w-7xl mx-auto">
                <motion.h2 
                    className="text-4xl text-gray-900 font-semibold text-center mb-4"
                    variants={{
                        hidden: { opacity: 0, y: -20 },
                        visible: { 
                            opacity: 1, 
                            y: 0,
                            transition: { duration: 0.6 }
                        }
                    }}
                >
                    Why Choose Nimbey?
                </motion.h2>
                
                <motion.p 
                    className="text-xl text-gray-600 text-center mb-16 max-w-3xl mx-auto"
                    variants={{
                        hidden: { opacity: 0 },
                        visible: { 
                            opacity: 1,
                            transition: { duration: 0.6, delay: 0.2 }
                        }
                    }}
                >
                    Maximize Your Recovery with Expert Audits, AI Insights, and Success-Based Results
                </motion.p>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {cards.map((card, index) => (
                        <motion.div
                            key={index}
                            className="h-[300px] bg-[#f4f1ef] rounded-xl p-8 hover:shadow-xl transition-shadow"
                            variants={cardVariants}
                            whileHover="hover"
                        >
                            <div className="bg-white p-4 rounded-full w-12 h-12 flex items-center justify-center mb-6">
                                <img src={card.imgSrc} alt="" className="" />
                            </div>
                            <h3 className="text-xl font-bold mb-4 text-gray-800">
                                {card.title}
                            </h3>
                            <p className="text-gray-600 leading-relaxed">
                                {card.description}
                            </p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </motion.section>
    );
};

export default Reasons;
