import React from "react";

let parent, toolTip = null;

const unitizeAmount = (amount) => {
    return `$${humanizePrice(amount)}`;
}

const humanizePrice = (price) => {
    return Number(price).toLocaleString("default", {currency: "USD"});
}

const humanizeTime = (time) => {
    const seconds = Number.parseInt(((time / 1000) % 60).toString()).toString().padStart(2, '0');
    const minutes = Number.parseInt(((time / 1000 / 60) % 60).toString()).toString().padStart(2, '0');
    const hrs = Number.parseInt((time / 1000 / 60 / 60).toString()).toString().padStart(2, '0');

    return `${hrs}:${minutes}:${seconds}`;
}

const getTimePeriod = (term) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    switch (term) {
        case 0: {
            return new Date().getFullYear();
        }
        case 1: {
            const date = new Date();
            const quarter = Number.parseInt((date.getUTCMonth() / 3).toString());
            return `${months[quarter * 3 - 1]} ${date.getFullYear()} - ${months[quarter * 3 + 3 - 1]} ${date.getFullYear()}`;
        }
        case 2: {
            return months[new Date().getUTCMonth()];
        }
        default: {
            return new Date().getFullYear();
        }
    }
}

function ComparisonArrow({one, two, prefix = '$', suffix = ''}) {
    if (one >= two) return <span
        className={"material-symbols-rounded text-lg text-green-400"}
        title={`${prefix}${two}${suffix} - increase by ${one === 0 ? 0 : 100 - (two === 0 ? 0 : one * 100 / two)}%`}>
            arrow_upward</span>
    return <span
        className={"material-symbols-rounded text-lg text-green-400"}
        title={`${prefix}${two}${suffix} - decrease by ${one === 0 ? 0 : 100 - (two === 0 ? 0 : one * 100 / two)}%`}>
        arrow_downward</span>
}

function handleTip(e, id) {
    try {
        if (e.type === "mouseenter") {
            parent = document.getElementById(id);
            toolTip = parent.querySelector(`.tool-tip-cover`);
            if (parent) parent.style.zIndex = '10';
            if (toolTip) {
                const positions = toolTip.getBoundingClientRect();
                const pp = document.getElementById('stat-container').getBoundingClientRect();
                if (positions.right > pp.right) {
                    toolTip.style.right = 0;
                }
                if (positions.left < pp.left) {
                    toolTip.style.left = 0;
                }
                if (positions.bottom > pp.bottom) {
                    toolTip.style.bottom = 0;
                }
                if (positions.top > pp.top) {
                    toolTip.style.top = 0;
                }
            }
        } else {
            if (parent) parent.style.zIndex = '0';
        }
    } catch (e) {
        console.error(e);
    }

}

function getQuarters(data) {
    const withQuarters = {};
    for (let year in data) {
        withQuarters[year] = {};
        for (let month in data[year]) {
            let q = Math.ceil(month / 3).toFixed(0);
            withQuarters[year][q] = (withQuarters[year][q] || 0) + data[year][month];
        }
    }
    return withQuarters;
}

function filterStats(stats = [], filters) {
    return stats.filter(record =>
        Object.keys(filters).every(property => {
            if (typeof filters[property] === "function")
                return filters[property](record);
            else
                return record[property] === filters[property];
        })
    );
}


function statsByTerm(stats, term) {
    return stats.reduce((prev, curr) => {
        const date = new Date(curr.order_date);
        const year = date.getFullYear();
        const month = date.getUTCMonth();
        const quarter = Math.ceil(month / 3);
        const key = term === 0 ? year : term === 1 ? `${year}/${quarter}` : `${year}/${month}`;
        if (key in prev) {
        } else prev[key] = [];
        prev[key].push(curr);
        return prev;
    }, {});
}

function reduceStats(stats, groupBy, fn = null) {
    const res = {};
    const dates = new Set();
    for (let [k, v] of Object.entries(stats)) {
        for (let record of v) {
            if (record[groupBy] in res) {
            } else res[record[groupBy]] = {};
            if (k in res[record[groupBy]]) {
            } else {
                res[record[groupBy]][k] = 0;
                dates.add(k);
            }
            res[record[groupBy]][k] += fn ? fn(record) : record.total_amount;
        }
    }
    return [res, Array.from(dates).toSorted().toReversed()];
}

const colors = ['#97f2b1', '#97cdf2', '#5683e3', '#28e8cb', '#afefa5', '#eae036', '#e1ce9e'];

export {
    handleTip, ComparisonArrow, getTimePeriod, humanizeTime, humanizePrice, getQuarters, unitizeAmount, filterStats,
    statsByTerm, reduceStats, colors
};