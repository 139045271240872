import React from "react";
import { motion } from "framer-motion";
import automatedImage from "../assets_lp2/updated_aiCopilot.svg";

const AutomatedDeductionManagement = ({
  mainHeading = "Fully automated, Deduction management",
  subText1 = "Your automated Co-Pilot to help you recover $$ due to incorrect deductions. ",
  subText2 = "Specifically built for CPG, Nimbey AI can dive into your data to identify incorrect deductions and gather evidence to file claims on your behalf.",
  buttonText = "How it works?",
  imageSrc = automatedImage,
}) => {
  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
      variants={containerVariants}
      className="bg-white w-11/12 mb-[100px] pt-10 px-6 md:px-12" // Added consistent padding here
    >
      <div className="flex flex-col md:flex-row w-full gap-[15.95%]">
        {/* Text Section */}
        <motion.div
          variants={itemVariants}
          className="w-full md:w-1/2 mb-8 text-center md:text-left"
        >
          <motion.div
            variants={itemVariants}
            className="text-[26px] md:text-[36px] font-semibold text-[#0d0b0b] mb-4 font-poppins"
          >
            {mainHeading.split("<br />").map((text, index) => (
              <span key={index}>
                {text}
                {index < mainHeading.split("<br />").length - 1 && <br />}
              </span>
            ))}
          </motion.div>

          <motion.p
            variants={itemVariants}
            className="text-[16px] md:text-[18px] text-[#1E2126] mb-2 font-public-sans"
          >
            {subText1}
          </motion.p>

          <motion.p
            variants={itemVariants}
            className="text-[16px] md:text-[18px] text-[#1E2126] mb-8 font-public-sans"
          >
            {subText2}
          </motion.p>
        </motion.div>

        {/* Image Section */}
        <motion.div
          variants={itemVariants}
          className="w-full md:w-1/2"
        >
          <img src={imageSrc} alt="Automated Deduction" className="h-full w-full" />
        </motion.div>
      </div>

      {/* Button Section */}
      <motion.div
        variants={itemVariants}
        className="flex justify-center md:justify-start w-full mt-[50px] md:-mt-[30px]"
      >
        <button
          onClick={() => handleScrollTo("howitworks")}
          className="inline-flex items-center justify-center text-[#212B36] bg-transparent border border-[#83CD26] px-4 py-2 rounded-md transition-all duration-300 hover:bg-[#83CD26] hover:text-white focus:outline-none"
        >
          <span className="mr-2">{buttonText}</span>
          <span className="font-bold">&rarr;</span>
        </button>
      </motion.div>
    </motion.section>
  );
};

export default AutomatedDeductionManagement;
