// data/dashboardData.js (or wherever you want to store your data)

export const dashboardData = {
  userName: "Aria",
  metrics: {
    open: {
      value: 109,
      trend: '+2.5%',
      data: [120, 170, 90, 160, 100, 190, 168, 180],
    },
    active: {
      value: 150000,
      trend: '-2.5%',
      data: [1380, 1360, 1390, 2500, 4200, 2700, 1950, 3800],
    },
    won: {
      value: 96,
      trend: '+6.3%',
      data: [120, 170, 90, 160, 100, 190, 168, 180],
    },
    lost: {
      value: 12,
      trend: '-3.7%',
      data: [110, 70, 90, 100, 80, 110, 130, 100],
    },
  },
  disputesByBuyer: {
    currentYear: [
      { name: 'KeHE', value: 10000 },
      { name: 'UNIFI', value: 5000 },
      { name: 'Sprouts', value: 7500 },
      { name: 'Whole Foods', value: 3000 },
      // ... more data
    ],
    previousYear: [
      { name: 'KeHE', value: 8000 },
      { name: 'UNIFI', value: 4000 },
      { name: 'Sprouts', value: 6000 },
      { name: 'Whole Foods', value: 2000 },
      // ... more data
    ],
  },
  disputesByYear: {
    value: 4239.5,
    currentYear: [
      { month: 'Jan', amount: 3000 },
      { month: 'Feb', amount: 5000 },
      { month: 'Mar', amount: 2500 },
      { month: 'Apr', amount: 4000 },
      { month: 'May', amount: 6000 },
      { month: 'Jun', amount: 3500 },
      { month: 'Jul', amount: 4500 },
      { month: 'Aug', amount: 5500 },
      { month: 'Sep', amount: 7000 },
      { month: 'Oct', amount: 6500 },
      { month: 'Nov', amount: 8000 },
      { month: 'Dec', amount: 9000 },
    ],
    previousYear: [
      { month: 'Jan', amount: 2500 },
      { month: 'Feb', amount: 4000 },
      { month: 'Mar', amount: 2000 },
      { month: 'Apr', amount: 3000 },
      { month: 'May', amount: 5000 },
      { month: 'Jun', amount: 3000 },
      { month: 'Jul', amount: 4000 },
      { month: 'Aug', amount: 5000 },
      { month: 'Sep', amount: 6000 },
      { month: 'Oct', amount: 5500 },
      { month: 'Nov', amount: 7000 },
      { month: 'Dec', amount: 8000 },
    ],
  },
  disputesByProduct: {
    currentYear: [
      { name: 'Apple Lite SKU345', value: 1200 },
      { name: 'Orange Lite SKU355', value: 800 },
      { name: 'Blueberry Delight SKU310', value: 1500 },
      { name: 'Mixed Light SKU100', value: 1500 },
      // ... more data
    ],
    previousYear: [
      { name: 'Apple Lite SKU345', value: 1000 },
      { name: 'Orange Lite SKU355', value: 600 },
      { name: 'Blueberry Delight SKU310', value: 1300 },
      { name: 'Mixed Light SKU100', value: 1300 },
      // ... more data
    ],
  },
  disputesByCategory: {
    'Fresh Fruit': 45,
    'Fruit Juice': 35,
    'Frozen Juice': 20,
  },
  salesByBuyer: {
    currentYear: [
      { name: 'Sprouts', value: 200000 },
      { name: 'Dot Foods', value: 150000 },
      { name: 'KeHE', value: 180000 },
      // ... more data
    ],
    previousYear: [
      { name: 'Sprouts', value: 180000 },
      { name: 'Dot Foods', value: 130000 },
      { name: 'KeHE', value: 160000 },
      // ... more data
    ],
  },
  disputesByLocation: {
    'California': 50,
    'Mid-West': 50,
  },
  table1: [
    { buyerName: 'Sprouts', invoiceNumber: 'INV001', totalDeduction: 150, incorrectDeduction: 50, buyerCategory: 'Retail', deliveryLocation: 'California', status: 'Open', invoiceDate: '2024-07-26' },
    { buyerName: 'Dot Foods', invoiceNumber: 'INV002', totalDeduction: 200, incorrectDeduction: 100, buyerCategory: 'Wholesale', deliveryLocation: 'Mid-west', status: 'Closed', invoiceDate: '2024-07-25' },
    // ... more data
  ],
  table2: [
    { invoiceNumber: 'INV001', lineNumber: 1, productName: 'Apples', productCategory: 'Produce', price: 1.50 },
    { invoiceNumber: 'INV001', lineNumber: 2, productName: 'Bananas', productCategory: 'Produce', price: 0.75 },
    // ... more data
  ],
};