import {Line} from "react-chartjs-2";
import React, {useEffect, useRef, useState} from "react";

function TotalPurchases({data, chat}) {

    const [period, setPeriod] = useState(true);
    const chart = useRef();

    useEffect(() => {
        chart.current?.chartInstance?.update();
    }, [data, chart, period])

    if (!data) return;

    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const times = {};
    for (let i of data.sea_shipments) {
        const date = new Date(i.date.toString());
        const key = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
        times[key] = [...(times[key] || []), i];
    }
    const year = (new Date().getFullYear()).toString();
    const proc = {};

    if (period) {
        for (let i in times) {
            const k = i.split("-")[1];
            proc[k] = (proc[k] || 0) + times[i].reduce((a, b) => a + b.shipments, 0);
        }
    } else {
        for (let i in times) {
            const [index, yr] = i.split("-");
            if (year !== yr) continue;
            const k = month[Number.parseInt(index)];
            proc[k] = (proc[k] || 0) + times[i].reduce((a, b) => a + b.shipments, 0);
        }
    }
    return <div className={"flex flex-col md:items-stretch w-full flex-1 max-h-96 card p-0 overflow-hidden"}>
        <div className={"flex items-center justify-between p-4 pb-0 w-full"}>
            <div className={"flex items-center gap-2"}>
                <span className={"material-symbols-rounded text-md"}>drag_indicator</span>
                <span className={"text-sm font-semibold"}>Total Purchases Since {Object.keys(proc)[0]}</span>
            </div>
            <button className={"text-xs border rounded-md px-3 py-2"} onClick={() => setPeriod(!period)}>{period ? 'Year' : 'Month'}</button>
        </div>
        <div className={"flex flex-col gap-2 p-4 overflow-hidden w-full"}>
            <Line ref={chart} data={{
                labels: Object.keys(proc),
                datasets: [
                    {
                        data: Object.values(proc),
                        borderColor: "rgba(151, 204, 83, 0.5)",
                        pointBackgroundColor: "rgba(151, 204, 83, 1)"
                    }
                ]
            }} options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                }
            }}/>
        </div>
    </div>
}

export default TotalPurchases;