import React, { useRef, useEffect, useState } from 'react';
import { Chart as ChartJS } from 'chart.js';

const InvoiceStatus = ({ data, onFilterChange }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [selectedLabel, setSelectedLabel] = useState(null); // Track selected status

  useEffect(() => {
    // Cleanup previous chart instance
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Prepare chart data
    const chartData = {
      labels: ['Pending', 'Approved', 'Paid', 'Overdue'],
      datasets: [
        {
          data: [
            data.pendingApproval,
            data.approved,
            data.paid,
            data.overdue,
          ],
          backgroundColor: [
            '#00A76F', // New Green
            '#B7EF6F', // New Lime Green
            '#97F2B2', // New Cyan
            '#98CDF3', // New Yellow
          ],
          borderWidth: 0,
        },
      ],
    };

    // Define chart options
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: '0%', // '0%' for a filled doughnut effect
      plugins: {
        legend: {
          position: 'right',
          align: 'end',
          labels: {
            boxWidth: 30,
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 15,
            color: '#6B7280',
            font: {
              size: 10,
            },
          },
        },
        title: {
          display: true,
          text: 'Invoices by Status',
          align: 'start',
          color: 'rgb(156 163 175)',
          font: {
            size: 16,
            weight: '500',
          },
          padding: {
            bottom: 25,
          },
        },
      },
      onClick(event) {
        const elements = chartInstanceRef.current.getElementsAtEventForMode(event.native, 'nearest', { intersect: true }, false);
        if (elements.length) {
          const clickedIndex = elements[0].index;
          const clickedLabel = chartData.labels[clickedIndex];

          // Check if the clicked label is already selected
          if (selectedLabel === clickedLabel) {
            // Reset filter to show all data
            setSelectedLabel(null); // Clear selected label
            // onFilterChange({ invoiceStatus: "" }); // Reset filter value
          } else {
            // Update selected label and send filter value back
            setSelectedLabel(clickedLabel);
            // onFilterChange({ invoiceStatus: clickedLabel });
          }
        }
      },
    };

    // Create new chart instance
    if (chartRef.current) {
      chartInstanceRef.current = new ChartJS(chartRef.current, {
        type: 'doughnut',
        data: chartData,
        options,
      });
    }

    // Cleanup on unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data, selectedLabel]); // Re-run when data or selectedLabel changes

  return (
    <div className="bg-white p-6 rounded-xl border h-[200px] relative">
      <div className='h-full w-full'>
        <canvas ref={chartRef} className='w-full h-full' />
      </div>
    </div>
  );
};

export default InvoiceStatus;
