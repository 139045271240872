import React, { useRef, useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    BarElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
} from 'chart.js';
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdRefresh } from 'react-icons/md';

ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

const GenericBarChart = ({ data, title, xAxisLabel, yAxisLabel, onFilterChange }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [filteredData, setFilteredData] = useState(data);
    const originalData = useRef(data); // Store original data

    const typeMap = {
        'Top Vendors By Spend': 'vendor_name',
        'Spend by Category': 'category_name',
        'Outstanding Invoice by Customers': 'customer_name',
        'Disputes by Buyers': 'buyer_name',
        'Disputes by Products': 'product_name',
        'Sales by Buyers': 'buyer_name',
    };

    const type = typeMap[title] || 'name';

    // Helper function to extract all unique names from both datasets
    const getAllNames = (data) => {
        const currentYearNames = data.currentYear ? data.currentYear.map(item => item.name) : [];
        const previousYearNames = data.previousYear ? data.previousYear.map(item => item.name) : [];
        return Array.from(new Set([...currentYearNames, ...previousYearNames])); // Use Set to get unique values
    };

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        if (!filteredData || !filteredData.currentYear && !filteredData.previousYear) {
            return;
        }

        // Get all unique names from current and previous year data
        const allNames = getAllNames(filteredData);

        const chartData = {
            labels: allNames, // Use all unique names as labels
            datasets: [
                {
                    label: 'Current Year',
                    data: allNames.map(name => {
                        const item = filteredData.currentYear?.find(item => item.name === name);
                        return item ? item.value : 0; // Return value if found, otherwise 0
                    }),
                    backgroundColor: '#98CDF3',
                    borderRadius: 4,
                    barThickness: 12,
                },
                {
                    label: 'Previous Year',
                    data: allNames.map(name => {
                        const item = filteredData.previousYear?.find(item => item.name === name);
                        return item ? item.value : 0; // Return value if found, otherwise 0
                    }),
                    backgroundColor: '#97F2B2',
                    borderRadius: 4,
                    barThickness: 12,
                },
            ],
        };

        const options = {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top',
                    align: 'center',
                    labels: {
                        boxWidth: 12,
                        usePointStyle: true,
                        pointStyle: 'circle',
                        padding: 20,
                        color: '#6B7280',
                    },
                },
            },
            scales: {
                x: {
                    grid: {
                        color: 'rgba(243, 244, 246, 1)',
                        lineWidth: 1,
                    },
                    ticks: {
                        color: '#6B7280',
                        font: {
                            size: 12,
                        },
                        // callback: (value) => `$${value.toLocaleString()}`,
                        callback: (value) => {
                            if (value >= 1000000) {
                                return `${value / 1000000}M`;
                            } else if (value >= 1000) {
                                return `${value / 1000}k`;
                            }
                            return value.toLocaleString(); 
                        },
                    },
                    title: {
                        display: !!xAxisLabel,
                        text: xAxisLabel || '',
                        color: '#6B7280',
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#6B7280',
                        font: {
                            size: 12,
                        },
                    },
                    title: {
                        display: !!yAxisLabel,
                        text: yAxisLabel || '',
                        color: '#6B7280',
                    },
                },
            },
            onClick(event, elements) {
                if (elements.length) {
                    const clickedIndex = elements[0].index;
                    const clickedName = allNames[clickedIndex];
            
                    // Check if clickedName exists in both currentYear and previousYear
                    const isCurrentYearFiltered = filteredData.currentYear?.some(item => item.name === clickedName);
                    const isPreviousYearFiltered = filteredData.previousYear?.some(item => item.name === clickedName);
            
                    // Check if currentYear is empty and previousYear has only 1 entry
                    const isEmptyCurrentYearAndOnePreviousYear =
                        !filteredData.currentYear?.length && filteredData.previousYear?.length === 1 && isPreviousYearFiltered;
            
                    // Check if previousYear is empty and currentYear has only 1 entry
                    const isEmptyPreviousYearAndOneCurrentYear =
                        !filteredData.previousYear?.length && filteredData.currentYear?.length === 1 && isCurrentYearFiltered;
            
                    // Reset if BOTH arrays have a single matching entry OR one is empty and the other has 1
                    const isOnlyCurrentYear = filteredData.currentYear?.length === 1 && isCurrentYearFiltered;
                    const isOnlyPreviousYear = filteredData.previousYear?.length === 1 && isPreviousYearFiltered;
            
                    if ((isOnlyCurrentYear && isOnlyPreviousYear) || isEmptyCurrentYearAndOnePreviousYear || isEmptyPreviousYearAndOneCurrentYear) {
                        onFilterChange({ [type]: "" });
                        setFilteredData(data);
                        console.log("Resetting filter to original data", data);
                        return;
                    }
            
                    // Filter based on clickedName
                    const newFilteredData = {
                        currentYear: filteredData.currentYear?.filter(item => item.name === clickedName) || [],
                        previousYear: filteredData.previousYear?.filter(item => item.name === clickedName) || [],
                    };
            
                    console.log("Filtered data after click", newFilteredData);
            
                    setFilteredData(newFilteredData);
            
                    // Determine filter value to send back
                    const filterValue = newFilteredData.currentYear[0]?.name || newFilteredData.previousYear[0]?.name || "";
                    console.log("Filter value being sent back", filterValue);
            
                    onFilterChange({ [type]: filterValue });
                }
            }                     
        };

        if (chartRef.current) {
            chartInstanceRef.current = new ChartJS(chartRef.current, {
                type: 'bar',
                data: chartData,
                options,
            });
        }

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [filteredData]);

    const handleRefresh = () => {
        setFilteredData(data);
        onFilterChange({ [type]: "" });
    };

    return (
        <div className="bg-white border rounded-xl text-left h-fit">
            <div className="border-b pb-1 p-6 flex items-center justify-between">
                <div>
                    <h2 className="text-lg font-semibold text-gray-800">{title || "Bar Chart"}</h2>
                </div>
                <div>
                    <IoIosInformationCircleOutline />
                </div>
            </div>

            {/* Refresh Button */}
            <button
                onClick={handleRefresh}
                className="text-gray-500 py-2 px-4 rounded hover:text-gray-800 transition"
            >
                <MdRefresh />
            </button>

            <div className="h-[320px] -mt-2 relative mx-2 pb-6 cursor-pointer">
                <canvas ref={chartRef} />
            </div>
        </div>
    );
};

export default GenericBarChart;