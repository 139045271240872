import React, { useRef, useEffect, useState } from 'react';
import { Chart as ChartJS } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const InvoiceAgeBreakdown = ({ data, title, onFilterChange }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [filteredData, setFilteredData] = useState(data); // State to manage filtered data
  const [selectedLabel, setSelectedLabel] = useState(null); // Track selected label

  useEffect(() => {
    // Cleanup previous chart instance
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Prepare chart data
    const chartData = {
      labels: Object.keys(filteredData),
      datasets: [
        {
          data: Object.values(filteredData),
          backgroundColor: [
            '#00A76F', // New Green
            '#B7EF6F', // New Lime Green
            '#97F2B2', // New Cyan
          ],
          borderWidth: 0,
          offset: 4,
        },
      ],
    };

    // Define chart options with datalabels plugin configuration
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: '0%',
      plugins: {
        legend: {
          display: false,
          position: 'right',
          align: 'center',
          labels: {
            boxWidth: 12,
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 15,
            color: '#6B7280',
            font: {
              size: 12,
            },
          },
        },
        datalabels: { // Configure datalabels plugin options
          anchor: 'center', // Center the labels within each slice
          align: 'center',  // Align labels in the center of each slice
          color: '#fff',    // Color of the labels
          formatter: (value, context) => {
            // Only show label if value is greater than zero
            return value > 0 ? context.chart.data.labels[context.dataIndex] : '';
          },
        },
      },
      onClick(event) {
        const elements = chartInstanceRef.current.getElementsAtEventForMode(event.native, 'nearest', { intersect: true }, false);
        if (elements.length) {
          const clickedIndex = elements[0].index;
          const clickedLabel = chartData.labels[clickedIndex];

          // Check if the clicked label is already selected
          if (selectedLabel === clickedLabel) {
            // Reset filter to show all data
            setFilteredData(data); // Reset to original data
            setSelectedLabel(null); // Clear selected label
            // Send filter value back - uncomment this when data is being fetched from the backend and dashboard has a filter change functiion
            // onFilterChange({ invoiceAgeRange: "" });
          } else {
            // Filter data based on the clicked label
            const newFilteredData = { [clickedLabel]: filteredData[clickedLabel] };

            // Update state with new filtered data
            setFilteredData(newFilteredData);
            setSelectedLabel(clickedLabel); // Update selected label

            // Send filter value back - uncomment this when data is being fetched from the backend and dashboard has a filter change functiion
            // onFilterChange({ invoiceAgeRange: clickedLabel });
          }
        }
      },
    };

    // Create new chart instance
    if (chartRef.current) {
      chartInstanceRef.current = new ChartJS(chartRef.current, {
        type: 'doughnut',
        data: chartData,
        options,
        plugins: [ChartDataLabels], // Register the plugin here
      });
    }

    // Cleanup on unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [filteredData]); // Only re-run if filteredData changes

  return (
    <div className="bg-white p-6 rounded-xl border text-left">
      <div>
        <p className='font-semibold'>{title ? title : "Invoice Age Breakdown"}</p>
      </div>
      <div className='h-[320px] relative p-8'>
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default InvoiceAgeBreakdown;