import React, {useState} from "react";
import DashboardFilters from "../components/DashboardFilters";

function AdminDashboardLayout({term, setTerm, children, filters = {}, setFilters, postFilters, setPostFilters, data}) {
    const [minAmount, setMinAmount] = useState(0);

    const max = data ?
        Math.max(...Object.values(data).map(x => x.reduce((prev, curr) => prev + curr.total_amount, 0)))
        : null;

    console.log(filters);

    return <div className={"flex items-center justify-between flex-col w-full flex-1 px-2 bg-gray-100 relative"}>
        <div className={"flex items-center justify-between gap-2 w-full sticky z-10 py-2 bg-gray-100 -top-4"}>
            <div className={"p-2"}>
                <span className={"flex text-xl gap-1 w-full"}><b>Admin</b> Dashboard</span>
            </div>
            <div className={"flex items-center relative"}>

                {/*  filters drop down  */}
                <DashboardFilters
                    Activator={({onClick, children}) =>
                        <div>
                            <button id={"filter-btn"}
                                    onClick={onClick}
                                    className={"flex items-center gap-2 shadow p-1 px-4 rounded-md bg-white"}>
                                <span className={"material-symbols-rounded text-lg"}>filter_list</span>
                                <span className={"font-semibold text-xs"}>Filter By {!!Object.keys(filters).length && `( ${Object.keys(filters).length} )`}</span>
                            </button>
                            {children}
                        </div>}
                    className={"flex absolute top-10 right-0 z-50 card flex-col shadow-2xl items-start gap-4"}
                    setFilters={setFilters} filters={filters} max={max} minAmount={minAmount}
                    setMinAmount={setMinAmount} setPostFilters={setPostFilters} postFilters={postFilters}
                    term={term} setTerm={setTerm}
                />

            </div>
        </div>
        <div className={"flex w-full flex-1 flex-col items-center justify-center"}>
            {children}
        </div>

        {/*<DashboardFilters*/}
        {/*    Activator={({onClick, children}) => <div className={"flex sticky z-40 bottom-1.5 items-center justify-center"}>*/}
        {/*            {children}*/}

        {/*            <button id={"filter-float-btn"}*/}
        {/*                    onClick={onClick}*/}
        {/*                    className={"flex items-center gap-2 shadow-xl bg-blue-400 text-white p-2 px-4 z-40 rounded-full w-fit max-w-56"}>*/}
        {/*                <span className={"material-symbols-rounded text-lg"}>filter_list</span>*/}
        {/*                <span className={"text-xs font-medium whitespace-nowrap"}>Filter By</span>*/}
        {/*            </button>*/}
        {/*        </div>}*/}
        {/*    className={"flex absolute bottom-14 z-30 card flex-col shadow-2xl items-start gap-4"}*/}
        {/*    setFilters={setFilters} filters={filters} max={max} minAmount={minAmount}*/}
        {/*    setMinAmount={setMinAmount} setPostFilters={setPostFilters} postFilters={postFilters}*/}
        {/*    term={term} setTerm={setTerm}*/}
        {/*/>*/}

    </div>
}

export default AdminDashboardLayout;